import React, {Fragment, HTMLAttributes} from 'react';
import TableRow from '../../molecules/TableRow';
import FunctionalImage from '../../atoms/FunctionalImage';
import './SpecificationTable.scss';

interface SpecificationTableItem{
  image?: string;
  subtitle?: string;
  title: string;
}

const SpecificationTable = ({attrs, ...divAttr}: {attrs: SpecificationTableItem[]} & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div { ...divAttr } className="specification-table">
      {
        attrs.map((attr, index) => {
          const {image, subtitle, title} = attr;
          const label = (
            <Fragment>
              <div className="title-group">
                <p className='small-text'>{title}</p>
                {subtitle && <p className='small-text fw-500'>{subtitle}</p>}
              </div>
            </Fragment>
          );
          const content = <FunctionalImage image={image} />
          return (
            <TableRow
              content={content}
              index={index}
              key={`key-${index}`}
              label={label} />
          );
        })
      }
    </div>
  )
};

export default SpecificationTable;
