import christmasHatImage from '../../../../assets/hat.png';
import React from 'react';
// @ts-ignore
import ErgeonLink from '../../../atoms/ErgeonLink';
// @ts-ignore
import {isChristmasTime} from '../../../../libs/utils/utils';

const TopPanelLogo = (props: {ergeonHomePage: string; showChristmasHat?: boolean}) => {
  const {ergeonHomePage} = props;

  const isShowChristmasHat = () => {
    if (props.showChristmasHat !== undefined) {
      return props.showChristmasHat;
    }
    return isChristmasTime(new Date());
  };

  return (
    <div className="top-panel__content__logo-wrapper">
      <ErgeonLink aria-label="Ergeon logo" className="ergeon-logo" href={ergeonHomePage} />
      {isShowChristmasHat() && <img className="logo-christmas-hat" src={christmasHatImage} />}
    </div>
  );
};

export default TopPanelLogo;
