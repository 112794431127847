import React, {HTMLAttributes, useCallback} from 'react';

import times from 'lodash/times';

import Star from './Star';
import useStarsRating from './useStarsRating';

import './StarRating.scss';

const NO_OF_STARS = 5;

interface StarRatingProps extends HTMLAttributes<HTMLDivElement> {
  defaultRating?: number;
  disabled?: boolean;
  onStarRatingClick?(starValue: number): void;
  onStarRatingHover?(starValue: number): void;
}

const StarRating = (props: StarRatingProps) => {
  const {defaultRating = 0, disabled, onStarRatingClick, onStarRatingHover, ...divProps} = props;
  const {rating, hoveredRating, onHoverRating, onClickRating} = useStarsRating(defaultRating);

  const onClick = useCallback(
    (starValue: number) => {
      onClickRating(starValue);
      onStarRatingClick?.(starValue);
    },
    [onClickRating, onStarRatingClick],
  );

  const onHover = useCallback(
    (starValue: number) => {
      onHoverRating(starValue);
      onStarRatingHover?.(starValue);
    },
    [onHoverRating, onStarRatingHover],
  );

  const renderStar = useCallback(
    (_, index: number) => {
      const starValue = index + 1;
      return (
        <Star
          disabled={disabled}
          filled={index < (hoveredRating || rating)}
          index={index}
          key={index}
          onClick={() => onClick(starValue)}
          onMouseEnter={() => onHover(starValue)}
        />
      );
    },
    [onClick, onHover, hoveredRating, rating, disabled],
  );

  return (
    <div {...divProps} data-testid="star-rating" className="StarRating" onMouseLeave={() => onHoverRating(0)}>
      {times(NO_OF_STARS, Number).map(renderStar)}
    </div>
  );
};

export default StarRating;
