import React from 'react';

interface RadioGroupContextValue {
  name?: string;
  selectedValue?: unknown;
  onChange?: (value: unknown) => void;
}

const RadioGroupContext = React.createContext<RadioGroupContextValue>({});

export default RadioGroupContext;
