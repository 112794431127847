import React, { HTMLAttributes, ReactNode } from 'react';

import EventCardSide from './EventCardSide';
import '../index.scss';

type EventCardLeftProps = {
  children: ReactNode;
  onClick?: () => void;
} & HTMLAttributes<HTMLButtonElement | HTMLSpanElement>;

const EventCardLeft = ({ children, onClick, ...ElementAttr }: EventCardLeftProps) => {
  return (
    <EventCardSide {...ElementAttr} placement="leftASide" onClick={onClick}>
      {children}
    </EventCardSide>
  )
};

export { EventCardLeft };
