import React from 'react'

import './CheckMark.scss';

const CheckMark = () => {
  return (
    <div className="checkMark">
      <div className="checkMark-line checkMark-line-long" />
      <div className="checkMark-line checkMark-line-tip" />
      <div className="checkMark-ring" />
      <div className="checkMark-hide-corners" />
    </div>
  )
}

export default CheckMark
