import React from 'react';

import {AttributesGroup} from '../types';
import RadioOptions from '../RadioOptions';
import DropdownOptions from '../DropdownOptions';

import {DROPDOWN_ATTR_CONTROL} from './constants';

interface OptionsAdapterProps {
  selectedParam?: AttributesGroup;
  selectedOptionCodes?: {[key: number]: string};
  onChangeOption: (id: number, value: string) => void;
  attrControl: Record<string, string>;
}

const OptionsAdapter = ({selectedParam, selectedOptionCodes, onChangeOption, attrControl}: OptionsAdapterProps) => {
  return (
    <>
      {selectedParam?.attrs.map((attr) => {
        if (!attr.slug || !selectedOptionCodes) return null;
        const controlType = attrControl[attr.slug];
        const selectedOptionCode = selectedOptionCodes[attr.id];

        if (controlType === DROPDOWN_ATTR_CONTROL) {
          return (
            <DropdownOptions
              key={attr.id}
              onChangeOption={onChangeOption}
              selectedOptionCode={selectedOptionCode}
              selectedParam={attr}
            />
          );
        }
        return (
          <RadioOptions
            key={attr.id}
            onChangeOption={onChangeOption}
            selectedOptionCode={selectedOptionCode}
            selectedParam={attr}
          />
        );
      })}
    </>
  );
};

export default OptionsAdapter;
