import React, {useCallback, useMemo} from 'react';

import {AttributesGroup, ReducedAttribute} from '../../../atoms/OptionsTable/types';
import OptionsTable from '../../../atoms/OptionsTable';
import Notification from '../../../molecules/Notification';

interface BaseQuestionnaireQuestionProps {
  handleChangeOption: (param: ReducedAttribute, optionCode: string) => void;
  selectedOptionCodes: Record<number, string>;
  selectedParam: AttributesGroup;
  isLoading?: boolean;
  error?: string;
  attrControl: Record<string, string>;
}

const ErrorNotification = ({error}) => {
  if (!error) return null;
  return (
    <Notification mode="embed" type="Error">
      {error}
    </Notification>
  );
};

const QuestionDescription = ({isGroup, selectedParam}) => (
  <p className="base-questionnaire__description">
    {!isGroup && selectedParam.attrs[0].helpText}
    {!isGroup && selectedParam.attrs[0].helpLink && <a href={selectedParam.attrs[0].helpLink}>Help Center</a>}
  </p>
);

const BaseQuestionnaireQuestion = (props: BaseQuestionnaireQuestionProps) => {
  const {handleChangeOption, selectedOptionCodes, selectedParam, isLoading, error, attrControl} = props;

  const isGroup: boolean = useMemo(() => selectedParam.attrs.length > 1, [selectedParam]);
  const onChangeOption = useCallback(
    (id: number, value: string) => {
      const attr = selectedParam.attrs.find((attr) => attr.id === id);
      if (!attr) {
        console.debug(`Attribute ${id} not found in selected group!`);
        return;
      }
      handleChangeOption(attr, value);
    },
    [handleChangeOption, selectedParam],
  );

  return (
    <div className="base-questionnaire__body">
      <p className="base-questionnaire__question">
        {isGroup ? selectedParam.name : selectedParam.attrs[0].label || selectedParam.attrs[0].name}
      </p>
      <QuestionDescription isGroup={isGroup} selectedParam={selectedParam} />
      <OptionsTable
        attrControl={attrControl}
        className="base-questionnaire__options-table"
        isOptionProcessing={isLoading}
        onChangeOption={onChangeOption}
        selectedOptionCodes={selectedOptionCodes}
        selectedParam={selectedParam}
      />
      <ErrorNotification error={error} />
    </div>
  );
};

export default BaseQuestionnaireQuestion;
