import React, {
  forwardRef,
  ForwardedRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import {v4 as uuidv4} from 'uuid';

import InputLabel from './InputLabel';
import InputText from './InputText';
import MaskInput from './MaskInput';
import TextArea from './TextArea';
import {controlNumberRange} from './utils';
import {InputProps, OnChangeType, OnFocusType} from './types';

import './index.scss';

/**
 * Generates a unique identifier that will be assigned to element’s `id`, `name`, and `htmlFor` attributes
 * @param prefix
 * @returns generated unique id
 */
export const getUniqueId = (prefix = '') => `${prefix}-${uuidv4()}`;

/**
 *  Renders
 *  - <MaskInput /> when `props.mask` is provided,
 *  - <TextArea /> when `props.isMultiline` true,
 *  - <InputText /> otherwise.
 */
const Input = forwardRef<HTMLInputElement | HTMLTextAreaElement, InputProps>((props, ref) => {
  const {
    className = '',
    isDisabled = false,
    label = '',
    mask,
    isMultiline = false,
    name = getUniqueId('erg-input'),
    onFocus = noop(),
    onBlur = noop(),
    onChange = noop(),
    onKeyUp = noop(),
    rightIcon = <span />,
    size = 'medium',
    isValid,
    validationMessage = '',
    value = '',
    min,
    max,
    type,
    maxLength,
    multilineRows,
    multilineCols
  } = props;

  const inputRef = useRef<HTMLDivElement>(null);

  const getCheckedValue = useCallback(
    (localValue) => {
      const isNumberInput = type === 'number';
      if (isNumberInput) {
        return controlNumberRange(localValue, min, max).toString();
      }
      return localValue?.toString();
    },
    [type, min, max],
  );

  const [focus, setFocus] = useState<boolean>(false);

  const onChangeLocal = useCallback((event: OnChangeType): void => {
    const {target: {value}} = event;
    if (maxLength && value.length > maxLength) {
      return;
    }
    if (onChange) {
      onChange(event, name, getCheckedValue(value));
    }
  }, [name, getCheckedValue, onChange, maxLength]);

  const onKeyUpLocal = useCallback((event: KeyboardEvent): void => {
    const {key} = event;
    if (onKeyUp) onKeyUp(event, name, key);
  }, [name, onKeyUp]);

  const onBlurLocal = useCallback((event: OnFocusType): void => {
    setFocus(false);
    if (onBlur) onBlur(event);
  }, [onBlur]);

  const onFocusLocal = useCallback((event: OnFocusType): void => {
    setFocus(true);
    if (onFocus) onFocus(event);
  }, [onFocus]);

  const InputComponent = useMemo((): JSX.Element => {
    const newProps = omit(props, ['isValid', 'validationMessage', 'isDisabled', 'isMultiline', 'rightIcon']);

    // We shouldn't allow to use values out of the diapason even if we got them from props
    newProps.value = getCheckedValue(newProps.value);

    if (!!mask) {
      return <MaskInput
        handleOnFocus={onFocusLocal}
        handleOnBlur={onBlurLocal}
        handleKeyUp={onKeyUpLocal}
        handleInputChange={onChangeLocal}
        disabled={isDisabled}
        {...newProps} />;
    }
    if (isMultiline) {
      return <TextArea
        ref={ref as ForwardedRef<HTMLTextAreaElement>}
        handleOnFocus={onFocusLocal}
        handleOnBlur={onBlurLocal}
        handleKeyUp={onKeyUpLocal}
        handleInputChange={onChangeLocal}
        disabled={isDisabled}
        rows={multilineRows}
        cols={multilineCols}
        {...newProps} />;
    }
    return <InputText
      ref={ref as ForwardedRef<HTMLInputElement>}
      handleOnFocus={onFocusLocal}
      handleOnBlur={onBlurLocal}
      handleKeyUp={onKeyUpLocal}
      handleInputChange={onChangeLocal}
      disabled={isDisabled}
      {...newProps} />;

  }, [isMultiline, mask, isDisabled, onFocusLocal, onBlurLocal, focus, onKeyUpLocal, onChangeLocal, props, getCheckedValue]);

  const renderValidationMessage = useMemo((): JSX.Element | null => {
    if (isValid !== false || !validationMessage) {
      return null;
    }
    return <div className="validation-message label error">{validationMessage}</div>;
  }, [isValid, validationMessage]);

  useEffect(() => {
    if (!onChange && !onKeyUp) {
      console.warn('You must either provide onChange or onKeyUp handler for input component');
    }
  }, [onChange, onKeyUp]);

  useEffect(() => {
    if (type === 'number' && inputRef.current) {
      const handler = (e: WheelEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      inputRef.current.addEventListener('wheel', handler, {passive: false});
      return () => inputRef.current?.removeEventListener('wheel', handler);
    }
  }, [type]);

  const classes = useMemo(() => {
    return classNames(['erg-input', size, {
      disabled: isDisabled,
      'no-label': !label,
      'erg-input--dirty': getCheckedValue(value).length || focus,
      'erg-input--valid': isValid,
      'erg-input--invalid': isValid === false,
    }, className]);
  }, [size, isDisabled, label, value, isValid, className, focus]);

  const labelCoverClassName = useMemo(() => {
    return classNames('multiline-labelCover', {
      'is-focus': focus,
    })
  }, [focus])

  return (
    <div className={classes} ref={inputRef}>
      {isMultiline && <div className={labelCoverClassName} />}
      {InputComponent}
      {rightIcon}
      <InputLabel name={name} label={label} valid={isValid} />
      {renderValidationMessage}
    </div>
  )
});

export default Input;
