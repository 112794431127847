import React, { useContext, useCallback, useMemo, HTMLAttributes } from 'react';
import classNames from 'classnames';

import NavLinkContext from '../../../libs/navlink-context';

import './index.scss';

export type BreadcrumbItem = {
  highlighted?: boolean,
  name: string,
  to: string,
} & HTMLAttributes<HTMLDivElement>

export interface BreadcrumbProps {
  className?: string,
  secondary?: boolean,
  items?: BreadcrumbItem[][],
}

const Breadcrumb = (props: BreadcrumbProps) => {
  const { className, secondary = false, items = [], ...divProps } = props;
  const NavLink = useContext(NavLinkContext);

  const renderItem = useCallback((groupIndex: number, item: BreadcrumbItem, index: number) => {
    const firstItem = !groupIndex && !index;
    const itemClasses = classNames('breadcrumb--item', {
      'breadcrumb--item--highlighted': item.highlighted,
      'breadcrumb--item--first': firstItem && !secondary,
    });

    return (
      <NavLink className={itemClasses} key={`breadcrumb-item-${index}`} to={item.to}>
        {item.name}
        {(firstItem && !secondary) ? <span className="breadcrumb--first-indicator" /> : null}
      </NavLink>
    );
  }, [secondary]);

  const renderItemGroup = useCallback((itemsArray: BreadcrumbItem[] = [], groupIndex: number) => {
    return (
      <div className="breadcrumb--group" key={`breadcrumb-${groupIndex}`}>
        {itemsArray.map((elem: BreadcrumbItem, index: number) => renderItem(groupIndex, elem, index))}
        {(groupIndex !== items.length - 1) ? <span className="breadcrumb--separator">/</span> : null}
      </div>
    );
  }, [renderItem, items]);

  const classes = useMemo(() => classNames('breadcrumb', className), [className]);

  return (
    <div className={classNames(classes)} {...divProps}>
      {items.map(renderItemGroup)}
    </div>
  );
}

export default Breadcrumb;
