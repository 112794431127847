import isNil from 'lodash/isNil';

export const controlNumberRange = (v: string, min?: number, max?: number): number | string => {
  if (v.length === 0) return '';
  let value = Number(v);

  if (!isNil(min) && value < min) return min;
  if (!isNil(max) && value > max) return max;

  return value;
};
