import React, {HTMLAttributes, ReactNode} from 'react';
import classNames from 'classnames';
import './index.scss';

type TitleType = {
  children: ReactNode;
  className?: string;
  icon?: string;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Displays Title with icon and text
 */
const Title = (props: TitleType) => {
  const {icon, className, children, ...divAttr} = props;
  return (
    <div {...divAttr} className={classNames('title', {[className as string]: className})}>
      {icon && <img className="title-icon" alt="" src={icon} />}
      {children}
    </div>
  );
};

export default Title;
