import React, { forwardRef, HTMLAttributes } from 'react';

import '../index.scss';

type MonthMarkerTypes = {
  monthYear: string,
} & HTMLAttributes<HTMLDivElement>

const MonthMarker = forwardRef<HTMLDivElement, MonthMarkerTypes>((props, ref) => {
  const { monthYear, ...divAttr } = props;
  return (
    <div {...divAttr} className="MonthMarker" ref={ref}>
      <p className="MonthMarker-text">{monthYear}</p>
    </div>
  )
});

export { MonthMarker };
