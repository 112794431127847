
/**
 * Triggers WebShare API, or rejects if not supported
 * @param title {string}
 * @param url {string}
 */
export const navigatorShare = (title: string, url: string) => {
  return new Promise((resolve, reject) => {
    if (navigator && navigator.share) {
      navigator.share({
        title,
        url,
      })
        .then(() => {
          resolve('WebShare triggered');
        })
        .catch((error) => {
          console.warn(error);
          reject(error);
        });
    } else {
      reject(new Error("Browser doesn't support navigator.share"));
    }
  });
}

const deprecatedCopyToClipboard = (str: string) => {
  const el: HTMLTextAreaElement = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

/**
 * Uses navigator.clipboard API to copy text to clipboard
 * @param text {string}
 */
export const copyToClipboard = (text: string) => {
  return new Promise((resolve) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(text)
        .then(() => {
          resolve('Copied to clipboard');
        })
        .catch((error) => {
          console.warn(error);
          // fallback to deprecated method
          deprecatedCopyToClipboard(text);
          resolve('Copied to clipboard');
        });
    } else {
      // fallback if navigator.clipboard is not supported
      deprecatedCopyToClipboard(text);
      resolve('Copied to clipboard');
    }
  });
}
