import {useMemo} from 'react';

import classNames from 'classnames';

const useCssClasses = (isDropdownShown: boolean) => {
  const dropDownWrapperClasses = useMemo(
    () =>
      classNames({
        'attribute-select--dropdown': true,
        visible: isDropdownShown,
      }),
    [isDropdownShown],
  );

  const selectClasses = useMemo(
    () =>
      classNames({
        'attribute-select--wrapper': true,
        active: isDropdownShown,
      }),
    [isDropdownShown],
  );

  return {selectClasses, dropDownWrapperClasses};
};

export default useCssClasses;
