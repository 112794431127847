import React, {useCallback, useEffect, useRef, useState} from 'react';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import {ReactSVG} from 'react-svg';
import iconVideo from '@ergeon/icons/svg/icon-video.svg';

import Spinner from '../Spinner';
import './index.scss';
import {getMediaType} from '../../molecules/ImageGallery/utils';

// Ensures to remove preloader after timeout ms.
const MAX_LOADING_WAIT_TIME_MS = 5000;

/**
 * Returns an image showing a spinner loader while it's not ready.
 */
const ImageLoader = ({url, alt, onImageLoaded = noop, style = {}, ...remainingProps}) => {
  const [showLoader, setShowLoader] = useState(true);
  const imgRef = useRef();
  const mediaType = getMediaType(url);

  /**
   * Removes preloader.
   */
  const setLoadingDone = useCallback(() => {
    setShowLoader(false);
  }, [setShowLoader]);

  /**
   * When img is loaded hide preloader and show the img.
   */
  useEffect(
    function replaceLoaderWithImageOnMount() {
      const {current: ref} = imgRef;
      if (ref) {
        if (ref.complete) {
          // More likely img is already cached.
          setLoadingDone();
          return;
        }
        // Either when image loaded or failed to load show it.
        ref.addEventListener('load', setLoadingDone);
        ref.addEventListener('error', setLoadingDone);
        return () => {
          ref.removeEventListener('load', setLoadingDone);
          ref.removeEventListener('error', setLoadingDone);
        };
      }
    },
    [setLoadingDone, setShowLoader],
  );

  /**
   * In case image is not loaded (should be impossible) ensure to hide preloader.
   */
  useEffect(
    function ensureToHideLoaderAfterDelay() {
      // If not loaded within 5s, force it.
      const timeout = setTimeout(setLoadingDone, MAX_LOADING_WAIT_TIME_MS);
      return () => {
        clearTimeout(timeout);
      };
    },
    [setLoadingDone],
  );

  /**
   * Notify parent component that image finished loading.
   */
  useEffect(
    function callOnImageLoaded() {
      if (showLoader === false) {
        // Loader is hidden, the img is loaded.
        onImageLoaded();
      }
    },
    [showLoader, onImageLoaded],
  );

  return (
    <div className={classnames('image-loader__wrapper', {'is-loading': showLoader})} style={style}>
      {showLoader && mediaType !== 'video' && (
        <div className="image-loader__spinner">
          <Spinner active={true} borderWidth={0.16} color="lightgray" size={64} />
        </div>
      )}
      {mediaType === 'video' ? (
        <div className="image-loader__image" {...remainingProps} data-testid="video-svg">
          <ReactSVG src={iconVideo} />
        </div>
      ) : (
        <img alt={alt} className="image-loader__image" ref={imgRef} src={url} {...remainingProps} />
      )}
    </div>
  );
};

ImageLoader.propTypes = {
  alt: PropTypes.string,
  onImageLoaded: PropTypes.func,
  style: PropTypes.object,
  url: PropTypes.string.isRequired,
};

export default ImageLoader;
