import {Dispatch, RefObject, useCallback} from 'react';

import {useClickOutside} from '../../../utils/useClickOutside';

interface UseOutsideClick {
  dropdownRef: RefObject<HTMLDivElement>;
  isDropdownShown: boolean;
  setIsDropdownShown: Dispatch<boolean>;
}

const useDropdownClickOutside = (props: UseOutsideClick) => {
  const {dropdownRef, isDropdownShown, setIsDropdownShown} = props;

  const onClickOustide = useCallback(() => {
    if (isDropdownShown) {
      setIsDropdownShown(false);
    }
  }, [isDropdownShown, setIsDropdownShown]);

  useClickOutside(dropdownRef, onClickOustide);
};

export default useDropdownClickOutside;
