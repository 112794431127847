import React from 'react';

import {SchematicOption} from '../../types';
import Schematic from '../../Schematic';

import './OptionWithImage.scss';

const OptionWithImage = ({option}: {option: SchematicOption}) => {
  const optionLabel = option.label || option.title;
  const optionHelpText = option.helpText;

  return (
    <div className="option-with-image__option-container">
      <Schematic className="schematic" option={option} />
      <div className="label">{optionLabel}</div>
      <div className="help-wrapper">
        <div className="help-text">{optionHelpText}</div>
      </div>
    </div>
  );
};

export default OptionWithImage;
