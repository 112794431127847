import React, {HTMLAttributes} from 'react';
import './MenuRowSimple.scss';

export interface MenuRowSimpleProps extends HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  title: string;
  leftNode?: React.ReactNode;
  rightNode?: React.ReactNode;
  asAnchor?: boolean;
  isBottomBordered?: boolean;
  isActive?: boolean;
}

const MenuRowSimple: React.FC<MenuRowSimpleProps> = ({
  title,
  leftNode,
  rightNode,
  asAnchor,
  isBottomBordered,
  isActive,
  ...props
}) => {
  const Tag = asAnchor ? 'a' : 'div';

  return (
    <Tag
      className={`menu-row-simple ${isBottomBordered ? 'menu-row-simple__bordered' : ''} ${isActive ? 'menu-row-simple__active' : ''}`}
      {...(asAnchor ? {href: '#'} : {})}
      {...props}
    >
      <div className="menu-row-simple__left-content">
        <div className="menu-row-simple__content-leftnode">{leftNode}</div>
        <div className="menu-row-simple__text">
          <span>{title}</span>
        </div>
      </div>
      <div className="menu-row-simple__content-rightnode">{rightNode}</div>
    </Tag>
  );
};

export default MenuRowSimple;
