import React, {HTMLAttributes} from 'react';

import StarRating from '../../molecules/StarRating';
import SuccessDialog from '../SuccessDialog/SuccessDialog';

import './GiveRatingDialog.scss';

interface GiveRatingDialogProps extends HTMLAttributes<HTMLDivElement> {
  onClose(): void;
  onRatingChange?(value: number): void;
  description?: string;
}

const GiveRatingDialog = (props: GiveRatingDialogProps) => {
  const {onClose, onRatingChange, description, ...divProps} = props;

  return (
    <SuccessDialog className="GiveRatingDialog" onClose={onClose} {...divProps}>
      <p className="rating-description center">{description}</p>
      <div className="stars center">
        <StarRating onStarRatingClick={onRatingChange} />
      </div>
    </SuccessDialog>
  );
};

export default GiveRatingDialog;
