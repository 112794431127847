import React, {forwardRef, HTMLAttributes, PropsWithChildren, useMemo} from 'react';
import classNames from 'classnames';

import Button from 'components/atoms/Button';

import CloseIcon from '!@svgr/webpack!@ergeon/icons/svg/icon-cross-gray.svg';

import './index.scss';

interface SideMenuProps extends PropsWithChildren<{}> {
  isOpen: boolean;
  onClose(isOpen: boolean): void;
}

const SideMenu = forwardRef<HTMLDivElement, SideMenuProps & HTMLAttributes<HTMLDivElement>>((props, ref) => {
  const {isOpen, onClose, children, ...divAttr} = props;

  const SideMenuClass = useMemo(
    () =>
      classNames('SideMenu', {
        hide: !isOpen,
      }),
    [isOpen],
  );

  const closeWrapperClass = useMemo(
    () =>
      classNames('SideMenu-closeWrapper', {
        hide: !isOpen,
      }),
    [isOpen],
  );

  const wrapperClass = useMemo(
    () =>
      classNames('SideMenu-wrapper', {
        'SideMenu-wrapper-show': isOpen,
      }),
    [isOpen],
  );

  return (
    <div {...divAttr} className={SideMenuClass}>
      <div className={wrapperClass}>
        <Button className="SideMenu-closeButton" flavor="regular" onClick={() => onClose(!isOpen)} taste="boundless">
          <CloseIcon />
        </Button>
        {children}
      </div>
      <div className={closeWrapperClass} ref={ref}></div>
    </div>
  );
});

export default SideMenu;
