import React, { HTMLAttributes, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import posed from "react-pose";

import "./index.scss";

type ItemsType = {
  title: ReactNode | string;
  content: ReactNode;
  isOpen?: boolean;
  isVisible?: boolean;
};

type AccordionProps = {
  items: ItemsType[];
} & HTMLAttributes<HTMLDivElement>;

const Content = posed.div({
  closed: { height: 0 },
  open: { height: "auto" },
});

const Accordion = (props: AccordionProps) => {
  const { items, ...divProps } = props;
  const [openedItems, setOpenedItems] = useState<boolean[]>([]);

  const visibleItems = useMemo(() => items.filter(item => item.isVisible === undefined || item.isVisible) , [items]);

  useEffect(function ensureDefaultOpened () {
    const openedItems = visibleItems.map(item => !!item.isOpen);
    setOpenedItems((prevOpenedItems) => prevOpenedItems.length === 0 ? openedItems : prevOpenedItems);
  }, [visibleItems])

  const onClickItem = useCallback((index: number) => {
      const newData = [...openedItems];
      newData[index] = !newData[index];
      setOpenedItems(newData);
  }, [openedItems]);

  const itemsClassName = useCallback((index) => {
    return classNames({
      "accordion--item": true,
      "accordion--item--hidden": !openedItems[index],
    });
  }, [openedItems])

  return (
    <div className="accordion" {...divProps}>
      {visibleItems.map((item, index) => {
        return (
          <div className={itemsClassName(index)} key={index} onClick={() => {onClickItem(index) }}>
            <div className="accordion--icon-container">
              {!!item.content && (<span className="accordion--icon" />)}
            </div>
            <div className="accordion--data">
              <div className="accordion--title">{item.title}</div>
              <Content className="accordion--content" pose={openedItems[index] ? "open" : "closed"}>
                {item.content}
              </Content>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Accordion;
