import React from 'react';
import noop from 'lodash/noop';

import ImageLoader from '../../atoms/ImageLoader';

export interface ThumbnailProps {
  title?: string,
  url: string,
  onClick?(event: MouseEvent): void,
  maxHeight: string | number,
}

/**
 *  Displays an image thumbnail
 */
const Thumbnail = (props: ThumbnailProps) => {
  const { title, url, onClick, maxHeight } = props;

  return (
    <div className="card ImageGallery-thumb">
      <ImageLoader alt={title} onImageLoaded={noop} url={url} style={{ maxHeight }} onClick={onClick} />
    </div>
  )
};

export default Thumbnail;
