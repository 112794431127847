import React, {Fragment, HTMLAttributes} from 'react';
import ClassNames from 'classnames';
import Skeleton from './Skeleton';
import times from 'lodash/times';

import Placeholder, {SchedulePlaceholderProps} from './Placeholder';

import './SchedulePlaceholder.scss';

/**
 * @summary SchedulePlaceholder usage
 * SchedulePlaceholder is meant to be used on contractor-app Schedule page while loading
 * @param {number} props.cardsPerContainer - Number of cards to add on each of our container elements
 * @param {number} props.containerLength - Number of containers to display
 */
const SchedulePlaceholder = (props: SchedulePlaceholderProps & HTMLAttributes<HTMLDivElement>) => {
  const {
    cardsPerContainer = 2,
    containerLength = 2,
    loadingText = 'Loading...',
    ...divAttr
  } = props;

  const cards = times(cardsPerContainer, i => {
    return (
      <div key={i} className="SchedulePlaceholder-card">
        <Skeleton
          containerClassName="SchedulePlaceholder-card is-LeftSkeleton"
          width={'100%'}
          height={88}
        />
      </div>
    );
  });

  const loadingLine = (
    <div className="SchedulePlaceholder-line">
      <div className="SchedulePlaceholder-lineWrapper">
        <span className="SchedulePlaceholder-text">{loadingText}</span>
      </div>
    </div>
  );

  return (
    <div {...divAttr} className="SchedulePlaceholder">
      {times(containerLength, i => {
        return (
          <Fragment key={i}>
            <div
              className={ClassNames('SchedulePlaceholder-container', {
                'is-FirstContainer': i === 0,
              })}>
              <Skeleton
                containerClassName="SchedulePlaceholder-container is-RightSkeleton"
                width={60}
                height={30}
              />
            </div>
            <div className="SchedulePlaceholder-container">
              {i === 0 && loadingLine}
              {cards}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Placeholder(SchedulePlaceholder);
