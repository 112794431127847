import { useEffect } from 'react';

import useEnhancedScrollBlocker from './useEnhancedScrollBlocker';
import type { EnhancedScrollBlockerOptions } from './useEnhancedScrollBlocker';

function useBlockBodyScroll(shouldDisable: boolean, options: EnhancedScrollBlockerOptions) {
  const ScrollBlocker = useEnhancedScrollBlocker(options);

  useEffect(function blockUnblockBodyScroll() {
    if (shouldDisable) {
      ScrollBlocker.blockContentScroll();
    } else {
      ScrollBlocker.enableContentScroll();
    }
    return () => {
      ScrollBlocker.enableContentScroll();
    };
  }, [ScrollBlocker, shouldDisable]);

  useEffect(function cleanup() {
    return () => {
      ScrollBlocker.remove();
    };
  }, []);

  return { id: ScrollBlocker.id };
}

export default useBlockBodyScroll;
