import React from 'react';

import usePhoneFormatter from '../../../utils/usePhoneFormatter';
import TopPanelLink, { TopPanelProps } from '../TopPanel/TopPanelLink';

interface CallUsPhoneLinkProps extends Omit<TopPanelProps, 'address' | 'name'> {}

const CallUsPhoneLink = (props: CallUsPhoneLinkProps) => {
  const phoneFormatter = usePhoneFormatter();

  return (
    <TopPanelLink
      {...props}
      address={phoneFormatter.link}
      name={`Call us ${phoneFormatter.display}`} />
  );
};

export default CallUsPhoneLink;
