import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import { EventCardContent, EventCardLeft, EventCardRight } from './components';

import './index.scss';

export interface EventCardInterface {
  className?: string,
  children: ReactNode,
}

/**
 * This component shows an event type card, has 3 slots, lef/right for icons only
 * content slot should get text elements as children like <b> <p> etc, has predefined css styles
 * for each event type, but can get any other class for reusability
 * @param props {EventCardInterface}
 */
const EventCard = (props: EventCardInterface & HTMLAttributes<HTMLDivElement>) => {
  const { children, className = 'is-GrayCard', ...divAttr} = props;

  const eventCardClasses = useMemo(() => classNames('EventCard', { [className]: className }), [className]);

  return (
    <div {...divAttr} className={eventCardClasses}>
      {children}
    </div>
  )
};

EventCard.Content = EventCardContent;
EventCard.Left = EventCardLeft;
EventCard.Right = EventCardRight;

export default EventCard;
