import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import ReactImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import ImageLoader from '../../atoms/ImageLoader';
import Title from '../../atoms/Title';
import ImageGalleryPopup from '../../molecules/ImageGallery/ImageGalleryPopup';
import NoImgsMessage from '../../molecules/ImageGallery/NoImgsMessage';
import { slideToIndex } from '../../molecules/ImageGallery/utils';
import Flickity from './Flickity';

import './index.scss';

type SwipeGalleryImage = {
  title: string,
  url: string,
  // provide custom renderer for item
  renderItem?(item: ReactImageGalleryItem): React.ReactNode;
};

type SwipeGalleryProps = {
  images: SwipeGalleryImage[],
  isFullWidth?: boolean,
  scrollToTop?: boolean,
  titleIcon?: string,
  title?: string,
  onOpenCloseGallery?: (open: boolean) => void,
};

/**
 *  SwipeGallery Component - Displays a swipeable carousel of images
 */
const SwipeGallery = (props: SwipeGalleryProps) => {
  const {
    images = [],
    isFullWidth = false,
    scrollToTop = false,
    titleIcon,
    title,
    onOpenCloseGallery,
  } = props;

  const refImg = useRef<ReactImageGallery>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const openGallery = useCallback((i: number): void => {
    setActiveSlideIndex(i);
    setOpen(true);
  }, [setOpen]);

  useEffect(function openCloseGallery() {
    if (onOpenCloseGallery) {
      onOpenCloseGallery(open);
    }
  }, [open, onOpenCloseGallery]);

  const imagesArray = useMemo(() => (
    images.map(elem => ({
      description: elem.title,
      original: elem.url,
      renderItem: elem.renderItem,
    }))
  ), [images]);

  useEffect(function slideToActiveIndex() {
    slideToIndex(refImg, activeSlideIndex);
  }, [activeSlideIndex]);

  if (isEmpty(images)) {
    return <NoImgsMessage />;
  }

  return (
    <div className={classNames('SwipeCarousel', { 'is-FullWidth': isFullWidth })}>
      {title &&
        <Title icon={titleIcon} className="spacing left__is-20">
          {title} ({images.length})
        </Title>
      }
      <Flickity>
        {images.map((item, i) => (
          <div key={item.url} className="card SwipeImage-thumb">
            <ImageLoader
              alt={item.title}
              onImageLoaded={noop}
              url={item.url}
              onClick={() => openGallery(i)}
            />
          </div>
        ))}
      </Flickity>
      <ImageGalleryPopup
        images={imagesArray}
        isVisible={open}
        onChangeVisible={setOpen}
        options={{
          scrollToTop,
          initialSlide: activeSlideIndex,
          showCounter: true,
        }}
      />
    </div>
  )
};

export default SwipeGallery;
