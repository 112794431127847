import React, {KeyboardEvent} from 'react';
import omit from 'lodash/omit';
import InputMask from 'react-input-mask-format';
import {InputTypes, OnChangeType, OnFocusType} from './types';

interface MaskInputProps {
  mask?: string | (string | RegExp)[];
  className?: string;
  disabled?: boolean;
  name?: string;
  handleOnFocus?: (e: OnFocusType) => void;
  handleOnBlur?: (e: OnFocusType) => void;
  handleInputChange: (e: OnChangeType) => void;
  handleKeyUp?: (e: KeyboardEvent) => void;
  placeholder?: string;
  value: string;
  type?: InputTypes;
}

const MaskInput = (props: MaskInputProps): JSX.Element => {
  const {
    mask = '',
    disabled = false,
    name = '',
    handleOnFocus = () => {},
    handleOnBlur = () => {},
    handleInputChange,
    handleKeyUp = () => {},
    placeholder = '',
    type = 'text',
    value
  } = props;
  const newProps = omit(props, ['handleOnFocus', 'handleOnBlur', 'handleInputChange', 'handleKeyUp']);
  return (
    <InputMask
      {...newProps}
      mask={mask}
      className='erg-input--text'
      disabled={disabled}
      id={name}
      name={name}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      type={type}
      value={value} />
  )
}

export default MaskInput;
