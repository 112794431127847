import React, { forwardRef, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactSignatureCanvas from 'react-signature-canvas';

import Signature from './Signature';

import './SignOffPad.scss';
import { resizeSignatureImageCanvas, MAX_IMAGE_SIZE } from './utils';

type SignType = 'draw' | 'text';
interface SignOffProps {
  onChange: (value: string) => void;
  signedValue?: { value: string; type: SignType };
  clearOnResize?: boolean;
}

const SignOffPad = forwardRef<ReactSignatureCanvas, SignOffProps>((props: SignOffProps, ref) => {
    const { onChange, signedValue, clearOnResize = false } = props;
    const [userInteraction, setUserInteraction] = useState(false);

    const onBeginDrawing = useCallback(() => {
      setUserInteraction(false);
    }, [setUserInteraction]);

    const onEndDrawing = useCallback(() => {
      setUserInteraction(true);
    }, [setUserInteraction]);

    const updateChangeState = useCallback(async () => {
      if (typeof ref !== 'function' && ref !== null) {
        const canvasImage = ref?.current?.getTrimmedCanvas().toDataURL() || '';
        onChange(await resizeSignatureImageCanvas(canvasImage, MAX_IMAGE_SIZE.WIDTH, MAX_IMAGE_SIZE.HEIGHT));
      }
    }, [onChange, ref]);

    useEffect(function stateUpdate(){
      if (userInteraction) {
        updateChangeState();
      }
    }, [updateChangeState, userInteraction]);

    return (
      <>
        <Helmet>
          <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet" />
        </Helmet>
        <div className={`SignOff`}>
          {!signedValue?.value
            ? <div className="SignOff-signature">
                <ReactSignatureCanvas
                  canvasProps={{ className: 'SignOff-signature-pad' }}
                  clearOnResize={clearOnResize}
                  onBegin={onBeginDrawing}
                  onEnd={onEndDrawing}
                  ref={ref}
                />
              </div>
            :  <Signature {...signedValue} />}
        </div>
      </>
    );
  }
);

export default SignOffPad;
