import { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import throttle from 'lodash/throttle';

export const MS_24_PER_SEC = 1000 / 24;

/**
 * Triggers requestAnimationFrame flow to improve animations performance,
 * uses callback to trigger from any component, passes dependencies to avoid unnecessary re-renders
 * @param {func} callback
 * @param {Array} deps
 */
export const useRequestAnimationFrame = (callback: () => void, deps: Dispatch<SetStateAction<boolean>>[]) => {
  useEffect(function animateFunc() {
    const animationLoopCounter = requestAnimationFrame(callback);
    return () => {
      cancelAnimationFrame(animationLoopCounter);
    }
  }, [callback, ...deps]);
};

export function movieThrottle(callback: () => void) { return throttle(callback, MS_24_PER_SEC) };

export const useWindowOnNearBottom = (handler: () => void) => {
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight =
        'innerHeight' in window ?
          window.innerHeight :
          document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (windowBottom >= docHeight) {
        handler();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handler]);
};

const NEGATIVE_RATING_LIMIT = 3;


export enum DialogRatingFlow {
  Empty,
  Positive,
  Feedback,
}

export const useDialogRatingState = (onRating?: (value: number) => void) => {

  const [rating, setRating] = useState(0);

  const dialogState = useMemo(() => {
    if (rating === 0) return DialogRatingFlow.Empty;
    if (rating > NEGATIVE_RATING_LIMIT) return DialogRatingFlow.Positive;
    return DialogRatingFlow.Feedback;
  }, [rating]);
  
  
  useEffect(function onRatingChanged() {
    if (rating !== 0) {
      onRating?.(rating);
    }
  }, [rating, onRating]);

  return {
    rating,
    setRating,
    dialogState,
  }
}

