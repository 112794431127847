import React, { ReactNode } from 'react';
import ContentPlaceholder from './ContentPlaceholder';

export interface BasePlaceholderProps {
  active: boolean
  children?: ReactNode,
}

export interface TextualPlaceholderProps extends BasePlaceholderProps {
  rows?: number
}

export interface MediaPlaceholderProps extends BasePlaceholderProps {
  height: number | string,
  width: number | string,
}

export interface SchedulePlaceholderProps extends BasePlaceholderProps {
  containerLength?: number,
  cardsPerContainer?: number,
  loadingText?: string,
}

export interface ImagePlaceholderProps extends BasePlaceholderProps, MediaPlaceholderProps { };

export interface ComplexPlaceholderProps
  extends TextualPlaceholderProps, ImagePlaceholderProps, MediaPlaceholderProps { };

export type PlaceholderProps =
  | ComplexPlaceholderProps
  | SchedulePlaceholderProps;

/**
 * @summary Placeholder usage
 * Placeholder is meant to be used as HOC to wrap `PlaceholderComponent`s
 */ // TODO: refactor these types to remove any from below
const Placeholder = (PlaceholderComponent: (props: any) => JSX.Element) => ({active, ...otherProps}: PlaceholderProps) => (
  <ContentPlaceholder active={active}>
    <PlaceholderComponent {...otherProps} />
  </ContentPlaceholder>
);

export default Placeholder;
