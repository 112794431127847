import { useCallback, useEffect, useRef, useState } from 'react';

const useMobileMenu = () => {
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const onShowMobileMenu = useCallback(() => {
    setMobileMenuVisible(true);
  }, []);

  const onHideMenu = useCallback((event: Event) => {
    const { target } = event;

    if (!ref.current) {
      setMobileMenuVisible(false)
    } else if (target !== ref.current && !ref.current?.contains(target as Element)) {
      setMobileMenuVisible(false)
    }
  }, []);

  useEffect(function onOutsideMenuClick() {
    if (!isMobileMenuVisible) return;

    const timeout = setTimeout(() => {
      document.addEventListener('click', onHideMenu);
    });
    return () => {
      clearTimeout(timeout);
      document.removeEventListener('click', onHideMenu);
    };
  }, [isMobileMenuVisible, onHideMenu]);

  return { isMobileMenuVisible, onShowMobileMenu, ref };
};

export default useMobileMenu;
