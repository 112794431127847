import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import './index.scss';

interface ButtonGroupItem {
  label: string;
  icon?: string;
}

interface ButtonGroupProps {
  className?: string;
  id?: string;
  items?: ButtonGroupItem[];
  onChange?: (selectedIndex: number) => void;
  selectedIndex?: number;
}

interface ButtonGroupItemProps extends ButtonGroupItem {
  index: number;
  isActive: boolean;
  onClick: (index: number) => void;
  id: string;
}

const ButtonGroupItemComponent: FC<ButtonGroupItemProps> = ({ label, index, isActive, onClick, id }) => {
  const classes = useMemo(() => classNames({
    'button-group__item': true,
    'button-group__item--active': isActive,
  }), [isActive]);

  return (
    <div className={classes} key={`${id}-${label}`} onClick={() => onClick(index)}>
      {label}
    </div>
  );
};

const ButtonGroup: FC<ButtonGroupProps> = ({
  className,
  id = uniqueId(),
  items = [],
  onChange,
  selectedIndex = 0,
}) => {

  const handleClick = useCallback(
    (index: number) => {
      if (onChange) {
        onChange(index);
      }
    },
    [onChange]
  );

  const classes = useMemo(() => classNames({
    'button-group': true,
  }, className), [className]);

  return (
    <div className={classes}>
      {items.map((item, index) => (
        <ButtonGroupItemComponent
          {...item}
          index={index}
          isActive={index === selectedIndex}
          onClick={handleClick}
          id={id}
          key={index}
        />
      ))}
    </div>
  );
};

export default ButtonGroup;
