import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export const getDate = (date) => {
  return date ? moment(date, 'DD-MM-YYYY', true).unix() : moment().unix();
};

export const datePropType = (props, propName, componentName) => {
  if (!moment(props[propName], 'DD-MM-YYYY', true).isValid()) {
    return new Error(`Invalid prop ${propName} supplied to '${componentName}'. Date invalid.`);
  }
  return null;
};

export const dateOrEmptyPropType = (props, propName, componentName) => {
  if (isEmpty(props[propName])) {
    return null;
  }
  return datePropType(props, propName, componentName);
};
