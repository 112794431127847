import React, { HTMLAttributes, ReactNode } from 'react';

import { Line, DateBox, MonthMarker, Card } from './components';
import Schedule from './Schedule'

import './index.scss';

export interface EventsCalendarInterface {
  children: ReactNode,
}
/**
 * This component is a wrapper for EventCards, it also provides Line and DateBox components to
 * form the full event calendar
 * @param {children} props.children
 */
const EventsCalendar = (props: EventsCalendarInterface & HTMLAttributes<HTMLDivElement>) => {
  const { children, ...divAttr } = props;
  return (
    <div {...divAttr} className="EventsCalendar">
      {children}
    </div>
  )
};

EventsCalendar.Line = Line;
EventsCalendar.DateBox = DateBox;
EventsCalendar.MonthMarker = MonthMarker;
EventsCalendar.Card = Card;
EventsCalendar.Schedule = Schedule;

export default EventsCalendar;
