import React, { useCallback, useMemo } from "react";
import classNames from "classnames";

import Button from "../../atoms/Button";

type SurveyNumberItemsProps = {
  onClick(value: number, title: string): void;
  position: number;
  title: string;
  value: number;
};

const SurveyNumberItems = (props: SurveyNumberItemsProps) => {
  const { position, onClick, title, value } = props;

  const onPositionClick = useCallback((e) => {
      e.preventDefault();
      onClick(position, title);
    }, [position, onClick, title]);

  const buttonClassName = useMemo(() => {
    return classNames("is-button", {
      "is-active": position <= value,
      "is-position": position === value,
    });
  }, [value, position]);

  return (
    <Button className={buttonClassName} onClick={onPositionClick}>
      {position}
    </Button>
  );
};

export default SurveyNumberItems;
