import React, {forwardRef, KeyboardEvent} from 'react';
import omit from 'lodash/omit';
import {OnChangeType, OnFocusType} from './types';

interface TextAreaProps {
  className?: string;
  disabled?: boolean;
  name?: string;
  handleOnFocus?: ((e: OnFocusType) => void);
  handleOnBlur?: ((e: OnFocusType) => void);
  handleInputChange: ((e: OnChangeType) => void);
  handleKeyUp?: ((e: KeyboardEvent) => void);
  placeholder?: string;
  value: string;
  rows?: number
  cols?: number
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
  const {
    disabled = false,
    name = '',
    handleOnFocus = () => {},
    handleOnBlur = () => {},
    handleInputChange,
    handleKeyUp = () => {},
    placeholder = '',
    value,
    cols = 50,
    rows = 2
  } = props;
  const newProps = omit(props, ['handleOnFocus', 'handleOnBlur', 'handleInputChange', 'handleKeyUp']);
  return (
    <textarea
      {...newProps}
      cols={cols}
      rows={rows}
      className='erg-input--text'
      disabled={disabled}
      id={name}
      name={name}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      ref={ref}
      value={value} />
  );
});

export default TextArea;
