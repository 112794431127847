import React from 'react';

import imgHAApprove from '../../../assets/ha-approved.png';
import imgBBB from '../../../assets/bbb.png';
import imgYelp from '../../../assets/yelp.png';
import imgCert from '../../../assets/certified.png';
import imgGoogleReview from '../../../assets/google-logo.png';
import imgGoogleReviewsPreview from '../../../assets/reviews-preview-google.png';
import imgYelpReviewsPreview from '../../../assets/reviews-preview-yelp.png';
import imgHomeAdvisorReviewsPreview from '../../../assets/reviews-preview-homeadvisor.png';
import ratings from '../../../data/ratings.json';
import Dialog from '../Dialog';
import PopUp from '../../atoms/PopUp';

import './index.scss';

const REVIEW_PROVIDER_TYPE = {
  'GOOGLE': {
    'CODE': 'GOOGLE',
    'URL': 'https://www.google.com/maps?cid=13156437773994721266&hl=en',
    'IMG': imgGoogleReviewsPreview,
  },
  'YELP': {
    'CODE': 'YELP',
    'URL': 'https://www.yelp.com/biz/ergeon-palo-alto-3',
    'IMG': imgYelpReviewsPreview,
  },
  'HOMEADVISOR': {
    'CODE': 'HOMEADVISOR',
    'URL': 'https://www.homeadvisor.com/rated.ErgeonPaloAlto.66624661.html',
    'IMG': imgHomeAdvisorReviewsPreview,
  },
  'BBB': {
    'CODE': 'BBB',
    'URL': 'https://www.bbb.org/us/ca/san-francisco/profile/landscape-contractors/ergeon-1116-928428',
    'IMG': imgBBB,
  },
};

class BadgesSection extends React.Component {
  state = {
    dialogShown: false,
    dialogType: null,
  };

  renderBadge({ title, subtitle, img, url, dialogType }) {
    return (
      <a
        className="badges-section--badge"
        href={dialogType ? null : url}
        onClick={dialogType ? (() => this.setState({ dialogShown: true, dialogType })) : null}
        rel="noopener noreferrer"
        target="_blank">
        <div className="badges-section--badge--img-container">
          <img src={img} />
        </div>
        <div className="badges-section--badge--txt">
          <h6 className="spacing after__is-6">{title}</h6>
          <div className="label">{subtitle}</div>
        </div>
      </a>

    );
  }
  renderReviewDialog(reviewProvider) {
    const { dialogType, dialogShown } = this.state;

    return (
      <PopUp
        className="badges-section--popup"
        onHide={() => this.setState({ dialogShown: false })}
        scrollToTop={false}
        showCloseIcon={false}
        visible={dialogShown && dialogType == reviewProvider.CODE}>
        <Dialog
          active={true}
          className="badges-section--dialog"
          onCancel={() => this.setState({ dialogShown: false })}
          onOk={() => console.log('Ok')}
          showButtons={false}>
          <img src={reviewProvider.IMG} width="100%" />
        </Dialog>
      </PopUp>
    );
  }
  render() {
    const { homeadvisor, google, yelp } = ratings;
    return (
      <div className="badges-section">
        <div className="badges-section--row">
          {this.renderBadge({
            dialogType: REVIEW_PROVIDER_TYPE.HOMEADVISOR.CODE,
            title: 'HomeAdvisor',
            subtitle: homeadvisor.subtitle,
            img: imgHAApprove,
            url: REVIEW_PROVIDER_TYPE.HOMEADVISOR.URL,
          })}
          {this.renderBadge({
            dialogType: REVIEW_PROVIDER_TYPE.GOOGLE.CODE,
            title: 'Google',
            subtitle: google.subtitle,
            img: imgGoogleReview,
            url: REVIEW_PROVIDER_TYPE.GOOGLE.URL,
          })}
          {this.renderBadge({
            dialogType: REVIEW_PROVIDER_TYPE.YELP.CODE,
            title: 'Yelp',
            subtitle: yelp.subtitle,
            img: imgYelp,
            url: REVIEW_PROVIDER_TYPE.YELP.URL,
          })}
          {this.renderBadge({
            title: 'BBB Accredited',
            subtitle: 'A Rated',
            img: imgBBB,
            url: REVIEW_PROVIDER_TYPE.BBB.URL,
          })}
        </div>
        <div className="badges-section--row">
          {this.renderBadge({
            title: 'C13 Fencing License',
            subtitle: 'Ergeon Inc License #1040925',
            img: imgCert,
            url: 'https://www2.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1040925',
          })}
          {this.renderBadge({
            title: 'C27 Landscaping License',
            subtitle: 'Ergeon Inc License #1040925',
            img: imgCert,
            url: 'https://www2.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1040925',
          })}
          {this.renderBadge({
            title: 'C8 Concrete License',
            subtitle: 'Ergeon Inc License #1040925',
            img: imgCert,
            url: 'https://www2.cslb.ca.gov/OnlineServices/CheckLicenseII/LicenseDetail.aspx?LicNum=1040925',
          })}
          {this.renderBadge({
            title: 'Residential/General contractor',
            subtitle: 'Ergeon Inc License #RBQA007338',
            img: imgCert,
            url: 'https://verify.sos.ga.gov/verification/',
          })}
        </div>
        {this.renderReviewDialog(REVIEW_PROVIDER_TYPE.GOOGLE)}
        {this.renderReviewDialog(REVIEW_PROVIDER_TYPE.HOMEADVISOR)}
        {this.renderReviewDialog(REVIEW_PROVIDER_TYPE.YELP)}
      </div>
    );
  }
}
export default BadgesSection;
