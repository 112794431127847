import React from 'react';

import AttributeSelector from '../../../atoms/AttributeSelector';
import AttributeHelpIcon from '../../../atoms/AttributeHelpIcon';
import Schematic from '../Schematic';
import {ReducedAttribute, SchematicOption, Value} from '../types';

import {createSelectOption} from './utils';

import './DropdownOptions.scss';

interface DropdownOptionsProps {
  selectedParam?: ReducedAttribute;
  selectedOptionCode?: string;
  onChangeOption: (id: number, value: string) => void;
}

const renderSchematic = (selectedOption: SchematicOption) =>
  selectedOption.image && <Schematic option={selectedOption} />;

const DropdownOptions = ({selectedParam, selectedOptionCode, onChangeOption}: DropdownOptionsProps) => {
  const selectedOption = selectedParam?.options.find((option) => option?.code === selectedOptionCode);

  if (!selectedOption || !selectedParam) {
    return null;
  }

  const definedOptions = selectedParam.options.filter(Boolean) as Value[];
  const options = definedOptions.map(createSelectOption);
  const value = createSelectOption(selectedOption);

  return (
    <div className="dropdown-options">
      {renderSchematic(selectedOption)}
      <div className="dropdown-options__wrapper">
        <div className="dropdown-options__label">
          {selectedParam.label || selectedParam.name}
          <AttributeHelpIcon helpLink={selectedParam.helpLink} helpText={selectedParam.helpText} />
        </div>
        <AttributeSelector
          onChange={({value}) => onChangeOption(selectedParam.id, value)}
          options={options}
          value={value}
        />
        <div className="dropdown-options__help-text label">{selectedOption.helpText}</div>
      </div>
    </div>
  );
};

export default DropdownOptions;
