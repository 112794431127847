import React from 'react';

import PropTypes from 'prop-types';
import { localStorage as ls } from '@ergeon/erg-utils-js';

import { dateOrEmptyPropType, getDate } from 'libs/utils/date';

import Notification from '../../molecules/Notification';
import './index.scss';

const NOTIFICATION_MARK = 'erg-notifications';

export default class EmergencyNotification extends React.Component {
  static get propTypes() {
    return {
      defaultCount: PropTypes.number,
      finalDate: dateOrEmptyPropType,
      markName: PropTypes.string,
      policyUrl: PropTypes.string,
    };
  }
  static defaultProps = {
    defaultCount: 3,
    markName: 'covid19',
    policyUrl: 'https://www.ergeon.com/help/202000377',
  };
  constructor(props) {
    super(props);

    const { markName, defaultCount } = props;
    const defaultMark = { [markName]: defaultCount };
    this.finalDate = props.finalDate && getDate(props.finalDate);
    this.notificationMark = ls.get(NOTIFICATION_MARK)? ls.get(NOTIFICATION_MARK) : defaultMark;
    this.state = {
      display: this.isVisible(),
    };
  }
  isVisible() {
    if (!this.finalDate) {
      // Keep it for eternity
      return true;
    }
    const { markName } = this.props;
    return (this.notificationMark[markName] > 0) && getDate() < this.finalDate;
  }
  onClose() {
    const { markName } = this.props;
    this.setState({ display: false });
    this.notificationMark[markName] -= 1;
    ls.set(NOTIFICATION_MARK, this.notificationMark);
  }
  render() {
    const { display } = this.state;
    const { policyUrl } = this.props;
    return (
      <React.Fragment>
        {display && this.isVisible() &&
          <div className="area-of-emergency-notification">
            <div className="wrapper-1180">
              <Notification
                className="spacing before__is-12 after__is-12"
                mode="transparent"
                onClose={this.onClose.bind(this)}
                type="Information">
                <h6 className="additional-header spacing">
                  We&apos;re working to keep your projects on time, on budget, and safe during COVID-19.
                </h6>
                Read more about our commitment to customers
                <a href={policyUrl}> here</a>
              </Notification>
            </div>
          </div>}
      </React.Fragment>
    );
  }
}
