import React from 'react';

import './MenuRowContainer.scss'
 
interface MenuRowContainerProps {
  title: string;
  children: React.ReactNode;
}

const MenuRowContainer: React.FC<MenuRowContainerProps> = ({ title, children }) => {
  return (
    <div className="menu-row-container">
      <h4 className="menu-row-container__title">{title}</h4>
      <div className="menu-row-container__content">
        {children} 
      </div>
    </div>
  );
};
export default MenuRowContainer;