import React, {useCallback, useMemo, useState} from 'react';

import classNames from 'classnames';
import {ReactSVG} from 'react-svg';
import imgCamera from '@ergeon/icons/icons/3d-lib-photo.svg';

import Spinner from '../../../atoms/Spinner';
import {SchematicOption} from '../types';

import './Schematic.scss';

interface SchematicProps {
  className?: string;
  option: SchematicOption;
}

const Schematic = ({className, option}: SchematicProps) => {
  const optionLabel = option.label || option.title;
  const imgSrc = option.image || '';
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleError = useCallback(() => {
    setIsError(true);
    setIsLoading(false);
  }, []);

  const handleLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  const wrapperClasses = useMemo(() => classNames('image-wrapper', className), [className]);

  const imgClasses = useMemo(
    () =>
      classNames({
        hidden: isLoading,
      }),
    [isLoading],
  );

  const renderedContent = useMemo(() => {
    if (isError) {
      return <ReactSVG className="preview-image__error" src={imgCamera} />;
    }
    return <img alt={optionLabel} className={imgClasses} onError={handleError} onLoad={handleLoading} src={imgSrc} />;
  }, [isError, optionLabel, imgClasses, imgSrc, handleError, handleLoading]);

  return (
    <div className={wrapperClasses}>
      <div className="preview-image">
        {isLoading && <Spinner active={true} borderWidth={0.16} color="gray" size={24} />}
        {renderedContent}
      </div>
    </div>
  );
};

export default Schematic;
