export type AutoCompletePlaceData = {
  city_name?: string,
  formatted_address: string,
  state_abbreviation: string,
  street_name?: string,
  zipcode: string,
  raw_address: string,
  places_types: string[],
  location: {
    lat: () => number,
    lng: () => number,
  }
}

export enum AutoCompleteTypes {
  GEOCODE = 'geocode',
  ADDRESS = 'address',
  ESTABLISHMENT = 'establishment',
  REGIONS = 'regions',
  CITIES = 'cities',
}
