/**
 * Scroll page to top
 */
export function scrollTop() {
  window.scrollTo(0, 0);
}

/**
 * Disallow scrolling for body
 */
export function blockBodyScroll() {
  document.body.style.overflow = 'hidden';
  document.body.style.height = '100%';
}

/**
 * Remove disallowed scrolling for body
 */
export function unblockBodyScroll() {
  document.body.style.overflow = 'unset';
  document.body.style.height = 'unset';
}
