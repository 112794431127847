import React from 'react';
import {BasePlaceholderProps} from './Placeholder';

/**
 * @summary ContentPlaceholder usage
 * @description ContentPlaceholder should be used by passing customPlaceholder elements
 * to render our Placeholder for the type of UI we need to cover, we should pass a boolean
 * value to show/hide (prop.active) on most cases this will be a state value when doing requests
 * like we do when using the Spinner component
 *
 *
 * customPlaceholder Example:
 * @example
 * const customComponent = <Skeleton count={rows}/>;
 *
 * @param {Object} props - Component props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {ReactNode} props.children - Children element
 */
const ContentPlaceholder = (props: BasePlaceholderProps) => {
  const {active, children} = props;
  if (active) {
    return <div className="skeleton-theme">{children}</div>;
  }
  return null;
};

export default ContentPlaceholder;
