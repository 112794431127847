import React, { forwardRef, HTMLAttributes } from 'react';
import classNames from 'classnames';

import '../index.scss';

type DateBoxTypes = {
  isToday?: boolean,
  weekDay: string,
  numDay: number,
  noVisits?: boolean,
  noVisitsText?: string,
} & HTMLAttributes<HTMLDivElement>

const DateBox = forwardRef<HTMLDivElement, DateBoxTypes>((props, ref) => {
  const { isToday = false, weekDay, numDay, noVisits = false, noVisitsText = 'No visits today.', ...divAttr } = props;
  return (
    <div
      {...divAttr}
      className={classNames("EventsCalendar-dateContainer", {
        'is-noVisits': noVisits,
      })}
      ref={ref}>
        <div className={classNames('EventsCalendar-dateBox', {
          'is-today': isToday,
        })}>
          <span className="EventsCalendar-dateBoxText">
            {weekDay}
            <span className="is-bold">{numDay}</span>
          </span>
        </div>
        {noVisits &&
          <div className="EventsCalendar-textBox">
            <span className="EventsCalendar-dateBoxText is-noVisitsText">{noVisitsText}</span>
          </div>
        }
    </div>
  )
});

export { DateBox };
