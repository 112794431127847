import React, {useMemo} from 'react';

import classNames from 'classnames';

import Spinner from '../../atoms/Spinner';

import {OptionsTableProps} from './types';
import OptionsAdapter from './OptionsAdapter';

import './OptionsTable.scss';

const OptionsTable = (props: OptionsTableProps) => {
  const {
    className = '',
    isOptionProcessing = false,
    onChangeOption,
    selectedOptionCodes,
    selectedParam,
    attrControl,
  } = props;

  const containerClasses = useMemo(
    () =>
      classNames({
        'options-table': true,
        [className]: className,
      }),
    [className],
  );

  const fogClasses = useMemo(
    () =>
      classNames({
        'fog-cover': true,
        visible: isOptionProcessing,
      }),
    [isOptionProcessing],
  );

  const preloaderClasses = useMemo(
    () =>
      classNames({
        'processing-marker': true,
        label: true,
        visible: isOptionProcessing,
      }),
    [isOptionProcessing],
  );

  return (
    <>
      <div className={preloaderClasses}>
        <Spinner active={true} borderWidth={0.25} color="blue" size={12} />
        Processing selection...
      </div>
      <div className={containerClasses}>
        <div className={fogClasses} />
        <OptionsAdapter
          attrControl={attrControl}
          onChangeOption={onChangeOption}
          selectedOptionCodes={selectedOptionCodes}
          selectedParam={selectedParam}
        />
      </div>
    </>
  );
};

export default OptionsTable;
