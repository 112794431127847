import React, { HTMLAttributes, ReactNode } from 'react';

import EventCardSide from './EventCardSide';
import '../index.scss';

type EventCardRightProps = {
  children: ReactNode;
  onClick?: () => void;
} & HTMLAttributes<HTMLButtonElement | HTMLSpanElement>;


const EventCardContent = ({ children, onClick, ...ElementAttr }: EventCardRightProps) => {
  return (
    <EventCardSide {...ElementAttr} placement="content" onClick={onClick}>
      {children}
    </EventCardSide>
  )
};

export { EventCardContent };
