import React from 'react';
import DataRow from '../../atoms/DataRow';
import {DataItem} from './ListerItem';

const ListerContent: React.FC<{
  items: DataItem[];
  renderItem?: (item: DataItem, index: number) => JSX.Element;
}> = ({ items, renderItem }) => {
  const defaultRenderItem = (item: DataItem, index: number) => (
    <DataRow
      key={index}
      title={item.title}
      value={item.value}
      defaultValue={item.defaultValue}
    />
  );

  const effectiveRenderItem = renderItem || defaultRenderItem;

  return <>{items.map((item, index) => effectiveRenderItem(item, index))}</>;
};

export default ListerContent;
