import React, { ForwardRefExoticComponent, FunctionComponent, ReactNode, useMemo, useState, forwardRef } from 'react';
import classNames from 'classnames';

import { TopPanelCenter, TopPanelCenterPropTypes, TopPanelLeft, TopPanelRight } from './components';
import { movieThrottle, useRequestAnimationFrame } from '../../utils/customHooks';

import './index.scss';

export interface TopPanelMobileInterface extends React.HTMLAttributes<HTMLDivElement> {
  isFixed?: boolean,
  isSecondary?: boolean,
  isAutoHideDisabled?: boolean,
  children: ReactNode,
}

export interface CompoundComponent extends
  ForwardRefExoticComponent<TopPanelMobileInterface & React.RefAttributes<HTMLDivElement>> {
  Center: FunctionComponent<TopPanelCenterPropTypes>;
  Left: FunctionComponent;
  Right: FunctionComponent;
}

/**
 * This component shows a TopPanel container, where we can place other components on
 * 3 different slots (left, center, right) center slot shows ergeon's logo
 * by default, we can use it as fixed or absolute, this way we can stack panels and
 * hide the top one by checking scrolling (using isSecondary on the second)
 * @param props {TopPanelMobileInterface}
 * @constructor
 */
const TopPanelMobile = forwardRef((props, ref) => {

  const { isFixed = true, isSecondary = false, isAutoHideDisabled = false, children, ...otherProps } = props;

  const [moveSecondary, setMoveSecondary] = useState<boolean>(false);

  /**
   * calculate our scroll direction, if we are moving down we need to hide the secondary
   * top-panel, if we move up then we show it
   */
  useRequestAnimationFrame(movieThrottle(function triggerMoveSecondary() {
    let lastScrollY = window.pageYOffset;

    function updateScrollDir() {
      requestAnimationFrame(() => {
        const scrollY = window.pageYOffset;
        setMoveSecondary(scrollY > lastScrollY); // scroll down
        lastScrollY = scrollY > 0 ? scrollY : 0;
      });
    };

    window.addEventListener('scroll', updateScrollDir);

    return () => {
      window.removeEventListener('scroll', updateScrollDir)
    };
  }), [setMoveSecondary]);

  const topPanelClassNames = useMemo(() => classNames('top-panel-mobile', otherProps.className, {
    'top-panel-mobile__fixed': isFixed,
    'top-panel-mobile__secondary': isSecondary,
    // set __move only on secondary and control animation
    'top-panel-mobile__secondary__move': isSecondary && moveSecondary && !isAutoHideDisabled,
  }), [isFixed, isSecondary, moveSecondary, otherProps.className]);

  return (
    <header {...otherProps} className={topPanelClassNames} ref={ref}>
      {children}
    </header>
  )
}) as CompoundComponent;

TopPanelMobile.Center = TopPanelCenter;
TopPanelMobile.Left = TopPanelLeft;
TopPanelMobile.Right = TopPanelRight;

export default TopPanelMobile;
