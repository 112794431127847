import SwiperCore from 'swiper';

/**
 * Helper function to set transition of a node.
 *
 * @param node
 * @param duration
 */
 export function setTransition(node: HTMLDivElement, duration = SwiperCore.defaults.speed ?? 0) {
  if (duration > 0) {
    node.style.transitionDuration = `${duration * 0.001}s`;
  } else {
    node.style.transitionDuration = '';
  }
}
