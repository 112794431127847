import classNames from 'classnames';
import React from 'react';
import kebadCase from 'lodash/kebabCase';

const ListerTitle: React.FC<{
  title?: string;
  buttons?: JSX.Element[];
  hasItems: boolean;
}> = ({ title, buttons, hasItems }) => {
  const titleClasses = classNames('erg-lister-item__title-row', {
    'no-border': !hasItems,
  });

  if (!title && (!buttons || buttons.length === 0)) return null;
  return (
    <div className={titleClasses}>
      {title && <h5 data-qa={`title-${(kebadCase(title))}`}>{title}</h5>}
      {buttons && (
        <div className="erg-lister-item__button-row">
          {buttons.map((button, index) => (
            <React.Fragment key={index}>{button}</React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListerTitle;
