import { useEffect } from 'react';
import keys from 'lodash/keys';
import pick from 'lodash/pick';

type DeprecationWarningMessages<T> = {[key in Partial<keyof T>]?: string};

export default (() => {
  const displayedDeprecationWarnings: string[] = [];

  return function useDeprecationWarning<T>(props: T, messages: DeprecationWarningMessages<T>) {
    const deprecatedKeys = keys(messages) as Array<keyof T>;

    useEffect(function errorOnDeprecatedProp() {
      deprecatedKeys.forEach(key => {
        if (props[key] !== undefined) {
          const prop: string = String(key);

          if (!displayedDeprecationWarnings.includes(prop)) {
            console.warn(`${prop} is deprecated,`, messages[key]);

            displayedDeprecationWarnings.push(prop);
          }
        }
      });
    }, [pick(props, deprecatedKeys)]);
  }
})();
