import {useState} from 'react';

const useStarsRating = (initialRating: number = 0) => {
  const [rating, setRating] = useState(initialRating);
  const [hoveredRating, setHoveredRating] = useState(0);

  const onHoverRating = (value: number) => {
    setHoveredRating(value);
  };

  const onClickRating = (value: number) => {
    setRating(value);
  };

  return {
    rating,
    hoveredRating,
    onHoverRating,
    onClickRating,
  };
};

export default useStarsRating;
