import React, {ChangeEvent, useEffect, useState, useMemo, useRef, HTMLAttributes} from 'react';
import classNames from 'classnames';
import Button from '../../atoms/Button';
import Spinner from '../../atoms/Spinner';
import {useGooglePlacesAutocomplete} from './customHooks';
import {AutoCompletePlaceData} from './types';

import IconError from '@ergeon/icons/svg/icon-error.svg';

import './CitySearchInput.scss';

export type CitySearchInputProps = {
  buttonTitle?: string;
  handleButtonClick: (placeData: AutoCompletePlaceData) => void;
  inputLabel?: string;
  placeholder?: string;
  error?: string;
  isLoading?: boolean;
};

const CitySearchInput = (props: CitySearchInputProps & HTMLAttributes<HTMLDivElement>) => {
  const {
    buttonTitle = 'Search',
    handleButtonClick,
    inputLabel = 'Enter your city',
    placeholder = 'Sacramento',
    error,
    isLoading,
    ...divProps
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const {placeData} = useGooglePlacesAutocomplete(inputRef);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setValue(val);
  };

  const inputClasses = useMemo(
    () =>
      classNames('CitySearchInput-Wrapper', {
        'has-Value': value.length > 0,
      }),
    [value],
  );

  useEffect(
    function updateValue() {
      if (placeData) {
        const {city_name, state_abbreviation, formatted_address} = placeData;
        let displayValue = formatted_address;
        if (city_name && state_abbreviation) {
          displayValue = `${city_name}, ${state_abbreviation}`;
        }
        setValue(displayValue);
        handleButtonClick(placeData);
      }
    },
    [placeData],
  );

  return (
    <div {...divProps} className="CitySearchInput">
      <div className={inputClasses}>
        <input
          name="textinput"
          onChange={handleInputChange}
          placeholder={placeholder}
          autoComplete="off"
          ref={inputRef}
          type="text"
          value={value}
        />
        {!error && <label className="label">{inputLabel}</label>}
        {error && (
          <div className="CitySearchInput-Error">
            <img className="CitySearchInput-Error--Icon" src={IconError} />
            <div className="CitySearchInput-Error--Text">{error}</div>
          </div>
        )}
        <Button
          className="CitySearchInput-Button"
          flavor="cta"
          onClick={() => placeData && handleButtonClick(placeData)}
          size="large"
        >
          {isLoading ? <Spinner active={true} borderWidth={0.1} size={25} /> : buttonTitle}
        </Button>
      </div>
    </div>
  );
};

export default CitySearchInput;
