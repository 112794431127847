/* 
* this values depend on backend for signature img sent back
* and would change only if it has been discussed
*/
export const MAX_IMAGE_SIZE = {
    WIDTH: 462,
    HEIGHT: 214
};

const drawImage = (image: HTMLImageElement, width: number, height: number) => {
  const canvas = document.createElement("canvas");
  canvas.height = height;
  canvas.width = width;
  const ctx = canvas.getContext("2d", {});
  ctx?.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL("image/png");
};

export const resizeSignatureImageCanvas = (image: string, width: number, height: number): Promise<string> => {
  const newImage = new Image();
  newImage.src = image;
  return new Promise((resolve, _reject) => {
    newImage.addEventListener("load", (e: Event) => {
      e.preventDefault();
      resolve(drawImage(newImage, width, height));
    });
  });
};
