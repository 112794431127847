import React from 'react';
import SpecificationTableWithTabs, 
  { ProductConfigTableRow, SpecificationTableTab } from '../../organisms/SpecificationTable/SpecificationTableWithTabs';

export interface ProductConfigTableServiceInterface {
  getTabs: () => SpecificationTableTab[];
  createModelStateRows: () => ProductConfigTableRow[];
  getHeaders: () => string[];
  getRows: () => ProductConfigTableRow[];
}

interface ProductConfigTableProps {
  service: ProductConfigTableServiceInterface;
}

const ProductConfigTable: React.FC<ProductConfigTableProps> = ({ service }) => {
  
  const getTable = () => {
    const tabs = service.getTabs();
    return <SpecificationTableWithTabs tabs={tabs} />;
  };

  return <div>{getTable()}</div>;
};

export default ProductConfigTable;
