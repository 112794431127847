import React, {ReactNode} from 'react';

const TopPanelRightSide = (props: {children: ReactNode}) => {
  const {children} = props;
  return (
    <div className="top-panel__right">
      {children}
    </div>
  )
}

export default TopPanelRightSide;
