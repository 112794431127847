import React from 'react';

import {ReactSVG} from 'react-svg';
import {DropdownItemIconPositionEnum, DropdownItemTypeEnum} from './contants';
import {DropdownListItemProps} from './types';
import classNames from 'classnames';
import iconCheck from '@ergeon/icons/svg/icon-check-mark.svg';

export const DropdownListItem = (props: DropdownListItemProps) => {
  const {item} = props;

  const isIconPositionRight = item.iconPosition === DropdownItemIconPositionEnum.RIGHT;
  const isGroupTitle = item.type === DropdownItemTypeEnum.GroupTitle;
  const iconClasses = classNames('dropdown-list--icon', {
    'icon-right': isIconPositionRight,
    'icon-selected': item.selected,
    'icon-wider': item.widerIcon,
    'icon-grayed': !item.keepOriginalColorsForIcon,
  });

  if (item.type === DropdownItemTypeEnum.Divider) {
    return <div className="dropdown-list__divider" />;
  }

  return (
    <div className={classNames('dropdown-list__item-wrapper', {'group-title': isGroupTitle})}>
      {item.icon && !isIconPositionRight && <ReactSVG className={iconClasses} src={item.icon} />}
      <div className="dropdown-list__item">
        <div className={classNames('dropdown-list__item__text', {selected: item.selected})}>
          {item.label}
          {item.selected && !isIconPositionRight && (
            <ReactSVG className="dropdown-list__item--selected-icon" src={iconCheck} />
          )}
          {item.icon && isIconPositionRight && <ReactSVG className={iconClasses} src={item.icon} />}
        </div>
        {item.subLabel && <p className="dropdown-list__item__subtext">{item.subLabel}</p>}
      </div>
    </div>
  );
};
