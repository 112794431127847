import { useCallback, useEffect, useMemo, useState } from 'react';
import { getWindow } from 'ssr-window';
import sortBy from 'lodash/sortBy';
import throttle from 'lodash/throttle';
import toPairs from 'lodash/toPairs';
import zipObject from 'lodash/zipObject';
import zipWith from 'lodash/zipWith';

import './useResponsiveBreakpoint.scss';

const window = getWindow();

export type ScreenSize =
  // NOTE: these values should be in sync with CSS variables
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg';

const useScreenSizes = () => {
  const getRootValue = useCallback((name: string) => (
    window.getComputedStyle(window.document.body).getPropertyValue(name)
  ), []);

  return useMemo(() => {
    const availableSizes = getRootValue('--screen-sizes').replace(/\s/g, '').split(',');

    return zipObject(
      availableSizes,
      zipWith(
        availableSizes,
        size => parseInt(getRootValue(`--screen-${size}`), 10),
      ),
    );
  }, [getRootValue]) as Record<ScreenSize, number>;
};

const useWindowWidth = () => {
  const [width, setWidth] = useState(() => window.innerWidth);

  const updateWidth = useCallback(throttle(() => {
    setWidth(window.innerWidth);
  }, 100), []);

  useEffect(function onWindowResize() {
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  return width;
};

const useResponsiveBreakpoint = () => {
  type SizePair = [ScreenSize, number];

  const screenSizes = useScreenSizes();
  const windowWidth = useWindowWidth();

  const getValue = useCallback((size: SizePair) => size[1], []);

  const sortedSizes = useMemo(() => (
    sortBy(toPairs(screenSizes), (size: SizePair) => -1 * getValue(size))
  ), [screenSizes]) as SizePair[];

  const sizesLeft = useMemo(() => {
    const startsFrom = sortedSizes.reduce((prev, next) => {
      if (!prev) return next;
      if (windowWidth > getValue(next) && windowWidth < getValue(prev)) return prev;
      if (windowWidth < getValue(prev)) return next;
      return prev;
    });
    if (!startsFrom) return sortedSizes;
    return sortedSizes.slice( sortedSizes.indexOf(startsFrom) );
  }, [sortedSizes, windowWidth]);

  return useMemo(() => {
    return sortedSizes.reduce((obj, [key, value]) => {
      obj[key] = sizesLeft.some(size => getValue(size) === value);
      return obj;
    }, {} as Record<ScreenSize, boolean>);
  }, [sizesLeft]);
};

export const useIsMobile = () => {
  const breakpoint = useResponsiveBreakpoint();

  return useMemo(() => !breakpoint.md, [breakpoint]);
};

export default useResponsiveBreakpoint;
