import React, { HTMLAttributes, useCallback, useMemo, useState }  from 'react';
import Measure, { BoundingRect } from 'react-measure';
import classNames from 'classnames';

import './index.scss';

export type ToggleItem<T> = {
  label: string,
  value: T,
};

type ToggleProps<T> = {
  className?: string,
  items: [ToggleItem<T>, ToggleItem<T>],
  onChange?(value: T): void,
  value?: T,
} & Omit<HTMLAttributes<HTMLButtonElement>, 'onChange'>;

/**
 * Traditional mobile 2-states toggle
 */
function Toggle<T>(props: ToggleProps<T>) {
  const { className, items: [left, right], onChange, value, ...buttonAttr } = props;

  const isToggled = right.value === value;

  const [leftWidth, setLeftWidth] = useState<number>();
  const [rightBounds, setRightBounds] = useState<BoundingRect>();

  // Override default styles of the slider
  const sliderPositionStyles: {left?: number, width?: number} = useMemo(() => {
    if (!(rightBounds || leftWidth)) return {};
    if (isToggled) {
      return {
        left: leftWidth,
        width: rightBounds?.width,
      };
    }
    return {
      left: 0,
      width: leftWidth,
    };
  }, [isToggled, leftWidth, rightBounds]);

  const onToggled = useCallback(() => {
    onChange?.(isToggled ? left.value : right.value);
  }, [isToggled, left, right]);

  return (
    <>
      <button {...buttonAttr} className={classNames('Toggle', { toggled: isToggled }, className)} onClick={onToggled}>
        <Measure bounds onResize={({ bounds }) => setLeftWidth(bounds?.width)}>
          {({ measureRef }) =>
            <span className="Toggle-item" ref={measureRef}>{left.label}</span>
          }
        </Measure>
        <Measure bounds onResize={({ bounds }) => setRightBounds(bounds)}>
          {({ measureRef }) =>
            <span className="Toggle-item" ref={measureRef}>{right.label}</span>
          }
        </Measure>
        <div className="Toggle-slider" style={sliderPositionStyles} />
      </button>
    </>
  );
}

export default Toggle;
