import React, {useCallback} from 'react';
import classNames from 'classnames';

import {CheckboxProps} from './types';

import {CheckIcon} from '@ergeon/icons/hero/micro';
import './index.scss';

const Checkbox = (props: CheckboxProps) => {
  const {checked = false, children, className, disabled = false, onClick, size = 'medium', ...labelAttr} = props;

  const handleClick = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onClick(e.target.checked);
    },
    [onClick],
  );

  return (
    <label
      {...labelAttr}
      className={classNames(className, 'checkbox-container', `is-${size}Size`, {'is-disabled': disabled})}
    >
      <input checked={checked} disabled={disabled} onChange={handleClick} type="checkbox" />
      <div className="checkbox-checkmark">
        <CheckIcon className="size-6" />
      </div>
      {children}
    </label>
  );
};

export default Checkbox;
