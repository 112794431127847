import React, {HTMLAttributes, useMemo} from 'react';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';
import Tooltip from '../../atoms/Tooltip';
import './TableRow.scss';

// Создаем отдельный интерфейс для наших пропсов
export interface TableRowProps {
  content: React.ReactNode;
  contentIcon?: string;
  index: number;
  label: React.ReactNode;
  helpText?: string;
  helpLink?: string;
}

// Объединяем наш интерфейс с HTMLAttributes при типизации компонента
const TableRow: React.FC<TableRowProps & Omit<HTMLAttributes<HTMLDivElement>, 'content'>> = ({
  label,
  content,
  contentIcon,
  index,
  helpText,
  helpLink,
  className,
  ...divAttr
}) => {
  const rowClasses = useMemo(() => classNames(
    'table-row',
    {
      'gray-bg': ((index % 2) !== 0),
    },
    className
  ), [index, className]);

  return (
    <div {...divAttr} className={rowClasses}>
      <span className="table-row__content">
        {contentIcon && (
          <ReactSVG src={contentIcon} className="table-row__content-icon"/>
        )}
        {content}
      </span>
      <div className="table-row__wrapper">
        <span className="table-row__label">{label}</span>
        <span className="table-row__help">
          {(helpText || helpLink) && (
            <Tooltip
              msg={
                <p>
                  {helpText}
                  {helpLink && (
                    <a href={helpLink} target="_blank" rel="noopener noreferrer"> Learn more.</a>
                  )}
                </p>
              }
              position="right"
            >
              <span className="table-row--help-icon"></span>
            </Tooltip>
          )}
        </span>
      </div>
    </div>
  );
};

export default TableRow;