import React, { HTMLAttributes, useMemo } from 'react';
import classNames from 'classnames';

import Toggle, { ToggleItem } from './index';

import './Switch.scss';

interface SwitchProps extends Omit<HTMLAttributes<HTMLButtonElement>, 'onChange'>{
  onChange(value: boolean): void,
  value: boolean,
}

/**
 * Shows a 2-state switch/toggle common for mobile devices.
 * @param props \{ onChange(isChecked: boolean) => void, value }
 */
function Switch(props: SwitchProps) {
  const { onChange, value, className: classNameProps, ...buttonAttr } = props;

  const items: [ToggleItem<false>, ToggleItem<true>] = useMemo(() => ([
    { label: '', value: false },
    { label: '', value: true },
  ]), []);

  return (
    <Toggle {...{ ...buttonAttr, items, onChange, value }} className={classNames(classNameProps, 'Switch')} />
  );
}

export default Switch;
