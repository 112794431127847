import {Dispatch, useMemo} from 'react';

import {SelectOption} from '../types';

interface UseListOptionsProps {
  setIsDropdownShown: Dispatch<boolean>;
  onChange: (selectedOption: SelectOption) => void;
  options: SelectOption[];
}
const useListOptions = (props: UseListOptionsProps) => {
  const {setIsDropdownShown, onChange, options} = props;

  const list = useMemo(
    () =>
      options.map((item) => {
        return {
          label: item.label,
          onClick: () => {
            onChange(item);
            setIsDropdownShown(false);
          },
        };
      }),
    [setIsDropdownShown, onChange, options],
  );

  return list;
};

export default useListOptions;
