import React, { HTMLAttributes, ReactNode } from 'react';

export interface DialogContentProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const DialogContent = (props: DialogContentProps) => {
  const { children } = props;

  return (
    <div className="dialog--content">
      {children}
    </div>
  );
};

export default DialogContent;
