import React from 'react';

import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import {StarIcon} from '@ergeon/icons/hero/mini';
import './index.scss';

const MAX_STAR_COUNT = 5;
const Rating = function ({value: rating}) {
  rating = Number(rating);
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < MAX_STAR_COUNT; i++) {
      const starClasses = ClassNames({
        'rating-icon star': true,
        half: rating - i > 0 && rating - i < 1,
        empty: i >= rating,
      });
      const icon = (
        <li className={starClasses} key={i}>
          <StarIcon className="size-5 text-orange-400" />
        </li>
      );
      stars.push(icon);
    }
    return stars;
  };
  return <ul className="stars-group">{renderStars(rating)}</ul>;
};

Rating.propTypes = {
  value: PropTypes.number,
};

export default Rating;
