import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import './Dialog.scss';

export interface DialogProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  isVisible: boolean;
}

const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const {
    children,
    className,
    isVisible,
    ...divProps
  } = props;

  if (!isVisible) {
    return null;
  }

  return (
    <div ref={ref} {...divProps} className={classNames('dialog-wrapper', className)}>
      {children}
    </div>
  );
});

export default Dialog;
