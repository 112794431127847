import React, {forwardRef, HTMLAttributes} from 'react';
import classNames from 'classnames';
import {ButtonProps as BaseButtonProps} from './types';

export type ButtonProps = BaseButtonProps &
  HTMLAttributes<HTMLButtonElement & HTMLAnchorElement> & {
    isSquare?: boolean;
  };

const Button = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const {
    ariaLabel,
    asAnchor = false,
    className = '',
    disabled = false,
    flavor = 'primary',
    size = 'medium',
    taste = 'solid',
    children,
    href,
    onClick,
    target,
    isFullWidth,
    isSquare,
    ...elementProps
  } = props;

  // Base
  const baseClasses =
    'flex items-center justify-center gap-1 font-heading text-center rounded font-semibold border border-solid transition duration-100 ease-in-out';

  // Solid
  const solidClasses = {
    primary: 'bg-blue-500 border-transparent text-white hover:bg-blue-600',
    cta: 'bg-green-500 border-transparent text-white hover:bg-green-600',
    secondary: 'bg-green-500 border-transparent text-white hover:bg-green-600',
    regular: 'bg-gray-200 border-transparent text-gray-700 hover:bg-gray-300',
    attention: 'bg-red-400 border-transparent text-white hover:bg-red-600',
    action: 'bg-orange-400 border-transparent text-white hover:bg-orange-600',
  };

  // Line
  const lineClasses = {
    primary: 'bg-white text-blue-500 border-blue-500 hover:bg-blue-100',
    cta: 'bg-white text-green-500 border-green-500 hover:bg-green-100',
    secondary: 'bg-white text-green-500 border-green-500 hover:bg-green-100',
    regular: 'bg-white text-gray-700 border-gray-300 hover:bg-gray-200',
    attention: 'bg-white text-red-400 border-red-400 hover:bg-red-100',
    action: 'bg-white text-orange-400 border-orange-400 hover:bg-orange-100',
  };

  // Boundless
  const boundlessClasses = {
    primary: 'bg-transparent text-blue-500 border-transparent hover:bg-blue-100',
    cta: 'bg-transparent text-green-500 border-transparent hover:bg-green-100',
    secondary: 'bg-transparent text-green-500 border-transparent hover:bg-green-100',
    regular: 'bg-transparent text-gray-700 border-transparent hover:bg-gray-200',
    attention: 'bg-transparent text-red-400 border-transparent hover:bg-red-100',
    action: 'bg-transparent text-orange-400 border-transparent hover:bg-orange-100',
  };

  // Size
  const sizeClasses = {
    small: isSquare
      ? 'aspect-square flex items-center justify-center size-6 p-1'
      : 'px-2 pt-1 pb-1.5 text-xs leading-3',
    medium: isSquare
      ? 'aspect-square flex items-center justify-center size-9 p-2'
      : 'px-4 pt-2 pb-2.5 text-sm leading-4',
    large: isSquare
      ? 'aspect-square flex items-center justify-center size-12 p-3'
      : 'px-5 pt-3 pb-3.5 text-base leading-5',
  };

  // Additional
  const additionalClasses = {
    fullWidth: 'w-full',
    disabled: 'opacity-30 pointer-events-none',
  };

  const classes = classNames(
    baseClasses,
    taste === 'solid' && solidClasses[flavor],
    taste === 'line' && lineClasses[flavor],
    taste === 'boundless' && boundlessClasses[flavor],
    sizeClasses[size],
    isFullWidth && additionalClasses.fullWidth,
    disabled && additionalClasses.disabled,
    className,
  );

  const Node = asAnchor ? 'a' : 'button';
  const NodeAttr = elementProps as HTMLAttributes<Node>;

  return (
    <Node
      {...NodeAttr}
      aria-label={ariaLabel}
      className={classes}
      disabled={disabled}
      href={href}
      onClick={onClick}
      ref={ref as unknown as typeof Node}
      target={target}
    >
      {children}
    </Node>
  );
});

export default Button;
