import React from 'react';

import classNames from 'classnames';

import './ProgressBar.scss';

interface ProgressBarProps {
  currentStep: number;
  totalSteps: number;
  className?: string;
}

const ProgressBar = ({currentStep, totalSteps, className}: ProgressBarProps) => {
  const progress = (currentStep / totalSteps) * 100;
  const containerClasses = classNames('progress-bar', className);

  return (
    <div className={containerClasses} role="progressbar">
      <div className="progress-bar__progress" style={{width: `${progress}%`}} />
    </div>
  );
};

export default ProgressBar;
