import moment, { LocaleSpecifier } from "moment";

import { DateISO8601, DateFormat } from "./constants";

export const getIsToday = (date: Date) => {
  return moment(date).isSame(moment(), "day");
};

export const getMonthYear = (date: Date, language: LocaleSpecifier) => {
  return moment(date).locale(language).format("MMMM YYYY");
};

export const getEventTime = (event: {
  date: string;
  time_start: string;
  time_end: string;
}) => {
  return {
    time_start: `${event.date}T${event.time_start}`,
    time_end: `${event.date}T${event.time_end}`,
  };
};

export const toISODate = (date: Date): DateISO8601 => {
  return moment(date).format(DateFormat.ISO8601);
};

/**
 * Check if our current time is way before the first event, otherwise timeline wont
 * be rendered unless current time is between first event.
 * Should be triggered only on our first event
 */
export const isDayStarts = (start: string) => {
  const startDayTime = moment(start);
  return moment().isBetween(moment(), startDayTime);
};

/**
 * Check if current time is between an event, this way if we have special cases on same hours (11-11:30, 11:30-12)
 * we will only render the timeline on the correct interval
 */
export const isCurrentTime = (start: string, end: string) => {
  const startDayTime = moment(start);
  const endDayTime = moment(end);
  return moment().isBetween(startDayTime, endDayTime);
};

export const isAfterTime = (start: string, end: string) => {
  const endDayTime = moment(end);
  // if we have start undefined we assume its the last slot and there are no
  // coming events on that day, so we add + 1 hour to current time, this will render our line at the end
  const startNextTime = start ? moment(start) : moment().add(1, "hour");

  // check if endDayTime is today to avoid painting on past days
  return (
    moment().isSame(endDayTime, "day") &&
    moment().isBetween(endDayTime, startNextTime)
  );
};
