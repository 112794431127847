import React, {useEffect, useCallback, useMemo, useRef, useState, HTMLAttributes} from 'react';
import classNames from 'classnames';
import {ReactSVG} from 'react-svg';

import DropdownList from '../DropdownList';
import {DropdownButtonInterface} from './types';

import iconArrow from '@ergeon/icons/svg/icon-arrow-down.svg';

import './index.scss';

/**
 * This component shows a dropdown when clicked, items should trigger any
 * desired action using onClick event
 * @param props {DropdownButtonInterface}
 * @constructor
 */
const DropdownButton = (props: DropdownButtonInterface & HTMLAttributes<HTMLDivElement>) => {
  const {items, className = '', label, icon, onShowDropdown, onDropdownDismiss, children, ...divAttr} = props;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const triggerBtnnRef = useRef<HTMLButtonElement>(null);
  const [isDropdownShown, setShowDropdown] = useState<boolean>(false);

  useEffect(
    function onOutsideClick() {
      const eventListener = (event: MouseEvent) => {
        if (
          dropdownRef.current?.contains(event.target as Node) ||
          triggerBtnnRef.current?.contains(event.target as Node)
        ) {
          // not an outside click
        } else {
          setShowDropdown(false);
          onDropdownDismiss?.();
        }
      };

      if (isDropdownShown) {
        window.addEventListener('click', eventListener);
      } else {
        window.removeEventListener('click', eventListener);
      }

      return () => window.removeEventListener('click', eventListener);
    },
    [isDropdownShown],
  );

  const list = useMemo(
    () =>
      items.map((item) => {
        return {
          ...item,
          onClick: () => {
            item.onClick?.();
            setShowDropdown(false);
          },
        };
      }),
    [items],
  );

  const triggerDropdown = useCallback(() => {
    setShowDropdown(!isDropdownShown);
    onShowDropdown?.();
  }, [isDropdownShown]);

  const dropdownListClasses = useMemo(
    () =>
      classNames('DropdownButton-dropdown', {
        'is-Visible': isDropdownShown,
      }),
    [isDropdownShown],
  );

  return (
    <div {...divAttr} className={className}>
      <div className="DropdownButton-wrapper">
        <button className="DropdownButton label" ref={triggerBtnnRef} onClick={triggerDropdown}>
          {icon && <ReactSVG className="DropdownButton-icon" src={icon} />}
          {label}
          <ReactSVG className="DropdownButton-arrow" src={iconArrow} />
        </button>
      </div>
      {isDropdownShown && (
        <div ref={dropdownRef} className={dropdownListClasses}>
          <DropdownList className="card shadow__z2" list={list} />
        </div>
      )}
      {children}
    </div>
  );
};

export default DropdownButton;
