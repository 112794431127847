import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import './HighlightNotificationWrapper.scss';

type Flavor = 'primary' | 'regular' | 'attention' | 'cta' | 'action';
type HighlightNotificationWrapperProps = {
  showBadge: boolean;
  badge: number;
  badgeFlavor: Flavor;
  icon: ReactNode;
  children: ReactNode;
  additionalClassName?: string;
} & HTMLAttributes<HTMLDivElement>;

const HighlightNotificationWrapper = (props: HighlightNotificationWrapperProps) => {
  const {
    showBadge,
    badge,
    badgeFlavor,
    icon,
    children,
    additionalClassName,
    ...spanAttr
  } = props;

  const allowBadge = useMemo(() => showBadge && badge, [showBadge, badge]);
  const notificationClassName = useMemo(() => {
    return classNames({
      HighlightNotificationWrapper: true,
      [additionalClassName!]: !!additionalClassName,
    });
  }, [additionalClassName]);

  const badgeClassName = useMemo(() => {
    return classNames('badge', {
      'is--primary': badgeFlavor === 'primary',
      'is--attention': badgeFlavor === 'attention',
      'is--cta': badgeFlavor === 'cta',
      'is--action': badgeFlavor === 'action',
      'is--regular': badgeFlavor === 'regular',
    });
  }, [badgeFlavor]);

  return (
    <>
      <span {...spanAttr} className={notificationClassName}>
        {allowBadge && <span className={badgeClassName}>{badge || 0}</span>}
        {icon}
      </span>
      {children}
    </>
  );
};

export default HighlightNotificationWrapper;
