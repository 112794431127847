import React, {forwardRef, HTMLAttributes, RefObject, useCallback} from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import Input from '../../atoms/Input';
import {InputProps} from '../../atoms/Input/types';

import ArrowIconSVG from '!@svgr/webpack!@ergeon/icons/svg/icon-arrow-left-2.svg';
import CloseIconSVG from '!@svgr/webpack!@ergeon/icons/svg/icon-cross-gray.svg';
import SearchIconSVG from '!@svgr/webpack!@ergeon/icons/svg/icon-search-gray.svg';

import './index.scss';

export interface SearchInputProps extends InputProps {
  isActive: boolean;
  onActiveChange(newActive: boolean): void;
}

type CombinedInputProps = SearchInputProps & { 'data-qa'?: string}

/**
 * This component represents a mobile search input that expands from a magnifier icon
 * to the full-width search bar.
 *
 * [◄- ___________ ✖ 🔍 ]
 *  |  |_ input _| |  |
 *  |_ back btn    |  |_ expand label
 *                 |_ clear text icon
 * @param props
 * @returns
 */
const SearchInput = forwardRef<HTMLInputElement, CombinedInputProps & HTMLAttributes<HTMLInputElement>>((props, inputRef) => {
  const {isActive, onActiveChange, 'data-qa': dataQa, ...inputProps} = props;

  /**
   * Clears and blurs input
   *
   * NOTE: React library overrides input’s value setter but we can call the function directly on it
   */
  const clearInput = useCallback(() => {
    const ref = inputRef as RefObject<HTMLInputElement>;
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype, 'value',
    )?.set;
    nativeInputValueSetter?.call(ref.current, '');
    ref.current?.dispatchEvent(new Event('input', { bubbles: true}));
    ref.current?.focus();
  }, [inputRef]);

  return (
    <fieldset className={classNames('MobileSearch', {active: isActive})}>
      <Input
        {...inputProps}
        ref={inputRef}
        className={`MobileSearch-input ${inputProps.className}`}
        data-qa={dataQa ? `${dataQa}-input` : undefined}/>
      <button
        className="MobileSearch-icon is-backIcon"
        data-qa={dataQa ? `${dataQa}-back-btn` : undefined}
        onClick={() => onActiveChange(false)}>
        <ArrowIconSVG />
      </button>
      <button
        className={classNames('MobileSearch-icon is-clearIcon', {'is-visible': !isEmpty(inputProps.value)})}
        data-qa={dataQa ? `${dataQa}-clear-btn` : undefined}
        onClick={clearInput}>
        <CloseIconSVG />
      </button>
      <label
        className="MobileSearch-icon is-searchIcon"
        data-qa={dataQa ? `${dataQa}-search-label` : undefined}
        htmlFor="search"
        onClick={() => onActiveChange(true)}>
        <SearchIconSVG />
      </label>
    </fieldset>
  );
});

SearchInput.displayName = 'SearchInput';
export default SearchInput;
