export enum Keystroke {
  _1 = 'Digit1',
  _2 = 'Digit2',
  _3 = 'Digit3',
  _4 = 'Digit4',
  _5 = 'Digit5',
  _6 = 'Digit6',
  _7 = 'Digit7',
  _8 = 'Digit8',
  _9 = 'Digit9',
  _0 = 'Digit0',
  A = 'KeyA',
  B = 'KeyB',
  C = 'KeyC',
  D = 'KeyD',
  E = 'KeyE',
  F = 'KeyF',
  G = 'KeyG',
  H = 'KeyH',
  I = 'KeyI',
  J = 'KeyJ',
  K = 'KeyK',
  L = 'KeyL',
  M = 'KeyM',
  N = 'KeyN',
  O = 'KeyO',
  P = 'KeyP',
  Q = 'KeyQ',
  R = 'KeyR',
  S = 'KeyS',
  T = 'KeyT',
  U = 'KeyU',
  V = 'KeyV',
  W = 'KeyW',
  X = 'KeyX',
  Y = 'KeyY',
  Z = 'KeyZ',
  PLUS = 'NumpadAdd',
  MINUS = 'NumpadSubtract',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  ENTER = 'Enter',
  SPACEBAR = 'Space',
  ESCAPE = 'Escape',
}
