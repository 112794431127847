import {useState} from 'react';

import {SelectOption} from '../types';

const useDropdownSelector = (
  value: SelectOption,
  onChange: (newValue: SelectOption) => void,
  options: SelectOption[],
) => {
  const [searchText, setSearchText] = useState(value.label);
  const [isSearching, setIsSearching] = useState(false);
  const [isDropdownShown, setIsDropdownShown] = useState(false);

  const filteredOptions =
    !isSearching || searchText.trim() === ''
      ? options
      : options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  const handleOptionChange = (newValue: SelectOption) => {
    onChange(newValue);
    setIsDropdownShown(false);
    setSearchText(newValue.label);
    setIsSearching(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchText = e.target.value;
    setSearchText(newSearchText);
    setIsSearching(newSearchText.trim() !== '');
  };

  const handleDropdownToggle = () => {
    setIsDropdownShown((prevState) => {
      if (!prevState) {
        setSearchText(value.label);
        setIsSearching(false);
      }
      return !prevState;
    });
  };

  const handleInputBlur = () => {
    if (!searchText.trim()) {
      setSearchText(value.label);
      setIsSearching(false);
    }
  };

  return {
    searchText,
    isSearching,
    isDropdownShown,
    filteredOptions,
    setIsDropdownShown,
    handleOptionChange,
    handleInputChange,
    handleDropdownToggle,
    handleInputBlur,
  };
};

export default useDropdownSelector;
