import React, {HTMLAttributes, useCallback, useEffect, useRef, useState} from 'react';
import ReactImageGallery, {ReactImageGalleryItem} from 'react-image-gallery';
import classNames from 'classnames';

import PopUp from '../../atoms/PopUp';
import Controls from './ImageGalleryControls';
import {slideToAction, slideToIndex} from './utils';

import {getMediaType} from './utils';

import './ImageGalleryPopup.scss';

// Basic setup for the ReactImageGallery
const REACT_IMAGE_GALLERY_BASE_PROPS = {
  lazyLoad: true,
  showBullets: false,
  showFullscreenButton: false,
  showIndex: false,
  showNav: false,
  showPlayButton: false,
  showThumbnails: false,
  slideDuration: 0,
};

type ImageGalleryPopupConfiguration = {
  initialSlide?: number,
  scrollToTop?: boolean,
  showCounter?: boolean,
};

type ImageGalleryPopupProps = {
  images: ReadonlyArray<ReactImageGalleryItem>,
  isVisible: boolean,
  onChangeVisible(isVisible: boolean): void,
  options: ImageGalleryPopupConfiguration,
} & HTMLAttributes<HTMLDivElement>;

const renderVideoItem = (item: ReactImageGalleryItem) => {
  return <video className="video-player" src={item.original} controls/>
};

const getRenderer = (url: string) => {
  if(getMediaType(url) === 'video') {
    return renderVideoItem;
  }

  // for images we will return nothing
  // react-image-gallery will use default renderer in that case
}

export default function ImageGalleryPopup(props: ImageGalleryPopupProps) {
  const {
    images,
    isVisible,
    onChangeVisible,
    options = {},
    ...divAttr
  } = props;

  const {
    initialSlide = 0,
    scrollToTop,
  } = options;

  const [galleryImages, setGalleryImages] = useState(images);
  useEffect(() => {
    const images = galleryImages.map((image) => ({
      ...image,
      renderItem: image.renderItem || getRenderer(image.original)
    }));
    setGalleryImages(images)
  }, []);

  const hasManyImages = images.length > 1;
  const galleryRef = useRef<ReactImageGallery>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(initialSlide);
  const [isFullScreen, setIsFullscreen] = useState(false);

  const onClose = useCallback(() => {
    onChangeVisible(false);
  }, [onChangeVisible]);

  const onSlide = useCallback(() => {
    if (galleryRef.current) {
      // onSlide will correctly trigger our slide index, if we manually dispatch on our onClick actions
      // we will keep increasing/decreasing going outside our range of images, so this will
      // sort that out for us, by syncing our state correctly.
      // Note: onSlide is triggered with every .slideToIndex() call
      setActiveSlideIndex(galleryRef.current.getCurrentIndex());
    }
  }, [galleryRef.current]);

  const onToggleFullScreen = useCallback(() => {
    setIsFullscreen(!isFullScreen);
  }, [isFullScreen]);

  useEffect(function slideToActiveIndex() {
    slideToIndex(galleryRef, activeSlideIndex);
  }, [activeSlideIndex]);

  return (
    <PopUp
      className={classNames('ImageGalleryPopup', {'is-fullscreen': isFullScreen})}
      onHide={onClose}
      showCloseIcon={false}
      scrollToTop={scrollToTop}
      visible={isVisible}>
      <div {...divAttr} className="ImageGalleryPopup-centerWrapper" data-testid="gallery-popup">
        <div className="ImageGalleryPopup-dialog">
          <div className="ImageGalleryPopup-header">
            <Controls.Share image={images[activeSlideIndex]} />
            <div className="flex-spacer" />
            {hasManyImages &&
              <Controls.Counter idx={activeSlideIndex} length={images.length} />
            }
            <div className="flex-spacer" />
            <Controls.Close onClick={onClose} />
          </div>
          <div className="ImageGalleryPopup-content">
            {hasManyImages &&
              <Controls.NavButton kind="left" onClick={() => slideToAction(galleryRef, 'prev')} />
            }
            <div className="ImageGalleryPopup-gallery" key="photo-gallery">
              <ReactImageGallery
                {...REACT_IMAGE_GALLERY_BASE_PROPS}
                ref={galleryRef}
                items={galleryImages}
                startIndex={activeSlideIndex}
                onClick={onToggleFullScreen}
                onSlide={onSlide}
              />
            </div>
            {hasManyImages &&
              <Controls.NavButton kind="right" onClick={() => slideToAction(galleryRef, 'next')} />
            }
          </div>
        </div>
        <div className="ImageGalleryPopup-footer">
          <Controls.Bullets
            idx={activeSlideIndex}
            length={images.length}
            onClick={(i) => slideToIndex(galleryRef, i)}
          />
        </div>
      </div>
    </PopUp>
  );
}
