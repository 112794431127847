import React, {useMemo, useEffect, ReactNode} from 'react';
import classNames from 'classnames';
import Button, {ButtonProps} from 'components/atoms/Button/Button';
import useCopyToClipboard from './useCopyToClipboard';

export type CopyButtonProps = {
  copyText: string;
  title: ReactNode;
  successTitle: ReactNode;
  icon?: ReactNode;
  iconSuccess?: ReactNode;
  className?: string;
} & ButtonProps;

const CopyButton = (props: CopyButtonProps) => {
  const {title, successTitle, copyText, iconSuccess, icon, className, ...buttonProps} = props;

  const {onCopy, isCopied, setIsCopied} = useCopyToClipboard(copyText);

  const containerClassName = useMemo(() => classNames(className), [className]);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  return (
    <Button onClick={onCopy} className={containerClassName} {...buttonProps}>
      {isCopied ? !!iconSuccess && iconSuccess : !!icon && icon}
      {isCopied ? successTitle : title}
    </Button>
  );
};

export default CopyButton;
