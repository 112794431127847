import React, { ReactNode } from 'react';

import '../index.scss';

export type ListSelectElementType = {
  className: string,
  children: ReactNode,
}

const ListSelectElement = ({ className, children }: ListSelectElementType) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
};

export default ListSelectElement;
