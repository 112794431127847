import React from 'react';
import Input from '../../atoms/Input';
import {MagnifyingGlassIcon} from '@ergeon/icons/hero/mini';
import {InputProps} from '../../atoms/Input/types';

import './index.scss';

const SearchInput = (props: InputProps) => {
  const {onChange, value, size = 'small', name, placeholder = 'Search...', label, ...inputAttr} = props;
  const searchIcon = <MagnifyingGlassIcon className="size-5 text-gray-400 absolute right-2"/>;
  return (
    <div className="search-input">
      <Input
        {...inputAttr}
        className="flex items-center"
        name={name}
        onChange={onChange}
        label={label}
        placeholder={placeholder}
        rightIcon={searchIcon}
        size={size}
        value={value}
      />
      <br />
    </div>
  );
};

export default SearchInput;
