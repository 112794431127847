import React, {HTMLAttributes, useMemo} from 'react';
import isNil from 'lodash/isNil';

import {CheckCircleIcon} from '@ergeon/icons/hero/micro';
import {XCircleIcon} from '@ergeon/icons/hero/micro';

interface InputLabelProps extends HTMLAttributes<HTMLLabelElement> {
  name?: string;
  label: string;
  valid?: boolean;
}
/**
 *
 * @param props
 * @returns A label with an icon attached to input by name.
 */
const InputLabel = (props: InputLabelProps) => {
  const {name, label, valid, ...labelAttr} = props;

  const renderLabelIcon = useMemo(() => {
    if (!isNil(valid)) {
      return valid ? (
        <CheckCircleIcon className="size-4 text-green-500" />
      ) : (
        <XCircleIcon className="size-4 text-orange-500" />
      );
    }
    return <span />;
  }, [valid]);

  return (
    <label {...labelAttr} className={`erg-input--label flex gap-1 items-center`} htmlFor={name}>
      {renderLabelIcon}
      {label}
    </label>
  );
};

export default InputLabel;
