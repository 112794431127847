import React from 'react';

import PropTypes from 'prop-types';
import ClassNames from 'classnames';

import './index.scss';

class Spinner extends React.Component {
  static get propTypes() {
    return {
      active: PropTypes.bool,
      borderWidth: PropTypes.number,
      color: PropTypes.oneOf(['blue', 'green', 'white', 'gray', 'lightgray', 'orange', 'red', 'black']),
      size: PropTypes.number,
    };
  }
  constructor(props) {
    super();
    this.state = {
      borderWidth: props.borderWidth || 0.25,
      color: props.color || 'white',
      size: props.size || 16,
    };
  }
  renderLoaderLines() {
    const { borderWidth, size } = this.state;
    const lines = [];
    for (let i = 0; i < 3; i++) {
      lines.push(
        <div
          key={i}
          style={{
            borderWidth: `${Math.round(size * borderWidth)}px`,
            width: `${Math.round(size)}px`,
            height: `${Math.round(size)}px`,
          }}>
        </div>,
      );
    }
    return lines;
  }
  render() {
    const { active } = this.props;
    const { color, size } = this.state;
    const loaderClasses = ClassNames({ [color]: true, 'loader': true });
    if (active) {
      return (
        <div
          className="loader-wrapper"
          style={{ width: `${size}px`, height: `${size}px` }}
          data-testid="spinner">
          <div className={loaderClasses}>
            {this.renderLoaderLines()}
          </div>
        </div>
      );
    }
    return null;
  }
}
export default Spinner;
