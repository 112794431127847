import React, {Fragment, HTMLAttributes, ReactNode} from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import './index.scss';

interface MobileMenuItem extends HTMLAttributes<HTMLButtonElement>{
  active?: boolean;
  icon?: ReactNode;
  title: string;
  onClick(): void;
}

interface MobileMenuSubItem extends Omit<MobileMenuItem, 'icon'> {}

export interface MobileMenuTopItem extends MobileMenuItem {
  items?: MobileMenuSubItem[];
}

type MobileMenuProps = {
  items: MobileMenuTopItem[],
} & HTMLAttributes<HTMLDivElement>;

/**
 * Component that renders a menu link.
 *
 * @param menuItem
 * @returns button ReactNode
 */
const MobileMenuLink = ({active, icon, title, onClick, ...buttonAttr}: MobileMenuItem) => (
  <button {...buttonAttr} className={classNames({'active': active})} onClick={onClick}>
    {icon &&
      <span className="MobileMenu-icon">{icon}</span>
    }
    {title}
  </button>
);

/**
 * Renders mobile menu list.
 *
 * @param props
 * @returns menu list
 */
const MobileMenu = ({items, ...divAttr}: MobileMenuProps) => (
  <nav {...divAttr} className="MobileMenu">
    <ul className="MobileMenu-list">
      {items.map(({items: subitems = [], ...item}, idx) => (
        <Fragment key={`${idx}-${item.title}`}>
          <li className="MobileMenu-listItem">
            <MobileMenuLink {...item} />
          </li>
          {!isEmpty(subitems) && (
            <li className="MobileMenu-listItem">
              <ul className="MobileMenu-listSecondary">
                {subitems.map((subitem, subIdx) => (
                  <li className="MobileMenu-listSecondaryItem" key={`${subIdx}-${subitem.title}`}>
                    <MobileMenuLink {...subitem} />
                  </li>
                ))}
              </ul>
            </li>
          )}
        </Fragment>
      ))}
    </ul>
  </nav>
);

export default MobileMenu;
