import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import ErgAuthService, { AuthApiError, User } from '@ergeon/erg-auth';

type AuthProviderChildrenCallableOptions = {
  user?: User,
  error?: AuthApiError,
  onAuth(): void,
};

type AuthProviderProps = {
  authService: ErgAuthService,
  children(options: AuthProviderChildrenCallableOptions): ReactNode,
};

/**
 * Allows connecting the Auth through passing a necessary options to the child component.
 * Expect from the child component to implement needed UI.
 * @param props auth service and children as a function
 * @returns ReactNode
 */
function AuthProvider(props: AuthProviderProps) {
  const { authService, children } = props;

  const [user, setUser] = useState<User>();
  const [error, setError] = useState<AuthApiError>();

  const onAuth = useCallback(() => {
    authService.authenticate();
  }, [authService]);

  const getUser = useCallback(async () => {
    try {
      setUser(Object.freeze(await authService.retrieveUser()));
    } catch (error) {
      setError(error as AuthApiError);
    }
  }, [authService]);

  useEffect(() => { // onMount
    if (!user) {
      getUser();
    }
    authService.on('refresh', getUser);

    return () => { // onUnmount
      authService.off('refresh', getUser);
    };
  }, [authService, user]);

  return <>{children({ user, error, onAuth })}</>;
}

export default AuthProvider;
