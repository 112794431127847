import React from 'react';

import './BaseQuestionnaireSkeleton.scss';

const BaseQuestionnaireSkeleton = () => {
  return (
    <div className="base-questionnaire-skeleton" data-testid="base-questionnaire">
      <div className="base-questionnaire-skeleton__header">
        <div className="base-questionnaire-skeleton__title" />
      </div>
      <div className="base-questionnaire-skeleton__body">
        <div className="base-questionnaire-skeleton__question" />
        <div className="base-questionnaire-skeleton__description-line" />
        <div className="base-questionnaire-skeleton__description-line" />
        <div className="base-questionnaire-skeleton__options">
          <div className="base-questionnaire-skeleton__option" />
          <div className="base-questionnaire-skeleton__option" />
          <div className="base-questionnaire-skeleton__option" />
        </div>
      </div>
      <div className="base-questionnaire-skeleton__footer">
        <div className="base-questionnaire-skeleton__progress-container">
          <div className="base-questionnaire-skeleton__progress-label" />
          <div className="base-questionnaire-skeleton__progress-bar" />
        </div>
        <div className="base-questionnaire-skeleton__buttons-container">
          <div className="base-questionnaire-skeleton__button" />
          <div className="base-questionnaire-skeleton__button" />
        </div>
      </div>
    </div>
  );
};

export default BaseQuestionnaireSkeleton;
