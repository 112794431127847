import React, { ReactNode } from 'react';

import '../index.scss';

interface TopPanelSideProps {
  placement: 'center' | 'right' | 'left',
  children?: ReactNode
}

const TopPanelSide = ({ placement, children }: TopPanelSideProps) => (
  <div className={`top-panel-mobile__${placement}`}>
    {children}
  </div>
);

export default TopPanelSide;
