import React, { useMemo, useRef, Fragment, useCallback, HTMLAttributes } from "react";
import moment, { LocaleSpecifier } from "moment";
import classNames from "classnames";

import { Line, DateBox, MonthMarker } from "./components";
import {
  getEventTime,
  getIsToday,
  getMonthYear,
  isAfterTime,
  isCurrentTime,
  isDayStarts,
  toISODate,
} from "./utils";
import { Event } from "./mockData";

export interface ScheduleProps {
  events: Event[];
  monthDayRef?: React.RefObject<HTMLDivElement>;
  renderEventCard(event: Event, idx?: number): JSX.Element;
  showNoEventMessage?: boolean;
  noEventText?: string;
  language: LocaleSpecifier;
}

const Schedule = (props: ScheduleProps & HTMLAttributes<HTMLDivElement>) => {
  const {
    events,
    monthDayRef,
    renderEventCard,
    showNoEventMessage = true,
    noEventText = "No events today.",
    language = "en",
    ...divAttr
  } = props;

  const _date = useRef<string>();
  const _month = useRef<string>();
  const _timeline = useRef<boolean>();
  const _hasToday = useRef<boolean>();

  const today = useMemo(() => new Date(), []);

  const renderNoEventDateBox = useCallback(
    (event, idx) => {
      // correctly position today by checking if today isAfter event.date
      // we also need to include MonthMarker in case we are on a new month
      if (showNoEventMessage && moment().isAfter(event.date)) {
        const monthYear = getMonthYear(event.date, language);
        const currentMonthYear = moment().locale(language).format("MMMM YYYY");
        _hasToday.current = true;

        return (
          <div
            className={classNames({ withWhiteSpace: idx !== 0 })}
            ref={monthDayRef}
          >
            {_month.current !== monthYear &&
              (_month.current = currentMonthYear) && (
                <div data-month={toISODate(today)}>
                  <MonthMarker monthYear={currentMonthYear} />
                </div>
              )}
            <div data-date={toISODate(today)}>
              <DateBox
                isToday={true}
                noVisits={true}
                noVisitsText={noEventText}
                numDay={parseInt(moment().format("DD"), 10)}
                weekDay={moment().locale(language).format("ddd")}
              />
            </div>
          </div>
        );
      }
    },
    [showNoEventMessage, today, monthDayRef, _hasToday.current, _month.current]
  );

  const renderMonthDateBox = useCallback(
    (event, idx) => {
      _date.current = event.date;

      const numWeek = moment(event.date).locale(language).format("ddd");
      const numDay = moment(event.date).locale(language).format("D");
      const isBeforeToday = moment(event.date).isBefore(moment(), "day");
      const monthYear = getMonthYear(event.date, language);
      const isToday = getIsToday(event.date);

      return (
        <div
          className={classNames({ withWhiteSpace: idx !== 0 })}
          ref={isToday ? monthDayRef : null}
        >
          {_month.current !== monthYear && (_month.current = monthYear) && (
            <div data-month={_date.current}>
              <MonthMarker monthYear={monthYear} />
            </div>
          )}
          <div
            className={classNames({
              "is-BeforeToday": isBeforeToday,
            })}
            data-date={_date.current}
          >
            <DateBox
              isToday={isToday}
              numDay={parseInt(numDay, 10)}
              weekDay={numWeek}
            />
          </div>
        </div>
      );
    },
    [monthDayRef, _month.current, _date.current]
  );

  const line = useMemo(() => {
    return <Line />;
  }, []);

  const renderCurrentDayLineBeforeEvent = useCallback(
    (event) => {
      const isToday = getIsToday(event.date);

      const { time_start, time_end } = getEventTime(event);

      if (_timeline.current) return;
      if (isToday && isDayStarts(time_start)) {
        _timeline.current = true;
        return line;
      }
      if (isCurrentTime(time_start, time_end)) {
        _timeline.current = true;
        return line;
      }
      return null;
    },
    [_timeline.current, line]
  );

  const renderCurrentDayLineAfterEvent = useCallback(
    (event, idx) => {
      const { time_end } = getEventTime(event);
      const nextEvent = events[idx + 1];
      const nextStart = nextEvent
        ? `${nextEvent.date}T${nextEvent.time_start}`
        : undefined;

      if (_timeline.current) return;
      if (isAfterTime(nextStart as string, time_end)) {
        _timeline.current = true;
        return line;
      }
    },
    [line, events]
  );

  const content = useMemo(() => {
    _date.current = "";
    _month.current = "";
    _hasToday.current = events.some((item) =>
      moment(item.date).isSame(moment(), "day")
    );
    _timeline.current = false;
    return events.map((event, idx) => {
      const key = moment(event.date).format("D");

      return (
        <Fragment key={event?.id ?? `${idx}-${key}`}>
          {!_hasToday.current && renderNoEventDateBox(event, idx)}
          {
            _date.current !== event.date && renderMonthDateBox(event, idx) // renders only one date box
          }
          {renderCurrentDayLineBeforeEvent(event)}
          {renderEventCard(event, idx)}
          {/* send upcoming event.time_start as second parameter */}
          {renderCurrentDayLineAfterEvent(event, idx)}
        </Fragment>
      );
    });
  }, [
    events,
    _hasToday.current,
    _date.current,
    _timeline.current,
    renderNoEventDateBox,
    renderMonthDateBox,
    renderCurrentDayLineBeforeEvent,
    renderEventCard,
    renderCurrentDayLineAfterEvent,
  ]);

  return <div {...divAttr} className="EventsCalendar">{content}</div>;
};

export default Schedule;
