import React from 'react';
import classNames from 'classnames';
import ListerContent from './ListerContent';
import ListerTitle from './ListerTitle';

import './ListerItem.scss';

export interface DataItem {
  title: string;
  value: string | number | JSX.Element;
  defaultValue?: string | number;
}

export interface ListerItemProps extends React.HTMLAttributes<HTMLDivElement> {
  items: DataItem[];
  title?: string;
  buttons?: JSX.Element[];
  renderItem?: (item: DataItem, index: number) => JSX.Element;
}

  const ListerItem: React.FC<ListerItemProps> = ({
     items,
     title,
     buttons,
     className,
     renderItem,
     ...divAttr
  }) => {
  const wrapperClasses = classNames('erg-lister-item rounded-lg', className);

  return (
    <div {...divAttr} className={wrapperClasses}>
      <ListerTitle title={title} buttons={buttons} hasItems={items.length > 0} />
      <ListerContent items={items} renderItem={renderItem} />
    </div>
  );
};

export default ListerItem;
