import React from "react";

export default function NoImgsMessage() {
  console.error('No images to show on Swipe Component');
  return (
    <div className="card image-swipe-thumb__error">
      <div className="error no-data">There are no images to show</div>
    </div>
  );
};
