import React, { HTMLAttributes, useMemo } from 'react';
import { times } from 'lodash';
import classNames from 'classnames';
import omit from 'lodash/omit';

import Shimmer from './Shimmer';

import './Skeleton.scss';

interface SkeletonProps extends HTMLAttributes<HTMLDivElement> {
  width?: number | string;
  height?: number | string;
  count?: number;
  containerClassName?: string;
}

const Skeleton = (props: SkeletonProps) => {
  const { width = "100%", height = 14, count = 1, containerClassName, ...divAttr } = props;
  const divAttributes = omit(divAttr, 'active') // clean up divAttr to keep only valid ones

  const className = useMemo(() => {
    return classNames('skeleton', containerClassName);
  }, [containerClassName]);

  const skeletons = useMemo(() => {
    return times(count, Number).map((item) => {
      return (
        <div
          {...divAttributes}
          key={item}
          style={{
            width,
            height,
          }}
          className={className}
        >
          <Shimmer />
        </div>
      );
    });
  }, [count, width, height, className]);

  return <>{skeletons}</>;
};

export default Skeleton;
