import { useMemo } from 'react';

import { PHONE_NUMBER } from '../../constants';

interface PhoneFormatterOptions {
  countryCode: string;
}

const DEFAULT_PHONE_FORMATTER_OPTIONS: PhoneFormatterOptions = {
  countryCode: '+1',
};

export function getPhoneIntl(phone: string, countryCode: PhoneFormatterOptions['countryCode']) {
  if (!/^\+/.test(phone)) {
    return `${countryCode}${phone}`;
  }
  return phone;
}

export function getPhoneDisplay(phone: string) {
  if (phone === PHONE_NUMBER) {
    return '1-888-ERGEON1';
  }
  const match = phone.match(/^(\+1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match.slice(1).join('-');
  }
  return phone;
}

export function getPhoneFormatter(phone = PHONE_NUMBER, options = DEFAULT_PHONE_FORMATTER_OPTIONS) {
  const rawPhone = typeof phone === 'string' ? phone.replace(/[^\d+]/g, '') : PHONE_NUMBER;
  const phoneIntl = getPhoneIntl(rawPhone, options.countryCode);
  return {
    phone: phoneIntl,
    link: `tel:${phoneIntl}`,
    display: getPhoneDisplay(phoneIntl),
  };
}

export default function usePhoneFormatter(phone = PHONE_NUMBER, options = DEFAULT_PHONE_FORMATTER_OPTIONS) {
  return useMemo(() => getPhoneFormatter(phone, options), [phone]);
}
