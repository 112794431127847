import React, { HTMLAttributes } from 'react';
import Skeleton from './Skeleton';
import Placeholder, {ImagePlaceholderProps} from './Placeholder';

/**
 * @summary ImagePlaceholder usage
 * ImagePlaceholder is meant to be used when loading single images
 *
 * @param {Object} props - Component Props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {number} props.height - Image height value to be use on RectShape
 * @constructor
 */

const ImagePlaceholder = (props: ImagePlaceholderProps & HTMLAttributes<HTMLDivElement>) => {
  const {height, width = '100%', ...divAttr} = props;
  return <Skeleton {...divAttr} height={height} width={width} />;
};

export default Placeholder(ImagePlaceholder);
