import React, { HTMLAttributes, ReactNode } from "react";

import EventCard from "../../../EventCard";

import "./Card.scss";

type CardProps = {
  onClick?(): void;
  containerClassName?: string;
  right?: ReactNode;
  left?: ReactNode;
  address?: ReactNode;
  header?: ReactNode;
  time?: ReactNode;
  title?: ReactNode;
  detail?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const Card = (props: CardProps) => {
  const {
    onClick,
    right,
    left,
    header,
    time,
    title,
    detail,
    address,
    containerClassName,
    ...divAttr
  } = props;

  return (
    <EventCard {...divAttr} className={containerClassName}>
      {!!left && <EventCard.Left>{left}</EventCard.Left>}
      <EventCard.Content onClick={onClick}>
        <p className="EventCard-title is-boldOne">
          {!!header && (
            <span className="EventCard-title is-eventLabel is-eventType">
              {header}
            </span>
          )}
          {!!time && <span className="EventCard-time">{time}</span>}
        </p>
        {!!title && <p className="EventCard-title">{title}</p>}
        {!!address && <p className="EventCard-title is-address">{address}</p>}
        {!!detail && <p className="EventCard-title">{detail}</p>}
      </EventCard.Content>
      {!!right && <EventCard.Right>{right}</EventCard.Right>}
    </EventCard>
  );
};

export default Card;
