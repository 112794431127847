import classNames from 'classnames';
import Button, {ButtonProps} from 'components/atoms/Button';
import {useIsMobile} from 'components/utils/useResponsiveBreakpoint';
import React, {ReactNode, useCallback, useMemo} from 'react';

import './index.scss';
import {ButtonFlavor} from 'components/atoms/Button/types';
import {type NotificationProps} from 'components/molecules/Notification';

type CTAProps = {
  text: string;
} & Omit<ButtonProps, 'children'>;

export type AdvancedNotificationProps = {
  type: NotificationProps['type'];
  className?: string;
  primaryButton?: CTAProps;
  secondaryButton?: CTAProps;
  title: string;
  description?: string | ReactNode;
  extraDescription?: string;
  compactMode?: boolean;
  isFullWidth?: boolean;
  images?: string[];
  icon?: string | ReactNode;
};

const AdvancedNotification = ({
  type = 'Success',
  className,
  primaryButton,
  secondaryButton,
  title,
  description,
  extraDescription,
  compactMode = false,
  isFullWidth = true,
  images,
  icon,
}: AdvancedNotificationProps) => {
  const isMobile = useIsMobile();

  const wrapperClasseNames = useMemo(
    () =>
      classNames('advanced-notification-wrapper', {
        warning: type === 'Warning',
        success: type === 'Success',
        info: type === 'Information',
        error: type === 'Error',
        'full-width': isFullWidth,
        [className || '']: className,
      }),
    [className, type, isFullWidth],
  );

  const compactModeClasseNames = useMemo(
    () =>
      classNames({
        'size-small': compactMode,
        'size-medium': !compactMode,
      }),
    [compactMode],
  );

  const btnFlavor = {
    Warning: 'action',
    Information: 'primary',
    Success: 'cta',
    Error: 'attention',
  }[type] as unknown as ButtonFlavor;

  const renderIcon = useCallback(() => {
    if (typeof icon === 'string') {
      return <img src={icon} alt="Icon" />;
    }
    return icon;
  }, [icon]);

  return (
    <div className={wrapperClasseNames}>
      <div className={classNames('content-container', { 'compact-mode': compactMode })}>
        {icon && <div className="icon-container">{renderIcon()}</div>}
        <div>
          <b className="title">{title}</b>
          <span className={`description ${compactModeClasseNames}`}>
            {description}
            {!isMobile && extraDescription && <>{extraDescription}</>}
          </span>
          {images && (
            <div className="image-grid">
              {images?.map((img) => (
                <div className="grid-img">
                  <img src={img} alt="grid image" key={img} />
                </div>
              ))}
            </div>
          )}
          {isMobile && extraDescription && (
            <span className={`description ${compactModeClasseNames}`}>{extraDescription}</span>
          )}
        </div>
      </div>
      {(primaryButton || secondaryButton) && (
        <div className={`cta-container ${compactModeClasseNames}`}>
          {primaryButton && (
            <Button
              {...primaryButton}
              flavor={btnFlavor}
              onClick={primaryButton.onClick}
              size={isMobile ? 'medium' : 'large'}
              isFullWidth
            >
              {primaryButton.text}
            </Button>
          )}
          {secondaryButton && (
            <Button
              {...secondaryButton}
              flavor="regular"
              taste="line"
              onClick={secondaryButton.onClick}
              size={isMobile ? 'medium' : 'large'}
              isFullWidth
              className={`button-overrides ${secondaryButton?.className}`}
            >
              {secondaryButton.text}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default AdvancedNotification;
