import React from 'react';

import ErgeonLink from '../../atoms/ErgeonLink';

import './LocationsFooter.scss';

type LocationFooterProps = {
  listArray: { text: string; url: string }[];
  listTitle?: string;
  widthClass?: string;
};

const LocationsFooter = (props: LocationFooterProps) => {
  const { listArray, listTitle } = props;

  return (
    <nav className="LocationsFooter">
      {listTitle && <div className="label uppercase">{listTitle}</div>}
      <ul className="LocationsFooter-Column">
        {listArray.map((item, index) => {
          return (
            <li key={`location-${index}`}>
              <ErgeonLink
                className="link"
                href={item.url}
                key={`${item.text}-${index}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {item.text}
              </ErgeonLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default LocationsFooter;
