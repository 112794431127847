import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  createValidator,
  cityAddress,
  required,
} from '../../../libs/utils/validation';
import { parsePlace } from '../../../libs/utils/places';
import { getGoogleLoader } from '../../../libs/integrations/google';
import Spinner from '../../atoms/Spinner';
import Button from '../../atoms/Button';

import './index.scss';
export default class AddressInput extends React.Component {
  static get propTypes() {
    return {
      buttonFlavor: PropTypes.oneOf([
        'primary',
        'cta',
        'secondary',
        'regular',
        'attention',
        'action',
      ]),
      buttonTitle: PropTypes.string,
      getCheckedZIP: PropTypes.func,
      inputLabel: PropTypes.string,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
      onLoadEnds: PropTypes.func,
      onLoadStarts: PropTypes.func,
      onSubmit: PropTypes.func,
      product: PropTypes.string,
      showButton: PropTypes.bool,
      value: PropTypes.string,
    };
  }

  static defaultProps = {
    buttonFlavor: 'cta',
    buttonTitle: 'Get a quote',
    inputLabel: 'Enter your street address',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      lead: null,
      error: null,
    };

    this.validator = createValidator({
      address: [required, cityAddress],
    });
  }

  componentDidMount() {
    getGoogleLoader().load().then(google => {
      this.google = google;
    });
  }

  initAutocomplete = (google, input) => {
    // Create the autocomplete object, restricting the search to geographical
    // location types.
    this.autocomplete = new google.maps.places.Autocomplete(
      input,
      {
        types: ['geocode'],
        componentRestrictions: { country: 'us' },
      },
    );

    // When the user selects an address from the dropdown, populate the address
    // fields in the form.
    this.autocomplete.addListener('place_changed', this.fillInAddress);
  };

  getFormFieldValue(place) {
    return place.formatted_address || '';
  }

  // eslint-disable-next-line complexity
  fillInAddress = () => {
    // Get the place details from the autocomplete object.
    const { product, getCheckedZIP, onLoadStarts, onLoadEnds, onChange } = this.props;
    const place = this.autocomplete.getPlace();
    const placeData = parsePlace(place);

    const error = this.validator({
      address: placeData,
    });

    const data = {
      address: placeData,
      'product_slug': product,
    };

    if (error) {
      this.setState({
        error: error.address,
        value: this.getFormFieldValue(place),
      });
      onChange && onChange(this.getFormFieldValue(place));
    } else {
      this.setState({
        loading: true,
        value: this.getFormFieldValue(place),
      });
      onChange && onChange(this.getFormFieldValue(place));
      onLoadStarts && onLoadStarts(place);

      getCheckedZIP && getCheckedZIP(placeData.zipcode, placeData).then(result => {
        data['productAvailability'] = result.data;
        this.setState({
          loading: false,
          lead: data,
        });
        onLoadEnds && onLoadEnds();
        this.submitSuccess(data);
      }, error => {
        console.log(error);
        let errorMessage = 'Server is not responding'
        if(error?.response?.status == 404) {
          errorMessage = 'City or zipcode not currently supported'
        }
        this.setState({
          loading: false,
          error: errorMessage,
          lead: null,
        });
        onLoadEnds && onLoadEnds();
      });
    }
  };

  submitSuccess = (lead) => {
    const { onSubmit } = this.props;

    onSubmit && onSubmit(lead);
  };

  getValue() {
    return (this.props.value !== undefined) ? this.props.value : this.state.value;
  }

  handleInputChange = (e) => {
    const { onChange } = this.props;
    const value = e.target.value;
    this.setState({
      value,
      error: '',
    });
    onChange && onChange(value);
  };

  handleInputFocus = () => {
    const { onFocus } = this.props;
    onFocus?.();
    if (this.autocomplete || !this.google) {
      return;
    }
    this.initAutocomplete(this.google, this.input);
  };

  handleButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { lead } = this.state;

    if (lead) {
      this.submitSuccess(lead);
    } else {
      this.setState({
        error: 'Select your address from the list below',
      });
    }
  };

  render() {
    const { showButton } = this.props;
    const { loading, error } = this.state;
    const value = this.getValue();
    const addressInputClassName = classNames({
      'address-input': true,
      'is-loading': loading,
      'is-has-value': value,
      'is-error': error,
    });
    const addressButtonClassName = classNames({
      'address-button': true,
      'is-loading': loading,
    });
    const { buttonFlavor, buttonTitle, inputLabel } = this.props;
    return (
      <div className={addressInputClassName}>
        <input
          disabled={loading}
          name="textinput"
          onChange={this.handleInputChange}
          onFocus={this.handleInputFocus}
          placeholder="1234 Street, Sacramento, CA"
          ref={(input) => this.input = input}
          type="text"
          value={value} />
        <label className="label">{inputLabel}</label>
        <div className="address-input-error">{error}</div>
        {showButton && <Button
          className={addressButtonClassName}
          disabled={loading}
          flavor={buttonFlavor}
          onClick={this.handleButtonClick}
          size="large">
          {loading ?
            <Spinner active={true} borderWidth={0.10} size={25} /> :
            buttonTitle}
        </Button>}
      </div>
    );
  }
}
