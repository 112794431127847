import React, { HTMLAttributes, useCallback } from 'react';

import Popup from '../../new/components/Popup';
import useDeprecationWarning from '../../utils/useDeprecationWarning';

interface PopUpProps extends HTMLAttributes<HTMLDivElement> {
  onHide(): void;
  rootNode?: HTMLElement;
  scrollToTop?: boolean;
  shouldBlockBodyScroll?: boolean;
  showCloseIcon?: boolean;
  visible?: boolean;
}

const DEFAULT_PROPS: Partial<PopUpProps> = {
  visible: false,
  scrollToTop: true,
  showCloseIcon: true,
};

function DeprecatedPopup(props: PopUpProps) {
  const {
    children,
    onHide,
    rootNode,
    scrollToTop,
    shouldBlockBodyScroll,
    showCloseIcon: isCloseIconShown,
    visible: isVisible,
    ...divProps
  } = { ...DEFAULT_PROPS, ...props };

  const onChangeVisible = useCallback((visible: boolean) => {
    if (!visible) {
      onHide?.();
    }
  }, [onHide]);

  useDeprecationWarning(props, {
    scrollToTop: 'remove since scroll behavior was fixed',
    shouldBlockBodyScroll: 'takes no effect, remove since scroll behavior was fixed',
  });

  return (
    <Popup
      data-testid="popup-container"
      {...divProps}
      id={undefined}
      isCloseIconShown={isCloseIconShown}
      isVisible={isVisible!}
      onChangeVisible={onChangeVisible}
      rootNode={rootNode as HTMLElement}>
        {children}
    </Popup>
  );
}

export default React.memo(DeprecatedPopup);
