import React, { Children, HTMLAttributes, ReactNode } from 'react';

import './index.scss';

type BottomNavbarProps = {
  children: ReactNode,
} & HTMLAttributes<HTMLDivElement>;

/**
 * Fixed bottom navbar layout
 */
function BottomNavbar({ children, ...divAttr }: BottomNavbarProps) {
  return (
    <>
      <nav {...divAttr} className="BottomNavbar">
        <ul className="BottomNavbar-content">
          {Children.map(children, (item, idx) => (
            <li className="BottomNavbar-item" key={idx}>
              {item}
            </li>
          ))}
        </ul>
      </nav>
      <div className="BottomNavbar-spacer" />
    </>
  );
}

export default BottomNavbar;
