import React, {useEffect, useMemo} from 'react';

import {ReducedAttribute, Value} from '../types';

import Option from './Option';
import RadioGroup from '../../../molecules/RadioGroup';

interface RadioOptionsProps {
  selectedParam?: ReducedAttribute;
  selectedOptionCode?: string;
  onChangeOption: (id: number, value: string) => void;
}

const RadioOptions = ({
  selectedParam,
  selectedOptionCode,
  onChangeOption,
}: RadioOptionsProps) => {
  if (!selectedParam?.options) {
    return null;
  }

  const definedOptions = selectedParam.options.filter(Boolean) as Value[];
  const optionsWithImages = definedOptions.filter((option) => option.image);
  const optionsWithoutImages = definedOptions.filter((option) => !option.image);

  const allOptions = useMemo(
    () => [...optionsWithImages, ...optionsWithoutImages],
    [optionsWithImages, optionsWithoutImages],
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault();
        const currentIndex = allOptions.findIndex((obj) => obj.code === selectedOptionCode);
        let newIndex;

        if (event.key === 'ArrowUp') {
          newIndex = currentIndex > 0 ? currentIndex - 1 : allOptions.length - 1;
        } else {
          newIndex = currentIndex < allOptions.length - 1 ? currentIndex + 1 : 0;
        }
        onChangeOption(selectedParam.id, allOptions[newIndex].code);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [allOptions, selectedParam.id, selectedOptionCode]);

  return (
    <RadioGroup
      name="option"
      onChange={(value) => onChangeOption(selectedParam.id, value)}
      selectedValue={selectedOptionCode}
    >
      {optionsWithImages.length > 0 && (
        <ul className="grid-wrapper">
          {optionsWithImages.map((option) => (
            <Option key={`${selectedParam.id}-${option.code}`} {...option} />
          ))}
        </ul>
      )}
      {optionsWithoutImages.length > 0 && (
        <ul className="plain-column-wrapper">
          {optionsWithoutImages.map((option) => (
            <Option key={`${selectedParam.id}-${option.code}`} {...option} />
          ))}
        </ul>
      )}
    </RadioGroup>
  );
};

export default RadioOptions;
