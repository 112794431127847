import React, {ReactNode, useState, useEffect} from 'react';
import {addGetParamsToUrl, getParsedParams, UPCOMING_FEATURES_PARAM} from '@ergeon/erg-utils-js';
import {useHistory, useLocation} from 'react-router-dom';

/**
 * Component that adds an `upcoming-features` get param to current url if it is exists in prev url
 * @param props
 */
const UpcomingFeaturesHandler: React.FC<{children: ReactNode}> = (props) => {
  const {children} = props;
  const {pathname, search} = useLocation();
  const history = useHistory();
  const [upcomingFeatures, setUpcomingFeatures] = useState(false);

  useEffect(() => {
    setUpcomingFeatures(search.includes(UPCOMING_FEATURES_PARAM));
  }, [search]);

  useEffect(() => {
    const parsed = getParsedParams(search);
    if(!parsed[UPCOMING_FEATURES_PARAM] && upcomingFeatures) {
      const url = addGetParamsToUrl(`${pathname}${search}`, {[UPCOMING_FEATURES_PARAM]: true});
      history.replace(url);
    }
  }, [pathname, search]);

  return (
    <>
      {children}
    </>
  );
}

export default UpcomingFeaturesHandler;
