import React, {FunctionComponent} from 'react';
import ReactFlickity from 'react-flickity-component';

import './Flickity.scss';

const Flickity: FunctionComponent = ({children}) => {
  return (
    <ReactFlickity
      className="Flickity"
      elementType='div'
      options={{
        initialIndex: 0,
        wrapAround: false,
        cellAlign: 'left',
        contain: false,
        // disable previous & next buttons and dots
        prevNextButtons: false,
        pageDots: false,
        // slower sticky transitions
        selectedAttraction: 0.01,
        friction: 0.15,
      }}
      disableImagesLoaded={false}
      reloadOnUpdate
      static>
      {children}
    </ReactFlickity>
  );
};

export default Flickity;
