export enum FooterDefaults {
  CitiesUrl = 'https://www.ergeon.com/cities/',
  ErgeonUrl = 'https://www.ergeon.com/',
  FencequotingUrl = 'https://fencequoting.com/',
  ProductCatalogUrl = 'https://www.ergeon.com/catalog/',
  UTMquery = '',
  WidthClass = 'wrapper-1180',
}

export enum TypeOfFooter {
  Default = 'Default',
  Simple = 'Simple',
  Minimal = 'Minimal',
}
