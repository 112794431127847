export enum DropdownItemTypeEnum {
  Divider = 'Divider',
  ListItem = 'ListItem',
  GroupTitle = 'GroupTitle',
}

export enum DropdownItemIconPositionEnum {
  LEFT = 'left',
  RIGHT = 'right',
}
