import React, { HTMLAttributes } from 'react';
import './index.scss';

type BannerCalcProps = {
  fencequotingUrl?: string
} & HTMLAttributes<HTMLDivElement>;

const BannerCalc = (props:BannerCalcProps) => {
  const {
    fencequotingUrl = 'https://fencequoting.com/',
    ...divProps
  } = props;
  return (
    <div {...divProps} className="banner">
      <div className="promo-img"/>
      <div className="desc">
        <h3>Try our fence calculator</h3>
        <p className="additional-header h2">Customize this fence to your specific requirements </p>
        <a className="primary-button margin-top" href={fencequotingUrl}>Fence Calculator</a>
      </div>
    </div>
  );
};

export default BannerCalc;
