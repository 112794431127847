import React, { forwardRef, HTMLAttributes, ReactNode, useCallback } from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import { DialogClose } from '../Dialog';

import getRootNode from './getRootNode';
import useBlockBodyScroll from './useBlockBodyScroll';

import './Popup.scss';

interface PopupVisibility {
  isVisible: boolean;
  onChangeVisible?(isVisible: boolean): void;
}

export interface PopupProps extends PopupVisibility, HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  id?: undefined; // since it is used in body scroll
  isCloseIconShown?: boolean;
  rootNode?: HTMLElement;
}

const Popup = forwardRef<HTMLDivElement, PopupProps>((props, ref) => {
  const {
    children,
    className,
    isCloseIconShown,
    isVisible,
    onChangeVisible,
    rootNode = getRootNode() as HTMLElement,
    ...divProps
  } = props;

  const { id } = useBlockBodyScroll(isVisible, { rootNode });

  const onClose = useCallback(() => {
    onChangeVisible?.(false);
  }, [onChangeVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <Portal node={document.body}>
      <div
        ref={ref}
        {...divProps}
        className={classNames('popup--container', { 'is-Visible': isVisible }, className)}
        id={id}>
          <div className="popup--tint" onClick={onClose} />
          <div className="popup--wrapper">
            {isCloseIconShown && (
              <DialogClose className="popup--cross-icon" onClose={onClose} />
            )}
            <div className="popup--content">
              {children}
            </div>
          </div>
      </div>
    </Portal>
  );
});

export default Popup;
