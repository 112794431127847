import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';

type MetaConfig = {
  title: string,
  description: string,
  canonical?: string,
  noindex?: boolean,
  keywords?: string,
};

/**
 * This component can optionally update meta tags every time the location is changed.
 * @param props {MetaTagsProps} {getMeta}
 *   - `getMeta` - function that should retrieve meta from given path, returns either `MetaConfig` or `null`
 * @returns
 */
 const MetaTags = ({getMeta}: {getMeta(path: string): Promise<MetaConfig | null>}) => {
  // We are going to store meta in state to easily asynchronously update it.
  const [meta, setMeta] = useState<MetaConfig | null>();

  const pathname = useMemo(() => window.location.pathname, [window.location.pathname]);

  // Get meta from path, aka refresh meta.
  const assignMetaForPath = useCallback(async () => {
    setMeta(await getMeta(pathname));
  }, [getMeta, pathname]);

  // Assign fresh meta.
  useEffect(function refreshMeta() {
    assignMetaForPath();
  }, [assignMetaForPath]);

  /**
   * Force meta update fixes title re-writes bug on tab activated,
   * see https://github.com/nfl/react-helmet/issues/462.
   */
  useEffect(function refreshMetaOnTabActive() {
    window.addEventListener('focus', assignMetaForPath);
    return () => {
      window.removeEventListener('focus', assignMetaForPath);
    };
  }, [assignMetaForPath]);

  if (!meta) return null;

  return (
    <Helmet>
      <title key={Math.random()}>{meta.title}</title>
      <meta content={meta.description} name="description" />
      {meta.canonical && <link href={meta.canonical} rel="canonical" />}
      {meta.noindex && <meta content="noindex" name="robots" />}
      {meta.keywords && <meta name="keywords" content={meta.keywords} />}
      {meta.title && <meta name="title" content={meta.title} />}
    </Helmet>
  );
 };

 export default MetaTags;
