import React, { memo } from 'react';

import classNames from 'classnames';

import './MinimalFooter.scss';
import TermsAndPolicy from './TermsAndPolicy';

type MinimalFooterProps = {
  className?: string;
  appEnv?: string;
};

const MinimalFooter = (props: MinimalFooterProps) => {
  const { className = '', appEnv = 'production' } = props;

  const footerClassNames = classNames({
    'minimal-footer': true,
    [className]: className,
  });

  return (
    <div className="minimal-footer__wrapper" data-testid="footer-component">
      <div className={footerClassNames}>
        <TermsAndPolicy appEnv={appEnv} agreementText={<>By creating an account, you agree to the <br /></>}/>
      </div>
    </div>
  );
};

export default memo(MinimalFooter);
