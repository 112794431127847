import { RefObject } from "react";
import ReactImageGallery from "react-image-gallery";
import isNil from 'lodash/isNil';

class NeverActionError extends Error {
  constructor(action: never) {
    super(`There is no handler for ${action}`);
  }
}

/**
 * Slides to new Index
 * @param galleryRef - ReactImageGallery ref element
 * @param newSlideIndex - Index Number
 */
export function slideToIndex(galleryRef: RefObject<ReactImageGallery>, newSlideIndex: number) {
  if (!galleryRef?.current) {
    return;
  }
  if (isNil(newSlideIndex)) {
    return;
  }
  galleryRef.current.slideToIndex(newSlideIndex);
}

/**
 * Slides to new Index using prev/next buttons
 * @param galleryRef - ReactImageGallery ref element
 * @param action - 'next'|'prev'
 */
export function slideToAction(galleryRef: RefObject<ReactImageGallery>, action: 'prev' | 'next') {
  if (!galleryRef?.current) {
    return;
  }
  let newIndex = 0;
  const currentIndex = galleryRef.current.getCurrentIndex();
  switch (action) {
    case 'prev':
      newIndex = currentIndex - 1;
      break;
    case 'next':
      newIndex = currentIndex + 1;
      break;
    default:
      // throw a TS error if there is no action not covered by switch case
      throw new NeverActionError(action);
  }
  slideToIndex(galleryRef, newIndex);
}

export function getMediaType(url: string) {
  const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv', '.flv', '.webm', '.mpd'];

  let extension = url.split('.').pop();

  if (extension) {
    extension = extension.toLowerCase();
    if (videoExtensions.includes('.' + extension)) {
      return 'video';
    }
  }
  return 'image';
}
