import React, {useMemo} from 'react';

import {FooterDefaults, TypeOfFooter} from './constants';
import ErgeonLink from '../../atoms/ErgeonLink';

import {ReactSVG} from 'react-svg';

import ErgeonLogo from '!@svgr/webpack!../../../assets/ergeon-logo-blue.svg';
import OneTreeLogo from '@ergeon/icons/svg/onetreee-logo.svg';

import LocationsFooter from './LocationsFooter';
import MenuFooter from './MenuFooter';
import SocialFooter from './SocialFooter';
import DetailsFooter from './DetailsFooter';
import TermsAndPolicy from './TermsAndPolicy';
import useFooter from './useFooter';
import {SimpleFooterProps} from './types';

import './SimpleFooter.scss';

const SimpleFooter = (props: SimpleFooterProps) => {
  const {
    ergeonUrl = FooterDefaults.ErgeonUrl,
    fencequotingUrl = FooterDefaults.FencequotingUrl,
    UTMquery = FooterDefaults.UTMquery,
    widthClass = FooterDefaults.WidthClass,
    citiesUrl = FooterDefaults.CitiesUrl,
    withTreeLogo,
    withSocialLogo = true,
    phoneNumber,
    address,
    licenses,
    locationFooterTitle,
    locationFooterArray,
    isLocationFooter,
    appEnv = 'production',
    typeOfFooter = TypeOfFooter.Simple,
    ...divProps
  } = props;

  const {getErgeonURL, menuItems} = useFooter({
    UTMquery,
    ergeonUrl,
    fencequotingUrl,
    typeOfFooter,
    citiesUrl,
  });

  const hasPhoneOrAddressOrLicenses = useMemo(
    () => phoneNumber || address || licenses?.length,
    [phoneNumber, address, licenses],
  );

  return (
    <footer {...divProps} className="footer-wrapper">
      <div className="footer-wrapper__shadow" />
      <div className="SimpleFooter">
        <div className={widthClass}>
          <div className="SimpleFooter-Content">
            <div className="SimpleFooter-Links">
              <div className="InfoFooter">
                <div className="InfoFooter-Info">
                  <div className="SimpleFooter-Logo">
                    <ErgeonLink className="Ergeon-Logo" href={getErgeonURL()}>
                      <ErgeonLogo />
                    </ErgeonLink>
                    <TermsAndPolicy appEnv={appEnv} />
                    {withSocialLogo && <SocialFooter />}
                    {withTreeLogo && (
                      <div className="one-tree">
                        <ReactSVG src={OneTreeLogo} />
                        <p>
                          For every project <br />
                          completed
                        </p>
                      </div>
                    )}
                  </div>
                  {hasPhoneOrAddressOrLicenses && (
                    <DetailsFooter address={address} licenses={licenses} phoneNumber={phoneNumber} />
                  )}
                </div>
                <MenuFooter itemsList={menuItems} />
              </div>
              {isLocationFooter && (
                <LocationsFooter listArray={locationFooterArray || []} listTitle={locationFooterTitle || ''} />
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SimpleFooter;
