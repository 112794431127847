import React, { ReactNode } from 'react';

import ListSelectElement from './ListSelectElement';

import '../index.scss';

const ListSelectIcon = ({ children }: { children: ReactNode }) => {
  return (
    <ListSelectElement className="ListSelect-Icon">
      {children}
    </ListSelectElement>
  )
};

export { ListSelectIcon };
