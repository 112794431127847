import React, {Dispatch, RefObject, useEffect, useMemo, useState} from 'react';

import {ReactSVG} from 'react-svg';
import classNames from 'classnames';

import iconPlus from '@ergeon/icons/svg/icon-plus-2.svg';
import iconMinus from '@ergeon/icons/svg/icon-minus-2.svg';
import './AccordionRow.scss';

export interface IAccordionRow {
  children: React.ReactNode;
  className?: string;
  classNameTitle?: string;
  expanded?: boolean;
  setExpanded?: Dispatch<boolean>;
  rightSlot?: JSX.Element;
  title: string;
  titleRef?: RefObject<HTMLDivElement>;
}

const AccordionRow = (props: IAccordionRow) => {
  const {
    children,
    className = '',
    classNameTitle = '',
    expanded = false,
    setExpanded,
    titleRef,
    rightSlot,
    title,
  } = props;
  const [expandedState, setExpandedState] = useState(children ? expanded : false);

  useEffect(() => setExpandedState(expanded), [expanded]);

  const handleClick = () => {
    if (children) {
      setExpandedState(!expandedState);
      setExpanded && setExpanded(!expandedState);
    }
  };

  const rowClasses = useMemo(() => classNames('accordion-row', className), [className]);

  const titleClasses = useMemo(
    () =>
      classNames('accordion-row__title', classNameTitle, {
        expanded: expandedState,
        'no-children': !children,
      }),
    [expandedState, children, classNameTitle],
  );

  const iconClasses = useMemo(
    () => classNames('accordion-row__title__left__icon', {expanded: expandedState}),
    [expandedState],
  );

  const contentClasses = useMemo(
    () =>
      classNames('accordion-row__content-wrapper', {
        expanded: expandedState,
      }),
    [expandedState],
  );

  const icon = useMemo(() => (expandedState ? iconMinus : iconPlus), [expandedState]);

  return (
    <div className={rowClasses}>
      <div className={titleClasses} draggable={Boolean(titleRef)} onClick={handleClick} ref={titleRef}>
        <div className="accordion-row__title__left">
          <ReactSVG className={iconClasses} onClick={handleClick} src={icon} />
          <span className="label uppercase">{title}</span>
        </div>
        {rightSlot && (
          <div className="accordion-row__title__right" onClick={(e) => e.stopPropagation()}>
            {rightSlot}
          </div>
        )}
      </div>
      <div className={contentClasses}>
        <div className="accordion-row__content">{children}</div>
      </div>
    </div>
  );
};

export default AccordionRow;
