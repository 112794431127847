import React, { ReactNode } from 'react';

import ListSelectElement from './ListSelectElement';

import '../index.scss';

const ListSelectText = ({ children }: { children: ReactNode }) => {
  return (
    <ListSelectElement className="ListSelect-Text">
      {children}
    </ListSelectElement>
  )
};

export { ListSelectText };
