import React, { ChangeEvent, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import isNil from 'lodash/isNil';

import withOptionsWidths from './withOptionsWidths';

import './index.scss';

export type OptionType = {
  label: string;
  value: string;
  width?: number;
}

export interface NativeSelectInterface extends Omit<HTMLAttributes<HTMLSelectElement>, 'onChange'> {
  className?: string;
  onChange?: (selectedOption: string) => void;
  options: OptionType[];
  placeholder?: string;
  value?: string;
}

/**
 * This component shows a native device select picker and calls `props.onChange`
 * when one of them is selected.
 * @param props {NativeSelectInterface}
 * @constructor
 */
export const NativeSelect = (props: NativeSelectInterface) => {
  const { onChange, options, className = '', placeholder, value, ...divAttr } = props;
  const [selectedValue, setSelectedValue] = useState(value || options[0].value);

  const handleChange = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
    onChange?.(event.target.value);
    setSelectedValue(event.target.value);
  }, [onChange, setSelectedValue]);

  useEffect(function componentMount() {
    // if there is no placeholder and no value we should set the first option if any
    if (!placeholder) {
      onChange?.(isNil(value) ? options[0]?.value : value);
    }
  }, []);

  return (
    <div  className={className}>
      <label className="mobile-select-picker__label">
        <select
          {...divAttr}
          style={{
            width: options.find(elem => elem.value === selectedValue)?.width /* stylelint-disable-line */
          }}
          className="mobile-select-picker"
          defaultValue={placeholder}
          onChange={handleChange}
          value={value}>
          {placeholder &&
            <option value={placeholder} disabled>{placeholder}</option>
          }
          {options.map((elem, i) =>
            <option key={`${i}-${elem.value}`} value={elem.value}>{elem.label}</option>
          )}
        </select>
      </label>
    </div>
  )
}

export default withOptionsWidths(NativeSelect);
