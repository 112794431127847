import React, {useState} from 'react';

import {ReactSVG} from 'react-svg';
import placeholder from '@ergeon/icons/icons/3d-lib-photo.svg';

interface BaseQuestionnaireImageProps {
  url?: string;
}

const BaseQuestionnaireImage = ({url}: BaseQuestionnaireImageProps) => {
  const [isError, setIsError] = useState(false);

  const handleError = () => {
    setIsError(true);
  };

  return (
    <div className="base-questionnaire__image-container">
      {isError || !url ? (
        <ReactSVG className="base-questionnaire__image base-questionnaire__image_placeholder" src={placeholder} />
      ) : (
        <img className="base-questionnaire__image" onError={handleError} src={url} />
      )}
    </div>
  );
};

export default BaseQuestionnaireImage;
