import React, { HTMLAttributes, ReactNode } from 'react';

export interface DialogButtonsProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
}

const DialogButtons = (props: DialogButtonsProps) => {
  const { children } = props;

  return (
    <>
      <div className="dialog--divider" />
      <div className="dialog--buttons-section">
        {children}
      </div>
    </>
  );
};

export default DialogButtons;
