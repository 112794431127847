import React, {Dispatch, SetStateAction, useCallback} from 'react';

import FixedNotification from './FixedNotification';
import {INotification} from './types';

import './FixedNotifications.scss';

interface IFixedNotifications {
  notifications: INotification[];
  setNotifications: Dispatch<SetStateAction<INotification[]>>;
}

/**
 * This component renders floating notification with warnings and errors in the left bottom corner.
 */
const FixedNotifications = (props: IFixedNotifications) => {
  const {notifications, setNotifications} = props;
  const closeNotification = useCallback(
    (index: number) => setNotifications((prev) => prev.filter((_, i) => i !== index)),
    [],
  );
  if (notifications && notifications.length > 0) {
    return (
      <div className="fixed-notification">
        {notifications.map((notification, index) => (
          <FixedNotification
            haveSpacingAfter={notifications.length > 1}
            index={index}
            key={`${index}-${notification.msg}`}
            notification={notification}
            onClose={closeNotification}
          />
        ))}
      </div>
    );
  }
  return null;
};

export default FixedNotifications;
