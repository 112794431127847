import React, { ReactElement, ComponentType } from 'react';

type WithScrollTopProps = {
  onClick?: (event: React.MouseEvent) => void;
};

const withScrollTop = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const handleClickEvent = (event: React.MouseEvent): void => {
    if (!(event.metaKey || event.ctrlKey) && window.scrollTo) {
      window.scrollTo(0, 0);
    }
  };

  const WithScrollTop = (props: P & WithScrollTopProps): ReactElement => (
    <WrappedComponent {...props} onClick={handleClickEvent} />
  );

  return WithScrollTop;
};

export default withScrollTop;
