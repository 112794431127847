import React, {createRef, forwardRef, HTMLAttributes, RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import Measure, {ContentRect} from 'react-measure';
import SwiperCore from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import classNames from 'classnames';
import first from 'lodash/first';
import last from 'lodash/last';

import {OptionType} from '../NativeSelect';
import {setTransition} from './helpers';

import './index.scss';

type SwipeableNavProps = {
  options: OptionType[],
  activeSlideIdx: number,
  onSlideChange(i: number): void,
  progress?: number,
} & HTMLAttributes<HTMLDivElement>;

/**
 * Swipe-able clickable navbar:
 *
 * [  Tab 1  ] [  Tab 2  ] [  Tab 3  ]
 *    _____
 *
 * @param props
 * @returns
 */
const SwipeableNav = forwardRef<HTMLDivElement, SwipeableNavProps>((props, ref) => {
  const {activeSlideIdx, onSlideChange, options, progress, ...divAttr} = props;

  const activeSlide = useMemo(() => options[activeSlideIdx].value, [activeSlideIdx, options]);

  // Progress element’s left and translateX are modified directly by the ref for smooth transitions.
  const progressRef = ref as RefObject<HTMLDivElement> ?? createRef<HTMLDivElement>();

  // Swiper lib is used to achieve swipe-able sticky behavior of the navbar.
  const [navSwRef, setNavSwRef] = useState<SwiperClass>();

  // Calculate options bounds and apply proper styles to progress slider based on them.
  const tabsBounds: {[key in string]?: ContentRect} = useMemo(() => ({}), []);

  /**
   * We need to enable CSS-animation when Swiper does transition from some offset_1 to offset_2
   * to apply similar transition to the progress slider.
   * For regular cases `onSliderMove` will be called many times per second and we will smoothly
   * update progress slider position without enabling CSS-animation.
   */
  const setProgressTransition = useCallback((duration = SwiperCore.defaults.speed) => {
    if (progressRef.current) {
      setTransition(progressRef.current, duration);
    }
  }, [progressRef]);

  /**
   * Saves options (tabs) bounds which include offsets from the parent and from the viewport.
   */
  const onTabResize = useCallback((value: string, rect: ContentRect) => {
    tabsBounds[value] = rect;
  }, [tabsBounds]);

  /**
   * Set progress slider x-translate manually makes transition smooth.
   */
  const onSliderMove = useCallback((_swiper: SwiperClass, translate: number) => {
    if (progressRef.current) {
      progressRef.current.style.transform = `translateX(${translate}px)`;
    }
  }, [progressRef]);

  /**
   * Swiper is transitioning. Callbacks are going to be called in this order
   * - onSetTransition(swiper, 300)
   * - onSliderMove(swiper, [x]) - transition to [x]
   * - onSetTransition(swiper, 0)
   */
  const onSetTransition = useCallback((_swiper: SwiperClass, transition: number) => {
    setProgressTransition(transition);
  }, [setProgressTransition]);

  /**
   * Helper function that calculates progress slider left offset by considering
   * - the first element’s left offset from the main container (or screen),
   * - active element’s left offset,
   * - and content padding.
   *
   * |<-->[<-->Value 1    ]  [    Value 2    ]  [    Value 3    ]
   *  <------->_______
   */
  const getRelativeLeftOffset = useCallback((activeSlide: string) => {
    const firstLeft = tabsBounds[first(options)?.value!]?.bounds?.left ?? 0;
    const activeLeft = tabsBounds[activeSlide]?.bounds?.left ?? 0;
    const leftOffset = tabsBounds[activeSlide]?.offset?.left ?? 0;
    return activeLeft - firstLeft + leftOffset;
  }, [options, tabsBounds]);

  const getAbsoluteWidth = useCallback((activeSlide: string) => {
    const activeWidth = tabsBounds[activeSlide]?.bounds?.width ?? 0;
    const leftOffset = tabsBounds[activeSlide]?.offset?.left ?? 0;
    return activeWidth + leftOffset * 2;
  }, [tabsBounds]);

  useEffect(function resetSlidePosition() {
    if (options && progressRef.current) {
      navSwRef?.slideReset(SwiperCore.defaults.speed)
    }
  }, [options]);

  // By calling `SwiperRef.slideTo` we can transition to the activated slide.
  useEffect(function slideToActive() {
    navSwRef?.slideTo(activeSlideIdx, SwiperCore.defaults.speed);
  }, [activeSlideIdx, navSwRef, options]);

  // Whenever we click on a new active slide we move progress to underneath it.
  // [    Value 1    ]  [    Value 2    ]  [    Value 3    ]
  //      [     ]----------->_______
  useEffect(function animateProgress() {
    if (progress === undefined && progressRef.current && tabsBounds[activeSlide]?.bounds) {
      setProgressTransition(SwiperCore.defaults.speed);
      progressRef.current.style.left = `${getRelativeLeftOffset(activeSlide)}px`;
      progressRef.current.style.width = `${tabsBounds[activeSlide]?.bounds?.width ?? 0}px`;
    }
  }, [activeSlide, progressRef, setProgressTransition, tabsBounds]);

  // When we connect it with another Swiper, e.g. with SwipeableContent, we should be able
  // to arbitrary set progress value from outside when we move slides and animate progress slider.
  useEffect(function animateProgressFromOutside() {
    if (progress === undefined || !progressRef.current) {
      return;
    }
    const lastIdx = options.length - 1;
    const step = 100 / lastIdx;
    const slide = progress * 100 / step;
    const prevIdx = Math.floor(slide);
    const nextIdx = Math.ceil(slide);
    const prev = tabsBounds[options[prevIdx]?.value];
    const next = tabsBounds[options[nextIdx]?.value];
    const relative = slide - prevIdx;

    if (slide < 0) {
      // Move outside the left border
      const option = tabsBounds[first(options)?.value!];
      progressRef.current.style.left = `${option?.bounds?.width! * slide}px`;
    } else if (slide >= options.length) {
      // Move outside the right border far enough
      const option = tabsBounds[last(options)?.value!];
      progressRef.current.style.left =
        `${option?.bounds?.left! + getAbsoluteWidth(last(options)?.value!) * (slide - lastIdx)}px`;
    } else {
      // Move between slides and outside the right border
      progressRef.current.style.left =
        `${prev?.bounds?.left! + getAbsoluteWidth(options[prevIdx]?.value) * relative}px`;

      if (next) {
        // Set width when we are moving between slides
        progressRef.current.style.width =
            `${prev?.bounds?.width! + (next?.bounds?.width! - prev?.bounds?.width!) * relative}px`;
      }
    }
  }, [progress, progressRef]);

  return (
    <nav
      {...divAttr}
      className="SwipeableNav"
      data-value={activeSlide} // able to add additional styles based on the value
    >
      <Swiper
        onSwiper={setNavSwRef}
        className="SwipeableNav-tabs"
        onSetTranslate={onSliderMove}
        onSetTransition={onSetTransition}
        slidesPerView="auto">
        {options.map(({label, value}, idx) => (
          <SwiperSlide key={label}>
            <Measure offset bounds onResize={contentRect => onTabResize(value, contentRect)}>
              {({measureRef}) => (
                <button
                  className={classNames('SwipeableNav-tab', {'is-active': value === activeSlide})}
                  onClick={() => onSlideChange(idx)}
                >
                  <span ref={measureRef}>{label}</span>
                </button>
              )}
            </Measure>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="SwipeableNav-ruler" />
      <div className="SwipeableNav-progress" ref={progressRef} />
    </nav>
  );
});

export default SwipeableNav;
