import React, { HTMLAttributes, ReactNode, useMemo } from "react";
import classNames from 'classnames';

import { ListSelectIcon, ListSelectText } from "./components";

import './index.scss';

export interface ListSelectProps {
  className?: string,
  onClick: () => void,
  children: ReactNode,
}

/**
 * ListSelect component should allow Icon followed by text
 * with a click action to trigger any action when pressing
 */
const ListSelect = (props: ListSelectProps & HTMLAttributes<HTMLButtonElement>) => {
  const { className = '', onClick, children, ...buttonAttr } = props;

  const listSelectClasses = useMemo(() => classNames('ListSelect', { [className]: className }), [className]);

  return (
    <button {...buttonAttr} className={listSelectClasses} onClick={onClick}>
      {children}
    </button>
  )
}

ListSelect.Icon = ListSelectIcon;
ListSelect.Text = ListSelectText;

export default ListSelect;
