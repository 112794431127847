import React, {memo} from 'react';
import {ReactSVG} from 'react-svg';

import useRatingBadge from './useRatingBadge';

import './index.scss';

const RatingBadge = () => {
  const {ratingData} = useRatingBadge();
  return (
    <div className="rating-badge-wrapper">
      {ratingData.map((rating) => (
        <div key={rating.subtitle} className="rating-badge-content">
          {rating.icon && <ReactSVG src={rating.icon} className={`rating-badge-img rating-badge-img-${rating.name}`} />}
          <div className="rating-badge-info">
            <b>{rating.rating} rating</b>
            {rating?.reviewCount ? <p>{rating.reviewCount} reviews</p> : <p>Accredited</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(RatingBadge);
