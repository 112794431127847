import React from 'react';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import NavLinkContext from '../../../libs/navlink-context';

import './index.scss';

class DropdownMenu extends React.Component {
  static get propTypes() {
    return {
      alwaysOpen: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.node,
          onClick: PropTypes.func,
          href: PropTypes.string,
          special: PropTypes.bool,
          iconSVG: PropTypes.string,
        }),
      ),
      title: PropTypes.node,
    };
  }

  static defaultProps = {
    alwaysOpen: false,
    items: [
      {
        href: 'https://www.ergeon.com/fence-specifications',
        content: 'Fence Specifications',
      },
      {
        href: 'https://www.ergeon.com/gallery/fence/picture-frame/without-lattice',
        content: 'Fence Photos',
      },
      {
        href: 'https://www.ergeon.com/gallery/gate/picture-frame/single',
        content: 'Gate Photos',
      },
      {
        href: 'https://www.ergeon.com/gallery/driveway/stamped/casual',
        content: 'Driveway Photos',
      },
      {
        href: 'https://www.ergeon.com/about-ergeon',
        content: 'About',
      },
      {
        href: 'https://fencequoting.com/',
        content: 'Fence Calculator',
        special: true,
      },
    ],
    title: <span>About Ergeon</span>,
  };

  static contextType = NavLinkContext;

  constructor(...args) {
    super(...args);
    this.state = {
      showMenu: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeMenu);
  }

  showMenu(event) {
    event.stopPropagation();
    event.preventDefault();
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    if (!this.dropdownMenu || !this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener('click', this.closeMenu);
      });
    }
  }

  handleMenuItemClick(onClick) {
    this.setState({ showMenu: false });
    onClick && onClick();
  }

  renderMenuItem({ content, href, iconSVG, special, onClick }, index) {
    const className = classNames('menu-item', { special });
    const NavLink = this.context;

    let ElementComponent = 'button';

    if (href) {
      ElementComponent = NavLink;
    }

    return (
      <ElementComponent
        activeClassName="active-link"
        className={className}
        key={index}
        onClick={this.handleMenuItemClick.bind(this, onClick)}
        to={href}>
        <div className="menu-title">
          <ReactSVG src={iconSVG} />
          <span>{content}</span>
        </div>
      </ElementComponent>
    );
  }

  render() {
    const { title, items, alwaysOpen } = this.props;
    const { showMenu } = this.state;
    return (
      <span className="dropdown-menu">
        <a className="link" href="" onClick={this.showMenu}>
          <div className="flex-align-middle">
            {title}
            <i
              className={classNames('dropdown-menu--icon', {
                'rotate-menu-icon':showMenu,
              })}
            />
          </div>
        </a>
        {showMenu || alwaysOpen ? (
          <div
            className="menu"
            ref={element => {
              this.dropdownMenu = element;
            }}>
            {items.map(this.renderMenuItem.bind(this))}
          </div>
        ) : null}
      </span>
    );
  }
}

export default DropdownMenu;
