import React, { HTMLAttributes } from 'react';
import kebabCase from 'lodash/kebabCase';

import './index.scss';

type DataRowProps = {
  defaultValue?: string | number | JSX.Element,
  title: string | JSX.Element,
  value?: string | number | JSX.Element,
} & HTMLAttributes<HTMLDivElement>;

const DataRow = (props:DataRowProps) => {
  const {title, value, defaultValue, ...divAttr} = props;
  return (
    <div {...divAttr} className="data-row">
      <div className="data-row-title">{title}</div>
      <div className="data-row-value" data-qa={`${kebabCase(title)}-value`}>{value || defaultValue}</div>
    </div>
  );
};

export default DataRow;
