import { Loader } from '@googlemaps/js-api-loader';
import union from 'lodash/union';

/**
 * Encapsulates all the logic needed for loading and using the Google maps.
 */
export class GoogleMapLoader {
  static version: Loader['version'] = 'weekly';
  static language: Loader['language'] = 'en';
  static libraries: Loader['libraries'] = ['places']

  private loader?: Loader;

  private createLoader(apiKey: Loader['apiKey'], ...libraries: Loader['libraries']) {
    return new Loader({
      apiKey,
      libraries,
      version: GoogleMapLoader.version,
      language: GoogleMapLoader.language,
    });
  }

  initGoogleLoader(apiKey: Loader['apiKey'], ...libraries: Loader['libraries']){
    this.loader = this.createLoader(apiKey, ...libraries);
    return this.loader;
  }

  getGoogleLoader(apiKey?: Loader['apiKey'], ...libraries: Loader['libraries']){
    if (this.loader) return this.loader;
    if (apiKey) return this.initGoogleLoader(apiKey, ...libraries);
    throw new Error('Google Loader is not initialized yet. Call `initGoogleLoader` first.');
  }
}

const MapLoaderSingleton = new GoogleMapLoader();

// Exports
export const ADDRESS_INPUT_LIBRARIES = GoogleMapLoader.libraries;

export const initGoogleLoader = (apiKey: Loader['apiKey'], ...libraries: Loader['libraries'][]) => {
  const uniqueLibraries = union(...libraries);
  return MapLoaderSingleton.initGoogleLoader(apiKey, ...uniqueLibraries)
}

export const getGoogleLoader = () => MapLoaderSingleton.getGoogleLoader();

export default {
  ADDRESS_INPUT_LIBRARIES,
  initGoogleLoader,
  getGoogleLoader,
};
