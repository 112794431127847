import classNames from 'classnames';
import Button, {ButtonProps} from 'components/atoms/Button/Button';
import {useIsMobile} from 'components/utils/useResponsiveBreakpoint';
import React, {ReactNode, useCallback, useMemo} from 'react';

import './index.scss';
import {ButtonFlavor} from 'components/atoms/Button/types';

type CTAProps = {
  text: string;
} & Omit<ButtonProps, 'children'>;

export type SimpleNotificationProps = {
  type?: 'Warning' | 'Information' | 'Success' | 'Cancelled';
  icon: string | ReactNode;
  title?: string;
  subtitle: string;
  primaryButton?: CTAProps;
  secondaryButton?: CTAProps;
  className?: string;
  compactMode?: boolean;
  isFullWidth?: boolean;
  extraDescription?: string;
};

const SimpleNotification: React.FC<SimpleNotificationProps> = ({
  type = 'Success',
  icon,
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  className,
  compactMode = false,
  extraDescription,
}) => {
  const isMobile = useIsMobile();

  const wrapperClassNames = useMemo(
    () =>
      classNames('simple-notification-wrapper', {
        warning: type === 'Warning',
        success: type === 'Success',
        info: type === 'Information',
        cancelled: type === 'Cancelled',
        [className || '']: className,
      }),
    [className, type],
  );

  const compactModeClassNames = useMemo(
    () =>
      classNames({
        'size-small': compactMode,
        'size-medium': !compactMode,
      }),
    [compactMode],
  );

  const btnFlavor = {
    Warning: 'action',
    Information: 'primary',
    Success: 'cta',
    Cancelled: 'regular',
  }[type] as unknown as ButtonFlavor;

  const renderIcon = useCallback(() => {
    if (typeof icon === 'string') {
      return <img src={icon} alt="status icon" />;
    }
    return icon;
  }, [icon]);

  return (
    <div className={wrapperClassNames}>
      <div className="simple-notification-icon-container">{renderIcon()}</div>
      <div className="simple-notification-content">
        <div className="simple-notification-info">
          <div className="simple-notification-text-container">
            {title && <h1 className="simple-notification-title">{title}</h1>}
            <p className={`simple-notification-subtitle ${compactModeClassNames}`}>
              {subtitle}
              {extraDescription && (
                <>
                  <br className="break-line" /> {extraDescription}
                </>
              )}
            </p>
          </div>
        </div>
        {(primaryButton || secondaryButton) && (
          <div className="simple-notification-actions">
            {primaryButton && (
              <Button
                {...primaryButton}
                flavor={btnFlavor}
                onClick={primaryButton.onClick}
                size={isMobile ? 'medium' : 'large'}
                isFullWidth={isMobile}
              >
                {primaryButton.text}
              </Button>
            )}
            {secondaryButton && (
              <Button
                {...secondaryButton}
                flavor="regular"
                taste="line"
                className={`button-overrides ${secondaryButton?.className}`}
                onClick={secondaryButton.onClick}
                size={isMobile ? 'medium' : 'large'}
                isFullWidth={isMobile}
              >
                {secondaryButton.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleNotification;
