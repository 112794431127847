import React, { useMemo } from 'react';
import classNames from 'classnames';
import ErgeonLink from '../../../atoms/ErgeonLink';
import { getDocument } from 'ssr-window';

export type TopPanelProps = {
  name: string,
  address: string,
  className?: string,
}

type ClassesType = {
  link: boolean,
  current: boolean,
  [key: string]: boolean,
}

const TopPanelLink = ({ name, address, className }: TopPanelProps) => {
  const currentPage = useMemo(() => {
    const document = getDocument();
    return document.location.origin + document.location.pathname
  }, []);

  const classes = useMemo(() => {
    const classesObj: ClassesType = {
      'link': true,
      'current': currentPage === address,
    };
    if (className) {
      classesObj[className] = true;
    }
    return classNames(classesObj);
  }, [currentPage, address, className]);

  return (
    <ErgeonLink href={address}>
      <li className={classes}>
        {name}
      </li>
    </ErgeonLink>
  );
}

export default TopPanelLink;
