import React, { HTMLAttributes, ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import Button from '../../../atoms/Button';

import { DEFAULT_TOP_PANEL_PROPS as DEFAULTS } from '../constants';
import TopPanelLink from '../TopPanel/TopPanelLink';
import TopPanelLogo from '../TopPanel/TopPanelLogo';
import TopPanelRightSide from '../TopPanel/TopPanelRightSide';
import PhoneLink, { CallUsPhoneLink } from '../PhoneLink';
import useMobileMenu from '../useMobileMenu';

import './SimpleTopPanel.scss';
import './SimpleProTopPanel.scss';

export interface SimpleTopPanelInterface extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  customerMenu?: ReactNode;
  ergeonUrl?: string;
  fencequotingUrl?: string;
  phoneNumber?: string;
  pdfDetails?: ReactNode;
  pdfMode?: boolean;
  projectsName?: string;
  showChristmasHat?: boolean;
  showNavigation?: boolean;
  onCTAClick?: () => void;
  showDisplayPricing?: boolean;
  pricingLabel?: string;
  UTMquery?: string;
  widthClass?: string;
  isProPage?: boolean;
  languageToggle?: ReactNode;
}

/**
 * This is a main Top Panel component for all Ergeon websites
 * It represent a basic TopPanel layout with several possible states.
 * There are several possible places for displaying custom content: customerMenu, pdfDetails or children.
 *
 * All "children" will be rendered in the NAVIGATION group.
 * "customerMenu" will be rendered in the end of the Top Panel
 * "pdfDetails" will be rendered if pdfMode is activated and it also will hide all other content from the
 * right side of the panel.
 *
 * Desktop layout:
 *  ----------------------------------------------------------------------
 * [ [LOGO]  {right-side: [GET_A_QUOTE?] [NAVIGATION?] [PDF_DETAILS?]} ]
 * ----------------------------------------------------------------------
 *
 * Mobile view has "Burger" icon for displaying NAVIGATION menu and CTA button.
 *
 * Mobile Layout:
 * --------------------------------------------------
 * [ [BURGER] [LOGO] {right-side: [GET_A_QUOTE?]} ]
 * --------------------------------------------------
 *
 * All props are optional and have some default values.
 * @param props: NiceTopPanelInterface
 * @constructor
 */
const SimpleTopPanel = (props: SimpleTopPanelInterface) => {
  const {
    ergeonUrl = DEFAULTS.ergeonUrl,
    fencequotingUrl = DEFAULTS.fencequotingUrl,
    pdfMode = DEFAULTS.pdfMode,
    projectsName = DEFAULTS.projectsName,
    UTMquery = DEFAULTS.UTMquery,
    widthClass = DEFAULTS.widthClass,
    showNavigation = DEFAULTS.showNavigation,
    phoneNumber = DEFAULTS.phoneNumber,
    customerMenu,
    showChristmasHat,
    pdfDetails,
    onCTAClick,
    showDisplayPricing = true,
    pricingLabel = 'Pricing',
    isProPage,
    languageToggle,
    ...divProps
  } = props;

  const { isMobileMenuVisible, onShowMobileMenu } = useMobileMenu();

  const topPanelClasses = useMemo(() => {
    return classNames('SimpleTopPanel top-panel', {
      SimpleProTopPanel: !!isProPage,
    })
  }, [isProPage])

  const topPanelContentClasses = useMemo(
    () =>
      classNames("SimpleTopPanel-Content top-panel__content", {
        [widthClass]: true,
      }),
    [widthClass]
  );

  const burgerBtnClasses = useMemo(
    () =>
      classNames('burger', {
        'is-Show show': isMobileMenuVisible,
        'is-Hide hide': !isMobileMenuVisible,
      }),
    [isMobileMenuVisible]
  );

  const navigationClasses = useMemo(
    () =>
      classNames('Navigation navigation', {
        'is-Show show': isMobileMenuVisible,
        'is-Hide hide': !isMobileMenuVisible,
      }),
    [isMobileMenuVisible]
  );

  const defaultUlList = useMemo(
    () => (
      <>
        {showDisplayPricing && !isProPage &&
          <TopPanelLink
            name={pricingLabel}
            address={`${fencequotingUrl}${UTMquery}`}
          />
        }
        <CallUsPhoneLink className="mobile" />
        {customerMenu}
        {onCTAClick &&
          <div className="QuoteButton-Wrapper mobile-length">
            <Button
              flavor="action"
              className="QuoteButton"
              onClick={onCTAClick}
            >
              {isProPage ? 'Join our Team' : 'Get a Quote'}
            </Button>
          </div>
        }
      </>
    ),
    [UTMquery, fencequotingUrl, customerMenu, onCTAClick, showDisplayPricing, isProPage]
  );

  const navigation = useMemo(() => {
    return (
      <div className={navigationClasses}>
        <ul>
          {defaultUlList}
          {props.children}
        </ul>
      </div>
    );
  }, [navigationClasses, defaultUlList, props.children]);

  const rightSide = useMemo(() => {
    if (pdfMode) {
      return <TopPanelRightSide>{pdfDetails}</TopPanelRightSide>;
    } else {
      return (
        <TopPanelRightSide>
          <PhoneLink phoneNumber={phoneNumber} />
          {showNavigation && navigation}
          {onCTAClick &&
            <Button
              flavor="action"
              className="QuoteButton desktop-length"
              onClick={onCTAClick}
            >
              Get a Quote
            </Button>
          }
        </TopPanelRightSide>
      );
    }
  }, [pdfMode, pdfDetails, showNavigation, navigation, phoneNumber, onCTAClick]);

  return (
    <div {...divProps} className={topPanelClasses}>
      <div className={topPanelContentClasses}>
        <div className={burgerBtnClasses} onClick={onShowMobileMenu} />
        <TopPanelLogo ergeonHomePage={ergeonUrl} showChristmasHat={showChristmasHat} />
        {rightSide}
        {isProPage && languageToggle && <div className="language-toggle">{languageToggle}</div>}
      </div>
    </div>
  );
};

export default SimpleTopPanel;
