import React from 'react';
import Skeleton from './Skeleton';
import Placeholder, {TextualPlaceholderProps} from './Placeholder';

/**
 * @summary SummaryPlaceholder usage
 * SummaryPlaceholder is meant to be use as part of the Quotation Summary
 *
 * @param {Object} props - Component Props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {number} props.rows - Number of lines for main summary part
 */
const SummaryPlaceholder = (props: TextualPlaceholderProps) => {
  const {rows} = props;
  return (
    <>
      <Skeleton count={rows} />
      <br />
      <br />
      <Skeleton count={1} width={200} />
      <br />
      <Skeleton count={1} width={150} />
    </>
  );
};

export default Placeholder(SummaryPlaceholder);
