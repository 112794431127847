import {useCallback, useEffect, useMemo} from 'react';

import {isMacDevice} from './utils';

interface UseKeyboard {
  alt?: boolean;
  ctrl?: boolean;
  key: string;
  noRepeat?: boolean;
  onKeyDown?: () => void;
  onKeyUp?: () => void;
  ref?: React.RefObject<HTMLElement> | null;
  shift?: boolean;
}

const useKeyboard = ({
  alt = false,
  ctrl = false,
  key,
  noRepeat = false,
  onKeyDown,
  onKeyUp,
  ref,
  shift = false,
}: UseKeyboard) => {
  if (!onKeyDown && !onKeyUp) {
    throw new Error('useKeyboard requires at least one key event handler');
  }

  const checkAlt = useCallback((e: KeyboardEvent) => alt === e.altKey, [alt]);
  const checkCtrl = useCallback(
    (e: KeyboardEvent) => {
      if (isMacDevice()) {
        return ctrl === e.metaKey || ctrl === e.ctrlKey;
      }
      return ctrl === e.ctrlKey;
    },
    [ctrl],
  );
  const checkShift = useCallback((e: KeyboardEvent) => shift === e.shiftKey, [shift]);

  const checkRepeat = useCallback((e: KeyboardEvent) => !noRepeat || !e.repeat, [noRepeat]);
  const checkKey = useCallback((e: KeyboardEvent) => e.code === key, [key]);
  const checkTarget = useCallback((e: KeyboardEvent) => {
    const target = e.target as HTMLElement;
    return typeof target.matches === 'function' ? !target.matches('input[type="text"], input[type="number"]') : true;
  }, []);

  const checkModifiers = useCallback(
    (e: KeyboardEvent) => checkAlt(e) && checkCtrl(e) && checkShift(e),
    [checkAlt, checkCtrl, checkShift],
  );

  const checkKeyboard = useCallback(
    (e: KeyboardEvent) => checkModifiers(e) && checkKey(e) && checkTarget(e),
    [checkModifiers, checkKey, checkTarget],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (checkKeyboard(e) && checkRepeat(e) && onKeyDown) {
        e.preventDefault();
        onKeyDown();
      }
    },
    [checkKeyboard, checkRepeat, onKeyDown],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (checkKeyboard(e) && onKeyUp) {
        e.preventDefault();
        onKeyUp();
      }
    },
    [checkKeyboard, onKeyUp],
  );

  const element = useMemo(() => ref?.current ?? window, [ref?.current]) as HTMLElement;

  useEffect(() => {
    element.addEventListener('keydown', handleKeyDown);
    element.addEventListener('keyup', handleKeyUp);

    return () => {
      element.removeEventListener('keydown', handleKeyDown);
      element.removeEventListener('keyup', handleKeyUp);
    };
  }, [element, handleKeyDown, handleKeyUp]);
};

export default useKeyboard;
