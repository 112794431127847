import React from 'react';

import { ImageGalleryImage } from '.';
import Thumbnail from './ImageGalleryThumbnail';

export interface ThumbnailsProps {
  images: ImageGalleryImage[]
  maxHeight: string | number,
  maxThumbnails: number,
  onClick: (event: MouseEvent, i: number) => void
}

/**
 *  Displays thumbnails by iterating the images array, we have a max defined to stop there
 */
const Thumbnails = (props: ThumbnailsProps) => {
  const { images, onClick, maxThumbnails, maxHeight } = props;
  const maxThumbnailsArray = images.slice(0, maxThumbnails);
  return (
    <>
      {maxThumbnailsArray.map((elem, i) => {
        return <Thumbnail
          key={`thumbnail-${i}`}
          title={elem.title}
          url={elem.url}
          maxHeight={maxHeight}
          onClick={(event: MouseEvent) => onClick(event, i)}
        />
      })}
    </>
  )
};

export default Thumbnails;
