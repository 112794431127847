import React from 'react';

import ErgeonLink from '../../atoms/ErgeonLink';
import usePhoneFormatter from '../../utils/usePhoneFormatter';

import {PhoneIcon} from '@ergeon/icons/hero/micro';
import {MapPinIcon} from '@ergeon/icons/hero/micro';
import {CheckBadgeIcon} from '@ergeon/icons/hero/micro';

import type {SimpleFooterProps} from './types';

type DetailsFooter = Pick<SimpleFooterProps, 'phoneNumber' | 'address' | 'licenses'>;

const DetailsFooter = ({address, licenses, phoneNumber}: DetailsFooter) => {
  const phoneFormatter = usePhoneFormatter(phoneNumber);

  return (
    <div className="SimpleFooter-Column">
      {phoneNumber && (
        <div className="IconWithText">
          <ErgeonLink className="flex-direction-row align-center is-withoutOffset link" href={phoneFormatter.link}>
            <PhoneIcon />
            <b>{phoneFormatter.display}</b>
          </ErgeonLink>
        </div>
      )}
      {address && (
        <div className="IconWithText">
          <MapPinIcon />
          <address className="display-flex flex-wrap flex-spacer">
            {address.split(',').map((addressPart, idx, {length}) => (
              <span className="nowrap-text" key={idx}>
                <span>{addressPart}</span>
                {idx !== length - 1 && <>,&nbsp;</>}
              </span>
            ))}
          </address>
        </div>
      )}
      {address && (
        <p className="fence-deliveryNotice">
          <i>&#42;Delivery times may vary due to demand spike</i>
        </p>
      )}
      <div className="is-Licenses">
        {licenses?.length &&
          licenses.map((license, index) => (
            <div key={`license-${index}`} className="IconWithText LicenseWrapper">
              <CheckBadgeIcon />
              {license?.url ? (
                <div>
                  {license.label && <span className="LicenseLabel">{`${license.label}:`}</span>}
                  <ErgeonLink className="LicenseWithLink" href={license.url}>
                    {license.name}
                  </ErgeonLink>
                </div>
              ) : (
                <div>
                  {license.label && <span className="LicenseLabel">{`${license.label}:`}</span>}
                  <span className="LicenseWithLink">{license.name}</span>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default DetailsFooter;
