import React, { HTMLAttributes } from 'react';

import SimpleFooter from './SimpleFooter';

import { TypeOfFooter } from './constants';
import { FooterInterface } from './types';

const Footer = (props: FooterInterface & HTMLAttributes<HTMLDivElement>) => {

  const typeOfFooter = TypeOfFooter.Default;

  return <SimpleFooter {...props} isLocationFooter={false} withSocialLogo={false} withTreeLogo={false} typeOfFooter={typeOfFooter} />

};

export default Footer;
