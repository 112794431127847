import React, { HTMLAttributes } from 'react';
import Skeleton from './Skeleton';
import Placeholder, { TextualPlaceholderProps } from './Placeholder';

/**
 * @summary TextPlaceholder usage
 * TextPlaceholder is meant to be use for text elements, single or multi line
 * use props.rows to set how many lines should be rendered
 *
 * @param {Object} props - Component Props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {number} props.rows - Number of lines to show
 */
const TextPlaceholder = ( props: TextualPlaceholderProps & HTMLAttributes<HTMLDivElement>) => {
  const {rows, ...divAttr} = props;
  return <Skeleton {...divAttr} count={props.rows} />;
};
export default Placeholder(TextPlaceholder);
