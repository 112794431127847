import React, { Fragment, useCallback, useState, useEffect, useMemo } from 'react';
import { NativeSelectInterface } from './index';

export interface MeasuredSpanInterface {
  label: string;
  onLayout: (width: number) => void;
  widthAddition?: number;
}

/**
 * This component is used to measure the width of text inside a span.
 */
function MeasuredSpan({ label, onLayout, widthAddition = 35 }: MeasuredSpanInterface) {
  const measuredRef = useCallback(node => {
    if (!node) return;
    onLayout(node.getBoundingClientRect().width + widthAddition);
  }, []);
  return <span className="Measure-Span" ref={measuredRef}>{label}</span>;
}

/**
 * HOC to hold our options measurements logic
 * @param props {NativeSelectInterface}
 */
const withOptionsWidths = (NativeSelectComponent: (props: any) => JSX.Element) => (props: NativeSelectInterface) => {
  const { options } = props;
  const [optionsWidths, setOptionsWidths] = useState(options);
  const [isCalculating, setIsCalculating] = useState(true);

  // re-render when options props changes, so we can recalculate our widths with the new options
  useEffect(function componentDidUpdate() {
    return () => setIsCalculating(true);
  }, [options]);

  // calculates each of our "invisible spans" so we can correctly map their total width
  const onMeasuredSpanLayout = useCallback((width: number, forValue: string) => {
    const indexToUpdate = options.findIndex((option) => option.value === forValue);
    const updatedOptions = [...options];
    updatedOptions[indexToUpdate].width = width;
    setOptionsWidths(updatedOptions);
    setIsCalculating(false);
  }, [options, setOptionsWidths]);

  const calcOptions = useMemo(() => (
    options.map((elem, i) =>
      <Fragment key={`${i}-${elem.value}`}>
        <MeasuredSpan label={elem.label} onLayout={width => onMeasuredSpanLayout(width, elem.value)} />
        <br></br> {/* we need to add <br> so we can correctly calculate the span width */}
      </Fragment>
    )
  ), [options]);

  return (
    <>
      {isCalculating && calcOptions}
      <NativeSelectComponent {...props} options={optionsWidths} />
    </>
  )
}

export default withOptionsWidths;
