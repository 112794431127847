import React, { HTMLAttributes, ReactNode } from 'react';

import '../index.scss';

interface EventCardSideProps extends HTMLAttributes<HTMLButtonElement | HTMLSpanElement>{
  placement: 'content' | 'rightASide' | 'leftASide',
  children: ReactNode,
  onClick?: () => void,
}

const EventCardSide = ({ placement, children, onClick, ...ElementAttr }: EventCardSideProps) => {
  if (onClick) {
    return (
      <button {...ElementAttr} className={`EventCard-${placement}`} onClick={onClick}>
        {children}
      </button>
    )
  }
  return (
    <span {...ElementAttr} className={`EventCard-${placement}`}>
      {children}
    </span>
  )
};

export default EventCardSide;
