import {CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon} from '@ergeon/icons/hero/solid';
import React from 'react';
import {NotificationType} from './types';

const NotificationIcon = ({type}: {type: NotificationType}) => {
  const iconClasses = "size-5";
  switch (type) {
    case 'Error':
      return <XCircleIcon data-testid={`icon-${type.toLowerCase()}`} className={`${iconClasses} text-red-500`} />;
    case 'Warning':
      return <ExclamationTriangleIcon data-testid={`icon-${type.toLowerCase()}`} className={`${iconClasses} text-orange-500`} />;
    case 'Success':
      return <CheckCircleIcon data-testid={`icon-${type.toLowerCase()}`} className={`${iconClasses} text-green-500`} />;
    case 'Information':
      return <InformationCircleIcon data-testid={`icon-${type.toLowerCase()}`} className={`${iconClasses} text-blue-500`} />;
    default:
      return <CheckCircleIcon data-testid={`icon-success`} className={`${iconClasses} text-green-500`} />;
  }
};

export default NotificationIcon;
