import React, {memo, useMemo} from 'react';

import {ReactSVG} from 'react-svg';
import ErgeonLink from '../../atoms/ErgeonLink';

import iconFacebook from '@ergeon/icons/svg/icon-facebook-black.svg';
import iconMedium from '@ergeon/icons/svg/icon-medium-black.svg';
import iconInstagram from '@ergeon/icons/svg/icon-insta-black.svg';
import classNames from 'classnames';

type SocialFooterProps = {
  hasMarginTop?: boolean;
};

const SocialFooter = ({hasMarginTop = true}: SocialFooterProps) => {
  const wrapperClassName = useMemo(() => {
    return classNames('SimpleFooter-Social', {
      'with-margin-top': hasMarginTop,
    });
  }, [hasMarginTop]);

  return (
    <nav className={wrapperClassName}>
      <ErgeonLink className="Icon" href="https://www.facebook.com/ergeon.inc" rel="noopener noreferrer" target="_blank">
        <ReactSVG src={iconFacebook} />
      </ErgeonLink>
      <ErgeonLink
        className="Icon"
        href="https://www.instagram.com/ergeon.inc"
        rel="noopener noreferrer"
        target="_blank"
      >
        <ReactSVG src={iconInstagram} />
      </ErgeonLink>
      <ErgeonLink className="Icon" href="https://medium.com/ergeon" rel="noopener noreferrer" target="_blank">
        <ReactSVG src={iconMedium} />
      </ErgeonLink>
    </nav>
  );
};

export default memo(SocialFooter);
