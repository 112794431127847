import React, {createContext, HTMLAttributes, useContext, useEffect, useMemo} from 'react';
import classNames from "classnames";
import {Portal} from 'react-portal';
import {useSpring, animated} from '@react-spring/web';

import './index.scss';

type AnimatedMenuDropdownContext = {
  onVisible(isVisible: boolean): void,
};

export const AnimatedMenuDropdownContext = createContext<AnimatedMenuDropdownContext>(null!);

type AnimatedMenuDropdownProps = {
  children: React.ReactNode,
  isVisible: boolean,
  containerClassName: string,
} & HTMLAttributes<HTMLDivElement>;

/**
 * This component triggers drop-down animation of the children passed to it from 0 height to 100%.
 *
 * @param props
 * @returns animated menu ReactNode
 */
const AnimatedMenuDropdownMobile = ({children, isVisible, containerClassName, ...divAttr}: AnimatedMenuDropdownProps) => {
  const context = useContext(AnimatedMenuDropdownContext);

  const [animatedProps] = useSpring({
    to: {height: isVisible ? '100vh' : '0vh'},
  }, [isVisible]);

  const dropdownClassName = useMemo(() => {
    return classNames(containerClassName, "AnimatedMenuDropDown");
  }, [containerClassName]);

  useEffect(() => {
    context?.onVisible(isVisible);
  }, [isVisible]);

  return (
    <Portal>
      <animated.div {...divAttr} className={dropdownClassName} style={animatedProps}>
        {children}
      </animated.div>
    </Portal>
  );
};

export default AnimatedMenuDropdownMobile;
