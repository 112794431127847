import React, { HTMLAttributes, useMemo } from "react";
import SurveyNumberItems from "./SurveyNumberItems";
import { getRangeOfNumbers } from "./utils";

import './index.scss';

type SurveyNumbersRangeProps = {
  onClick(value: number, title: string): void;
  min: number;
  max: number;
  intervals?: number; // numbers interval e.g by 2 => 1, 3, 5 ...
  title: string;
  value: number;
};

const SurveyNumbersRange = (props: SurveyNumbersRangeProps & HTMLAttributes<HTMLDivElement>) => {
  const { min, max, intervals, onClick, title, value, ...divAttr } = props;

  const range = useMemo(() => {
    return getRangeOfNumbers(min, max, intervals);
  }, [min, max, intervals]);

  return (
    <div { ...divAttr} className="SurveyNumbersRange">
      {range.map((number, indx) => (
        <SurveyNumberItems
          key={`${Date.now()}-${indx}`}
          position={number}
          onClick={onClick}
          title={title}
          value={value}
        />
      ))}
    </div>
  );
};

export default SurveyNumbersRange;
