import React, {useMemo} from 'react';

import classNames from 'classnames';
import ReactQRCode, {QRCodeProps as ReactQRCodeProps} from 'react-qr-code';

export interface QRCodeProps extends Omit<ReactQRCodeProps, 'ref'> {
  value: string;
  className?: string;
}

const QRCode = (props: QRCodeProps) => {
  const {value, className, ...qrCodeProps} = props;

  const containerClassName = useMemo(() => classNames(className), [className]);

  return (
    <div className={containerClassName}>
      <ReactQRCode {...qrCodeProps} value={value} />
    </div>
  );
};

export default QRCode;
