import React, { HTMLAttributes } from 'react';
import Skeleton from './Skeleton';
import Placeholder, {ComplexPlaceholderProps} from './Placeholder';

/**
 * @summary MediaPlaceholder usage
 * MediaPlaceholder is meant to be used for mixed content preferably
 * image + text
 *
 * @param {Object} props - Component Props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {number} props.rows - Number of lines to show
 */
const MediaPlaceholder = (props: ComplexPlaceholderProps & HTMLAttributes<HTMLDivElement>) => {
  const {width = 80, height = 80, rows = 2, ...divAttr} = props;
  return (
    <div {...divAttr} className="skeleton-media">
      <div className="skeleton-media__image">
        <Skeleton width={width} height={height} />
      </div>
      <div className="skeleton-media__text">
        <Skeleton count={rows} />
        <Skeleton count={1} width={120} />
      </div>
    </div>
  );
};

export default Placeholder(MediaPlaceholder);
