import React from 'react';
import Skeleton from './Skeleton';
import Placeholder, {ComplexPlaceholderProps} from './Placeholder';

/**
 * @summary CardPlaceholder usage
 * CardPlaceholder is meant to be use with cards
 *
 * @param {Object} props - Component Props
 * @param {boolean} props.active - Show/Hide placeholder
 * @param {number} props.height - Image height value
 * @param {number} props.rows - Number of lines to show
 * @param {number} props.width - Image width value
 */
const CardPlaceholder = (props: ComplexPlaceholderProps) => {
  const {height, width = '100%', rows} = props;
  return (
    <>
      <Skeleton height={height} width={width} />
      <br />
      <br />
      <Skeleton count={rows} />
    </>
  );
};

export default Placeholder(CardPlaceholder);
