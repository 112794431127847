import React, { ReactNode } from 'react';
import TopPanelSide from './TopPanelSide';

import '../index.scss';

const TopPanelRight = ({ children }: { children?: ReactNode }) => {
  return (
    <TopPanelSide placement="right">
      {children}
    </TopPanelSide>
  )
};

export { TopPanelRight };
