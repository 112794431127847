import React, { HTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';

import './index.scss';

type FormFieldProps = {
  children: ReactNode,
  className?: string,
  labelClassName?: string,
  errorClassName?: string,
  error?: string,
  showError?: boolean,
  label?: string,
  name?: string,
} & HTMLAttributes<HTMLDivElement>;

const FormField = (props: FormFieldProps) => {
  const {
    children,
    className,
    labelClassName,
    errorClassName,
    error,
    label,
    name,
    showError = true,
    ...divAttr
  } = props;

  return (
    <div { ...divAttr } className={classNames('erg-form-field', className)}>
      {!!label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}
      {children}
      {showError && !!error && (
        <p className={errorClassName}>{error}</p>
      )}
    </div>
  );
};

export default FormField;
