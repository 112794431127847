import React, {useMemo} from 'react';
import {ReactImageGalleryItem} from 'react-image-gallery';
import {ReactSVG} from 'react-svg';
import classNames from 'classnames';
import times from 'lodash/times';

import IconArrowLeft from '@ergeon/icons/svg/icon-arrow-left.svg';
import IconArrowRight from '@ergeon/icons/svg/icon-arrow-right.svg';
import IconClose from '@ergeon/icons/svg/icon-cross-gray.svg';
import IconShare from '@ergeon/icons/svg/icon-open-in-new-tab.svg';

import WebSharePopup, {useWebSharePopupState} from '../../atoms/WebSharePopUp';

import './ImageGalleryControls.scss';

/**
 * Shows bullets navigation: °°°°°.
 */
const Bullets = ({idx, length, onClick}: {idx: number; length: number; onClick(idx: number): void}) => {
  return (
    <nav className="ImageGalleryBullets">
      {times(length, Number).map((i) => (
        <button
          key={i}
          type="button"
          className={classNames('ImageGalleryBullets-item', {'is-active': idx === i})}
          onClick={() => onClick(i)}
        />
      ))}
    </nav>
  );
};

/**
 * Displays close control: (x).
 */
const Close = ({onClick}: {onClick(): void}) => {
  return (
    <button className="ImageGalleryClose" onClick={onClick}>
      <ReactSVG className="ImageGalleryClose-icon" src={IconClose} />
    </button>
  );
};

type NavButtonKind = 'left' | 'right';

const NAV_ICON: Record<NavButtonKind, string> = {
  left: IconArrowLeft,
  right: IconArrowRight,
};

/**
 * Displays slides back/forth navigation button.
 */
const NavButton = ({kind, onClick}: {kind: NavButtonKind; onClick(): void}) => {
  const className = useMemo(() => {
    switch (kind) {
      case 'left':
      case 'right':
        return `ImageGalleryNav is-${kind}Nav`;
      default: {
        const neverKind: never = kind;
        throw new Error(`Cannot get class name for the kind "${neverKind}"`);
      }
    }
  }, [kind]);

  return (
    <button type="button" className={className} onClick={onClick}>
      <ReactSVG className="ImageGalleryNav-icon" src={NAV_ICON[kind]} />
    </button>
  );
};

/**
 * Displays 1 / 10.
 */
const Counter = ({idx, length}: {idx: number; length: number}) => {
  if (length === 0) return null;
  if (idx < 0 || idx > length) return null;
  return <p className="ImageGalleryCounter">{`${idx + 1}/${length}`}</p>;
};

/**
 * Displays share button and share popup when the button is clicked.
 */
const Share = ({image}: {image: ReactImageGalleryItem}) => {
  const webSharePopup = useWebSharePopupState();

  return (
    <>
      <button className="ImageGalleryShare" onClick={webSharePopup.show}>
        <ReactSVG className="ImageGalleryShare-icon" src={IconShare} />
      </button>
      <WebSharePopup
        title={image.description ?? ''}
        url={image.original}
        isOpen={webSharePopup.isVisible}
        onClose={webSharePopup.hide}
      />
    </>
  );
};

export default {
  Bullets,
  Close,
  Counter,
  NavButton,
  Share,
};
