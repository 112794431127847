import React, { useState, useCallback, useMemo, CSSProperties, HTMLAttributes } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { ReactImageGalleryItem } from 'react-image-gallery';

import Thumbnails from './ImageGalleryThumbnails';
import Thumbnail from './ImageGalleryThumbnail';
import ImageGalleryTitle from './ImageGalleryTitle';
import ImageGalleryPopup from './ImageGalleryPopup';
import NoImgsMessage from './NoImgsMessage';

import './index.scss';

export type ImageGalleryImage = {
  title: string,
  url: string,
  // provide custom renderer for item
  renderItem?(item: ReactImageGalleryItem): React.ReactNode;
};

type ImageGalleryProps = {
  isMaxHeight?: boolean;
  images: ImageGalleryImage[],
  initialSlide?: number,
  scrollToTop?: boolean,
  width?: number,
  height?: number,
  title?: string,
  titleIcon?: string,
  thumbnailNumDisplay?: number,
} & HTMLAttributes<HTMLDivElement>;

/**
 *  ImageGallery Component - Displays a popup gallery of images
 *  There's 2 view modes:
 *  - Single image
 *  - Thumbnail view:
 *      use "thumbnailNumDisplay" to set up the max number of thumbnails to display
 */
const ImageGallery = (props: ImageGalleryProps) => {
  const {
    isMaxHeight = false,
    images = [],
    initialSlide = 0,
    scrollToTop = false,
    width = 120,
    height = 120,
    title,
    titleIcon,
    thumbnailNumDisplay = 1,
    ...divAttr
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [clickedSlide, setClickedSlide] = useState<number>(initialSlide);

  const openGallery = useCallback((event: MouseEvent, imageIndex: number): void => {
    event.preventDefault();
    setOpen(true);
    setClickedSlide(imageIndex);
  }, []);

  const imagesArray = useMemo(() => (
    images.map(elem => ({
      description: elem.title,
      original: elem.url,
      renderItem: elem.renderItem,
    }))
  ), [images]);

  const styles = useMemo(() => ({
    width, height
  }), [width, height]) as CSSProperties;

  const maxHeight = useMemo(() => isMaxHeight ? '100%' : height, [isMaxHeight, height]);

  const isThumbnailsView = useMemo(() => thumbnailNumDisplay > 1, [thumbnailNumDisplay]);

  if (isEmpty(images)) {
    return <NoImgsMessage />;
  }

  return (
    <>
      <div {...divAttr} className={classNames('ImageGallery-container', {
        'has-Thumbnails': isThumbnailsView,
      })}
        style={!isThumbnailsView ? styles : undefined}>
        {isThumbnailsView
          ? <Thumbnails
            images={images}
            maxThumbnails={thumbnailNumDisplay}
            maxHeight={maxHeight}
            onClick={openGallery} />
          : <Thumbnail
            title={images[initialSlide].title}
            url={images[initialSlide].url}
            maxHeight={maxHeight}
            onClick={(e) => openGallery(e, initialSlide)} />
        }
        <ImageGalleryTitle title={title} titleIcon={titleIcon} images={images} />
      </div>
      <ImageGalleryPopup
        key={`popup-image-${clickedSlide}`}
        images={imagesArray}
        isVisible={open}
        onChangeVisible={setOpen}
        options={{
          initialSlide: clickedSlide,
          scrollToTop,
        }} />
    </>
  )
};

export default ImageGallery;
