import React from 'react';
import classNames from 'classnames';

import ErgeonLink from '../../atoms/ErgeonLink';
import { MenuItemsList } from './types';

type MenuFooterProps = {
  itemsList: MenuItemsList[];
};

const MenuFooter = ({ itemsList }: MenuFooterProps) => {
  if (!itemsList.length) {
    return null;
  }

  return (
    <div className="InfoFooter-Links">
      {itemsList.map((list) => {
        return (
          <nav className="SimpleFooter-Column" key={list.column}>
            <div className="label uppercase">{list.column}</div>
            {!!list.items?.length && (
              <ul>
                {list.items.map((item, idx) => {
                  return (
                    <li key={idx}>
                      {item.isHTML ? (
                        item.children
                      ) : (
                        <ErgeonLink
                          {...item}
                          className={classNames('link', item.className)}
                          href={item.href}
                        >
                          {item.children}
                        </ErgeonLink>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </nav>
        );
      })}
    </div>
  );
};

export default MenuFooter;
