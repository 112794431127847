import React from 'react';

import classNames from 'classnames';
import Notification from 'components/molecules/Notification';

import {getNotificationHeaderText} from './utils';
import {INotification} from './types';

interface IFixedNotification {
  index: number;
  notification: INotification;
  haveSpacingAfter: boolean;
  onClose: (index: number) => void;
}

const FixedNotification = ({index, notification, haveSpacingAfter, onClose}: IFixedNotification) => {
  const notificationClasses = classNames({
    'animation-move-out': notification.type === 'Warning' || notification.type === 'Information',
    spacing: haveSpacingAfter,
    'after__is-6': haveSpacingAfter,
  });
  return (
    <div className={notificationClasses}>
      <Notification {...notification} onClose={() => onClose(index)}>
        <div>
          <b>{getNotificationHeaderText(notification.type)}</b>
        </div>
        {notification.msg}
      </Notification>
    </div>
  );
};

export default FixedNotification;
