import React, {useMemo} from 'react';

import {DISCLAIMER} from './constants';

const Disclaimer = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  return (
    <div className="disclaimer">
      <p className="label">{DISCLAIMER}</p>
      <p className="label">&copy; {currentYear} Ergeon Inc.</p>
    </div>
  );
};

export default Disclaimer;
