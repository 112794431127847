import {useCallback} from 'react';

const useMailto = (email: string) => {
  const triggerMailto = useCallback(() => {
    const mailtoLink = `mailto:${email}`;
    // Create a temporary link element and trigger the click
    const tempLink = document.createElement('a');
    tempLink.href = mailtoLink;
    tempLink.click();
  }, [email]);

  return triggerMailto;
};

export default useMailto;
