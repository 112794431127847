import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import posed from 'react-pose';
import classNames from 'classnames';
import noop from 'lodash/noop';

import IconDropDown from '@ergeon/icons/svg/icon_drop_down.svg';

import './index.scss';

const AnimatedCollapsibleContent = posed.div({
  closed: {height: 0},
  open: {height: 'auto'},
});

export interface CollapsibleProps {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  onChangeOpen?(isOpen: boolean): void | Promise<void>;
}

const Collapsible = (props: CollapsibleProps & HTMLAttributes<HTMLDivElement>) => {
  const { children, title, isOpen, onChangeOpen = noop, ...divAttr } = props;
  const pose = useMemo(() => isOpen ? 'open' : 'closed', [isOpen]);
  const toggle = useCallback(() => onChangeOpen(!isOpen), [isOpen]);

  return (
    <section {...divAttr} className={classNames('Collapsible', {'is-open': isOpen})}>
      <button className="Collapsible-headerBtn" onClick={toggle}>
        <p className="h4">{title}</p>
        <img alt="DropDown icon" className="Collapsible-icon" src={IconDropDown} />
      </button>
      <AnimatedCollapsibleContent className="Collapsible-content" pose={pose}>
        {children}
      </AnimatedCollapsibleContent>
    </section>
  );
};

export default Collapsible;
