import React from 'react';

import usePhoneFormatter from '../../../utils/usePhoneFormatter';

import {PhoneIcon} from '@ergeon/icons/hero/micro';

interface PhoneLinkProps {
  phoneNumber?: string;
}

const PhoneLink = ({phoneNumber}: PhoneLinkProps) => {
  const phoneFormatter = usePhoneFormatter(phoneNumber);

  return (
    <a className="SimpleTopPanel-phone phone-link" data-track-call="true" href={phoneFormatter.link}>
      <PhoneIcon />
      <span>{phoneFormatter.display}</span>
    </a>
  );
};

export default PhoneLink;
