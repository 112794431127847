import React, {forwardRef, KeyboardEvent} from 'react';
import omit from 'lodash/omit';
import {InputTypes, OnChangeType, OnFocusType} from './types';

interface InputTextProps {
  className?: string;
  disabled?: boolean;
  name?: string;
  handleOnFocus?: (e: OnFocusType) => void;
  handleOnBlur?: (e: OnFocusType) => void;
  handleInputChange: (e: OnChangeType) => void;
  handleKeyUp?: (e: KeyboardEvent) => void;
  placeholder?: string;
  value: string;
  type?: InputTypes;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>((props, ref) => {
  const {
    disabled = false,
    name = '',
    handleOnFocus = () => {},
    handleOnBlur = () => {},
    handleInputChange,
    handleKeyUp = () => {},
    placeholder = '',
    value,
    type = 'text',
  } = props;
  const newProps = omit(props, ['handleOnFocus', 'handleOnBlur', 'handleInputChange', 'handleKeyUp']);
  return (
    <input
      { ...newProps }
      autoComplete="off"
      className="erg-input--text"
      disabled={disabled}
      id={name}
      name={name}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      onKeyUp={handleKeyUp}
      placeholder={placeholder}
      ref={ref}
      type={type}
      value={value} />
  )
});

export default InputText;
