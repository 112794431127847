import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';

import Button from '../../../atoms/Button';
import { DEFAULT_TOP_PANEL_PROPS } from '../constants';
import { CallUsPhoneLink } from '../PhoneLink';
import useMobileMenu from '../useMobileMenu';

import TopPanelLink from './TopPanelLink';
import TopPanelLogo from './TopPanelLogo';
import TopPanelRightSide from './TopPanelRightSide';

import './TopPanel.scss';

export interface TopPanelInterface {
  children?: ReactNode,
  customerMenu?: ReactNode,
  ergeonUrl?: string,
  fencequotingUrl?: string,
  pdfDetails?: ReactNode,
  pdfMode?: boolean,
  projectsName?: string,
  showChristmasHat?: boolean,
  showNavigation?: boolean,
  UTMquery?: string,
  widthClass?: string,
}
/**
 * This is a main Top Panel component for all Ergeon websites
 * It represent a basic TopPanel layout with several possible states.
 * There are several possible places for displaying custom content: customerMenu, pdfDetails or children.
 *
 * All "children" will be rendered in the NAVIGATION group.
 * "customerMenu" will be rendered in the end of the Top Panel
 * "pdfDetails" will be rendered if pdfMode is activated and it also will hide all other content from the
 * right side of the panel.
 *
 * All props are optional and have some default values.
 *
 * ### Desktop layout
 * ```
 * [ [LOGO]  {right-side: [NAVIGATION?] [PDF_DETAILS?] [CUSTOMER_MENU?]} ]
 * ```
 *
 * ### Mobile Layout
 * Mobile view has "Burger" icon for displaying NAVIGATION menu and CTA button.
 *
 * ```
 * [ [BURGER] [LOGO] {right-side: [CUSTOMER_MENU?]} ]
 * ```
 *
 * @param props: NiceTopPanelInterface
 * @constructor
 */
const TopPanel = (props: TopPanelInterface) => {
  const {
    ergeonUrl = DEFAULT_TOP_PANEL_PROPS.ergeonUrl,
    fencequotingUrl = DEFAULT_TOP_PANEL_PROPS.fencequotingUrl,
    pdfMode = DEFAULT_TOP_PANEL_PROPS.pdfMode,
    projectsName = DEFAULT_TOP_PANEL_PROPS.projectsName,
    UTMquery = DEFAULT_TOP_PANEL_PROPS.UTMquery,
    widthClass = DEFAULT_TOP_PANEL_PROPS.widthClass,
    showNavigation = DEFAULT_TOP_PANEL_PROPS.showNavigation,
    customerMenu,
    showChristmasHat,
    pdfDetails,
  } = props;

  const ergeonHomePage = `${ergeonUrl}${UTMquery}`;

  const { isMobileMenuVisible, onShowMobileMenu, ref: navMenuRef } = useMobileMenu();

  const topPanelContentClasses = useMemo(() => classNames({
    'top-panel__content': true,
    [widthClass]: true,
  }), [widthClass]);

  const burgerBtnClasses = useMemo(() => classNames({
    'burger': true,
    'show': isMobileMenuVisible,
    'hide': !isMobileMenuVisible,
  }), [isMobileMenuVisible]);

  const navigationClasses = useMemo(() => classNames({
    'navigation': true,
    'show': isMobileMenuVisible,
    'hide': !isMobileMenuVisible,
  }), [isMobileMenuVisible]);

  // TODO: move Fence Calculator and Projects Gallery links
  const navigation = useMemo(() => {
    return (
      <div
        className={navigationClasses}
        ref={navMenuRef}>
        <ul>
          <CallUsPhoneLink />
          <TopPanelLink name="Fence Calculator" address={`${fencequotingUrl}${UTMquery}`} />
          {props.children}
        </ul>
        <div className="mobile-items">
          <hr />
          <div className="mobile-items__wrapper">
            <Button
              asAnchor className="mobile-items__button" flavor="cta" href={ergeonHomePage}
              size="large" taste="solid">
              Get a quote
            </Button>
          </div>
        </div>
      </div>
    )
  }, [navigationClasses, navMenuRef, fencequotingUrl, UTMquery, ergeonHomePage, projectsName])

  const rightSide = useMemo(() => {
    if (pdfMode) {
      return (
        <TopPanelRightSide>
          {pdfDetails}
        </TopPanelRightSide>
      )
    } else {
      return (
        <TopPanelRightSide>
          {showNavigation && navigation}
          {customerMenu}
        </TopPanelRightSide>
      )
    }
  }, [pdfMode, pdfDetails, navigation, customerMenu])

  return (
    <div className="top-panel">
      <div className={topPanelContentClasses}>
        <div className={burgerBtnClasses} onClick={onShowMobileMenu} />
        <TopPanelLogo ergeonHomePage={ergeonHomePage} showChristmasHat={showChristmasHat} />
        {rightSide}
      </div>
    </div>
  )
}

export default TopPanel;
