import React, { Children, ComponentPropsWithRef, HTMLAttributes, ReactElement } from 'react';
import classNames from 'classnames';

import RadioGroupContext from './RadioGroupContext';

import './RadioGroup.scss';

interface RadioGroupAttrs extends ComponentPropsWithRef<'div'> {
  name?: string;
  selectedValue?: unknown;
  onChange?: (value: unknown) => void;
  children?: React.ReactNode;
}

type RadioGroupProps = RadioGroupAttrs & HTMLAttributes<HTMLDivElement>

const RadioGroup = (props: RadioGroupProps) => {
  const { className, name, selectedValue, onChange, children, ...rest } = props;
   return (
    <RadioGroupContext.Provider value={{name, onChange, selectedValue}}>
      <div className={classNames(className, 'RadioGroup')} role="radiogroup" {...rest}>
        <ul>
          {Children.map(children, (child, idx) => (
            <li key={(child as ReactElement)?.key ?? idx}>{child}</li>
          ))}
        </ul>
      </div>
    </RadioGroupContext.Provider>
  );
};

export default RadioGroup;
