export interface ContentScrollBlocker {
  blockContentScroll(): void;
  enableContentScroll(): void;
}

/**
 * This component blocks vertical scrolling of a HTMLElement.
 */
export default class ScrollBlocker implements ContentScrollBlocker {
  private contentNode: HTMLElement;

  private savedScrollPosition = 0;

  constructor(node?: HTMLElement | null) {
    this.contentNode = node || document.body;
  }

  private get contentScrollPosition() {
    return window.scrollY;
  }

  private set contentScrollPosition(scrollPosition: number) {
    window.scrollTo(0, scrollPosition);
  }

  private resetContentScrollPosition() {
    this.contentScrollPosition = 0;
  }

  private setContentToScrollPositionStyles(scrollPosition: number) {
    this.contentNode.style.position = 'fixed';
    this.contentNode.style.overflow = 'hidden';
    this.contentNode.style.top = `-${scrollPosition}px`;
    this.contentNode.style.right = '0';
    this.contentNode.style.bottom = '0';
    this.contentNode.style.left = '0';
  }

  private resetContentToScrollPositionStyles() {
    this.contentNode.style.position = '';
    this.contentNode.style.overflow = '';
    this.contentNode.style.top = '';
    this.contentNode.style.right = '';
    this.contentNode.style.bottom = '';
    this.contentNode.style.left = '';
  }

  public blockContentScroll() {
    this.savedScrollPosition = this.contentScrollPosition;
    this.setContentToScrollPositionStyles(this.savedScrollPosition);
    this.resetContentScrollPosition();
  }

  public enableContentScroll() {
    const scrollPosition = this.savedScrollPosition;
    this.resetContentToScrollPositionStyles();
    this.contentScrollPosition = scrollPosition;
  }
}
