import React from 'react';

import PropTypes from 'prop-types';
import ReactSelect, {components} from 'react-select';
import classNames from 'classnames';
import {ChevronDownIcon, XMarkIcon} from '@ergeon/icons/hero/micro';

import './index.scss';

export const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="size-6" />
      </components.DropdownIndicator>
    )
  );
};

export const ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <XMarkIcon className="size-6" />
      </components.ClearIndicator>
    )
  );
};

export default class Select extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      isMulti: PropTypes.bool,
      name: PropTypes.string,
      onChange: PropTypes.func,
      options: PropTypes.array,
      placeholder: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    };
  }

  static defaultProps = {
    placeholder: 'Select services…',
  };

  render() {
    const {id, options, isDisabled, isMulti, onChange, name, value, placeholder, defaultValue} = this.props;

    const classes = classNames([
      'react-select-container',
      {
        disabled: isDisabled,
      },
    ]);

    return (
      <ReactSelect
        className={classes}
        classNamePrefix="react-select"
        components={{DropdownIndicator, ClearIndicator}}
        defaultValue={defaultValue}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={false}
        {...(id ? {id, instanceId: id} : {})}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}
