import {useCallback, useState} from 'react';
import {copyToClipboard} from 'libs/utils/navigator';

const useCopyToClipboard = (copyText: string) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopy = useCallback(() => {
    copyToClipboard(copyText).then(() => {
      setIsCopied(true);
    });
  }, [copyText]);

  return {isCopied, onCopy, setIsCopied};
};

export default useCopyToClipboard;
