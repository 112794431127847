import React, { HTMLAttributes, useMemo } from "react";

import classNames from "classnames";

interface SignatureProps extends HTMLAttributes<HTMLDivElement>{
  value: string;
  type: 'draw' | 'text';
}

const TEXT_RESIZE_LENGTHS = {
  SMALL: 13,
  MEDIUM: 20,
};

export default function Signature(props: SignatureProps) {
  const { value, type, ...divAttr } = props;

  const limitTextClassName = useMemo(() => {
    if (type !== 'text') {
      return 'Signature-text'
    }

    return classNames('Signature-text', {
      'is-text-large': value.length < TEXT_RESIZE_LENGTHS.SMALL,
      'is-text-medium': value.length >= TEXT_RESIZE_LENGTHS.SMALL && value.length <= TEXT_RESIZE_LENGTHS.MEDIUM,
      'is-text-small': value.length > TEXT_RESIZE_LENGTHS.MEDIUM,
    })
  }, [type, value])

  const renderSignature = useMemo(() => {
    if (type === 'draw') {
      return <img alt="Signature" src={value} className="Signature-image" />;
    }
    return <span className={limitTextClassName}>{value}</span>;
  }, [type, value, limitTextClassName]);

  return <div { ...divAttr } className="Signature">{renderSignature}</div>;
}
