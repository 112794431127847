import React, { forwardRef, HTMLAttributes } from 'react';

import '../index.scss';

/**
 * Line component is a forwardRed so we can use the passed ref for any special
 * action we need, like doing a scroll where line component is positioned
 */
const Line = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>((props, ref) => {
  return (
    <div {...props} ref={ref} className="EventsCalendar-line">
      <div className="EventsCalendar-lineWrapper">
      </div>
    </div>
  )
});

export { Line };
