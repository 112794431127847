import React from 'react';

import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';

import Rating from 'components/atoms/Rating';

import './index.scss';

class Review extends React.Component {
  static get propTypes() {
    return {
      customerName: PropTypes.string,
      feedback: PropTypes.object,
    };
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      customerName,
      feedback,
    } = this.props;
    const { feedbackContent,
      feedbackTitle, linkReviewGoogle,
      linkReviewHomeAdvisor, linkReviewYelp,
      rating } = feedback?
      feedback : '';
    const customerNameArr = customerName? customerName.split(' ') : [];
    const customerFirstName = customerNameArr[0];
    const customerLastName = (customerNameArr.length > 1)? customerNameArr[1] : null;
    return (
      <div>
        {(feedbackContent || feedbackTitle)? (
          <div className="review">
            <Rating value={rating} />
            <p className="additional-header h2">{feedbackTitle }</p>
            <div className="content">
              <ShowMoreText
                anchorClass=""
                less="Show less"
                lines={3}
                more="Show more">
                {feedbackContent}
              </ShowMoreText>
            </div>

            <p className="name">
              &#8213; {customerName ?
                `${customerFirstName} ${customerLastName ?
                  `${customerLastName[0]}.` :
                  ''}` :
                'Anonymous' }
            </p>
            {
              (linkReviewGoogle || linkReviewHomeAdvisor || linkReviewYelp) &&
              <div className="social-links-block">
                {
                  linkReviewGoogle &&
                  <a className="img google-review" href={linkReviewGoogle}/>
                }
                {
                  linkReviewHomeAdvisor &&
                  <a className="img home-advisor-review" href={linkReviewHomeAdvisor}/>
                }
                {
                  linkReviewYelp &&
                  <a className="img yelp-review" href={linkReviewYelp}/>
                }
              </div>
            }
          </div>) : (null)}
      </div>
    );
  }
}

export default Review;
