import React, {FormEvent, HTMLAttributes, MouseEvent, useCallback, useMemo} from 'react';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import Spinner from '../../atoms/Spinner';
import Tooltip from '../../atoms/Tooltip';
import {InputProps, OnChangeType} from '../../atoms/Input/types';
import classNames from 'classnames';

import SearchIcon from '@ergeon/icons/svg/icon-search-gray.svg';
import NodeSearchIcon from '!@svgr/webpack!@ergeon/icons/svg/icon-node-search.svg';

import './SearchInputToggle.scss';

export type SearchInputToggleProps = {
  placeholder?: string;
  size?: InputProps['size'];
  isFullWidth?: boolean;
  isLoading: boolean;
  showToggle?: boolean;
  isToggleEnabled: boolean;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChange: (value: string) => void;
  onToggleClick: (value: boolean) => void;
  tooltipMsg?: string;
  value: string;
};

const SearchInputToggle = (props: SearchInputToggleProps & HTMLAttributes<HTMLFormElement>) => {
  const {
    placeholder = 'Search...',
    isFullWidth = false,
    isLoading,
    showToggle = true,
    isToggleEnabled,
    size = 'small',
    onSubmit,
    onChange,
    onToggleClick,
    tooltipMsg = '',
    value,
    ...formAttr
  } = props;

  const handleToggleClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onToggleClick(!isToggleEnabled);
    },
    [isToggleEnabled],
  );

  const onValueChange = (_event: OnChangeType, _name: string, val: string) => {
    onChange(val);
  };

  const searchIcon = <img className="is-LeftIcon" src={SearchIcon} />;
  const nodeSearchIcon = <NodeSearchIcon />;

  const toggleBtnClasses = useMemo(
    () =>
      classNames('SearchInput-Toggle', {
        'is-Active': isToggleEnabled,
      }),
    [isToggleEnabled],
  );

  const inputClasses = useMemo(
    () =>
      classNames({
        'is-FullWidth': isFullWidth,
      }),
    [isFullWidth],
  );

  return (
    <form {...formAttr} className="SearchInput" onSubmit={onSubmit}>
      <Input
        className={inputClasses}
        name="search-input-toggle"
        onChange={onValueChange}
        placeholder={placeholder}
        rightIcon={searchIcon}
        size={size}
        value={value}
      />
      <Button className="SearchInput-Button" flavor="primary" size="medium">
        {isLoading ? <Spinner active={true} borderWidth={0.1} size={25} /> : 'Go'}
      </Button>
      {showToggle && (
        <Tooltip msg={tooltipMsg} position="bottom">
          <Button
            className={toggleBtnClasses}
            size="small"
            taste="boundless"
            flavor="regular"
            onClick={handleToggleClick}
          >
            {nodeSearchIcon}
          </Button>
        </Tooltip>
      )}
    </form>
  );
};

export default SearchInputToggle;
